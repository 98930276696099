import React, { useState } from 'react';
import "./Dashboard.css";
import theomo from '../assets/admin/Theom2.png'
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import headerlogo from '../assets/homepage/header-logo.png';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Homepage from '../homepage/Homepage';
import Footer from '../common/Footer';
import Dashboard from './Dashboard';
import Profile from "./Profile";
import Booking from "./Booking";
import Pending from "./Pending";
import Availability from './Availability';
import Earnings from './Earnings';
import Account from './Account';
import ProfileManagement from './ProfileManagement';
import VideoPlayer from "./VideoPlayer";
import Grid from '@material-ui/core/Grid';
import service5 from '../assets/homepage/service1.png';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import PersonalSettings from './PersonalSettings';
import usericon from '../assets/homepage/images.png'
import { API } from "../backend";

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    // appBar: {
    //   [theme.breakpoints.up('sm')]: {
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     marginLeft: drawerWidth,
    //   },
    // },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },

    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    dashboard: {
      backgroundColor: "#353b48",
      marginTop: 85,
      paddingLeft: 20,
      color: 'grey',
      height: 583,

    },
    profile: {
      position: 'relative',
      width: 50,
      height: 50,
      borderRadius: 100
    },
    tooltip: {
      zIndex: 9999,
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    // appBar: {
    //   [theme.breakpoints.up('sm')]: {
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     marginLeft: drawerWidth,
    //   },
    // },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    center: {
    },
    notifications: {
      fontSize: 57,
      color: "#e8b702",
      paddingRight: 10
    },
    sidebarhover: {
      '&:hover': {
        backgroundColor: "rgb(0 0 0 / 18%)",
      },
    },
    profilehover: {
      paddingTop: 0,
      paddingBottom: 0,
      '&:hover': {
        backgroundColor: "#0b88b5",
        color: "#fff",
      },
    }
  }
  ),
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function WaitingForResponse(props: Props) {
  const providerData = JSON.parse(sessionStorage.getItem("providerData"))
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [component, setComponent] = useState('dashboard')
  const [orderdata, setorderdata] = useState([])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  React.useEffect(() => {

  }, [providerData])
  const drawer = (
    <div>

      <Divider />
      <List className={classes.dashboard}>
        <ListItem button className={classes.sidebarhover} key="dashboard" onClick={() => setComponent('dashboard')} style={component === 'dashboard' ? { color: "white" } : { color: "#ccc" }}>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button className={classes.sidebarhover} key="bookings" style={{ color: "#666" }}>
          <ListItemText primary="Bookings" />
        </ListItem>
        {/* <ListItem button className={classes.sidebarhover} key="pending-request" style={{ color: "#666" }}>
          <ListItemText primary="Pending/Request" />
        </ListItem> */}
        <ListItem button className={classes.sidebarhover} key="availability" style={{ color: "#666" }}>
          <ListItemText primary="Availability" />
        </ListItem>
        <ListItem button className={classes.sidebarhover} key="earnings" style={{ color: "#666" }}>
          <ListItemText primary="Earnings" />
        </ListItem>
        {/* <ListItem button className={classes.sidebarhover} key="account" style={{ color: "#666" }}>
          <ListItemText primary="Account Management" />
        </ListItem> */}
        <ListItem button className={classes.sidebarhover} key="profile_management" style={component === 'profile_management' ? { color: "white" } : { color: "#ccc" }} onClick={() => setComponent('profile_management')}>
          <ListItemText primary="Account Management" />
        </ListItem>
      </List>
    </div>
  );
  const logout = () => {
    sessionStorage.clear("providerDatatoken")
    props.history.push(`/`)
  }
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>


          <Grid container justify="center" >
            <Grid item xs={10} sm={10} md={10} >
              <Link onClick={() => setComponent('dashboard')} class="hidetable"><Image title="THEOM" src={headerlogo} fluid /></Link>
              <IconButton
                color="#000"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                {/* <MenuIcon /> */}
                <img src={theomo} style={{ width: "15px", marginRight: "2px", marginTop: "12px" }} />
              </IconButton>
            </Grid>

            <Grid item container xs={2} sm={2} md={2} alignItems="flex-end" direction="column">

              <Grid item >
                <div className="topBarMenu">
                  <ul id="header-menu">
                    <li className="nav-item dropdown dropdone">
                      <Link to="#" >
                        <NotificationsIcon className="notif" /><span className="badge badge-warning admin-navbar-notification-icon navbar-badge costmr">{orderdata.length}</span>
                      </Link>
                      {orderdata.length > 0 ?
                        <div className="dropdown2">
                          <h2>Notifications</h2>
                          <ul className="">
                            {
                              orderdata.map((noty) => {
                                return (
                                  <li>
                                    <span>
                                      <img src={usericon} class="makeStyles-profile-3 img-fluid" />
                                    </span>
                                    <span>
                                      <button onClick={() => setComponent('pending')} style={{ paddingRight: 5 }} >
                                        <strong>{noty.userId.first_name + ' ' + noty.userId.last_name}</strong>
                                      </button>
                                      place new order please check.</span>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div> : ''}
                    </li>
                    <li><Link to="#" onClick={handleClick('bottom')}><Image className={classes.profile} src={providerData.professionalImage == '' || providerData.professionalImage == null ? service5 : `${API}/files/${providerData.professionalImage}`} fluid /> <i class="fa fa-angle-down" aria-hidden="true"></i></Link></li>
                  </ul>
                </div>


              </Grid>

            </Grid>
          </Grid>




        </Toolbar>

        <Popper className={classes.tooltip + ' ' + "setdrop"} open={open} anchorEl={anchorEl} placement={placement} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <List >
                  <ListItem className={classes.profilehover} key="name">
                    <ListItemText className={classes.center} primary={providerData ? providerData.first_name + ' ' + providerData.last_name : ""} style={{ textTransform: 'uppercase', borderBottom: " 1px solid #ccc" }} />
                  </ListItem>

                  <ListItem button className={classes.profilehover} key="profile" onClick={() => setComponent('profile')}>
                    <ListItemText className={classes.center} primary="My Profile" onClick={handleClick('bottom')} />
                  </ListItem>

                  {/* <ListItem className={classes.profilehover} button key="account_setting"  onClick={() => setComponent('account_setting')}>
            <ListItemText  className={classes.center} primary="Account Settings" />
          </ListItem> */}

                  <ListItem className={classes.profilehover} button key="logout">
                    <ListItemText className={classes.center} primary="Logout" onClick={(e) => logout()} />
                  </ListItem>
                </List>
              </Paper>
            </Fade>
          )}
        </Popper>
      </AppBar>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              className="drowrrelative"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content} >
          <div className={classes.toolbar} />
          {

            // component === 'dashboard' ? '' : 'null'

          }

          <div className="">
            <div className="conatainer">
              <div className="row">
                <div className="col-sm-10 margin mt-3 mb-5 waitingpage">
                  {component === 'profile' ? <PersonalSettings /> : component === 'profile_management' ? <ProfileManagement /> :
                    <>
                      <h1>Welcome  <span style={{ color: "#2b5ea6" }}>{providerData ? (providerData.first_name + ' ' + providerData.last_name) : ""}</span></h1>
                      <h5>Your account is being reviewed by our support team and it will be activated once the verification process is complete. The verification process can take up to 48 hours. In the meantime, please feel free to update your profile.</h5>
                      <h5>If you have any questions, please reach out to our onboarding team info@theom.ca or</h5>
                      <h5> call us at XXX-XXX-XXXX</h5>
                      <VideoPlayer />
                    </>
                  }

                </div>

              </div>
            </div>

          </div>
        </main>

      </div>
      <Footer />
    </>
  );
}