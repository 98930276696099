import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { Modal, Button, Container, Row } from 'react-bootstrap';
import Signupform from './Signupform'
import './SignUp.css'
import '../Style.css'

export default function Google() {
    const [show, setShow] = useState(false);
    const [showPopup, setPopup] = useState(false);
    const [googledata, setgoogledata] = useState({})

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseSignupForm = () => setPopup(false);
    const showSignForm = () => setPopup(true);

    const responseGoogle = (response) => {
        if (response.profileObj) {
            setgoogledata(response.profileObj)
            setPopup(true);
        }
    }
    return (
        <>
            <GoogleLogin
                clientId="445670612418-800u96b6hb7d0mdjdupoqk0d8gerk3em.apps.googleusercontent.com"
                render={renderProps => (
                    <button className="loginwithgoogle mt-3 btn-lg btn btn-primar" id="loginTabs" onClick={renderProps.onClick} disabled={renderProps.disabled}>Sign up using Google </button>
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
            />


            <Modal show={showPopup} id="signUpForm" onHide={handleCloseSignupForm} size="lg" keyboard={false} aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Signupform data={googledata} />
                        </Row>
                    </Container>

                </Modal.Body>

            </Modal>
        </>
    )
}