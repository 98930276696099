import React from "react";
import ReactStars from "react-rating-stars-component";
import { render } from "react-dom";

export default function Showreview(props) {

  const ratingChanged = (newRating) => {
  };

  const rv = props.star
  const ab = String(rv).substr(String(rv).lastIndexOf('\\') + 1).split('.') ?
    '.'+ parseInt(String(rv).substr(String(rv).lastIndexOf('\\') + 1).split('.')[1]) >= .5 ? parseInt(String(rv).substr(String(rv).lastIndexOf('\\') + 1).split('.')[0]) + 1 : String(rv).substr(String(rv).lastIndexOf('\\') + 1).split('.')[0] : rv
  return (
    <>
      <div className="ave">
        <ReactStars
          count={parseInt(ab)}
          onChange={ratingChanged}
          size={24}
          isHalf={false}
          emptyIcon={<i className="far fa-star"></i>}
          halfIcon={<i className="fa fa-star-half-alt"></i>}
          fullIcon={<i className="fa fa-star"></i>}
          activeColor="#ffd700"
        />
      </div>

      <div className="nonerat">
        <ReactStars
          count={5 - (parseInt(ab))}
          onChange={ratingChanged}
          size={24}
          isHalf={false}
          emptyIcon={<i className="far fa-star"></i>}
          halfIcon={<i className="fa fa-star-half-alt"></i>}
          fullIcon={<i className="fa fa-star"></i>}
          activeColor="#ffd700"
        />
      </div>
    </>

  );
}