import React, { useState } from 'react';
import Payment from './payment'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { CheckoutForm } from '../stripe/Checkout';
import { Link } from 'react-router-dom';
import { Modal, Container, Row } from "react-bootstrap";
import theomo from '../assets/admin/Theom2.png'
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';



const stripePromise = loadStripe('pk_test_51JO1iSSDo0gJpYTejY7QVhbtnhnTGzRQaCZSA5Pzxj9RaV6FsYEu67H0E71cuBCw2R6TIEWLknAHyUH6QiuJqyrH00TlxEIUXM');

export default function Earnings() {
  const [color, setColor] = useState('Earnings')
  const [component1, setComponent1] = useState('Theom')
  const [component, setComponent] = useState('dailyupdate')
  const [datetime, setdatetime] = useState('Weekly')
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  });
  const [show, setShow] = useState(false);
  const [showPopup, setPopup] = useState(false);
  const [showPopup2, setPopup2] = useState(false);
  const [showPopup3, setPopup3] = useState(false);
  const [showPopup4, setPopup4] = useState(false);
  const [showPopup5, setPopup5] = useState(false);
  const handleCloseSignupForm = () => {
    setPopup(false)
    setPopup2(false)
    setPopup3(false)
    setpoints4({})
    setPopup4(false)
    setPopup5(false)
    setpoints4({})
    setdetailbill(false)
  };
  const [token, setpoint] = useState(0)
  const [vl, setvl] = React.useState(3)

  const [detailbill, setdetailbill] = useState(false)
  const [count, setcount] = useState(false)
  const [color3, setColor3] = useState('#EA4337')
  const [color4, setColor4] = useState('')
  const [points, setpoints4] = useState({})
  const [checkpoints, setcheckpoints4] = useState({
    fname: 'a',
    lname: 'a',
    address: 'a',
    city: 'a',
    country: 'a',
    postal: 'a',
    province: 'a',
  })
  const [costumpoin, setcostumpoin] = useState()
  const providerData = JSON.parse(sessionStorage.getItem("providerData"))

  const [recent, setrecent] = useState([])
  const [month, setmonth] = useState([])
  const [monthpoint, setmonthpoint] = useState({})
  const [week, setweek] = useState([])
  const [weekpoint, setweekpoint] = useState({})
  const [costum, setcostum] = useState([])
  const [costumpoint, setcostumpoint] = useState({})
  const [count2, setcount2] = useState(false)
  const [date, setdate] = useState({})
  const [monthdate, setmonthdate] = useState({
    now: "",
    old: ""
  })
  const [weektime, setweektime] = useState()
  const [cstmbtn, setcstmbtn] = useState(false)

  const selectpoint = (e) => {
    var check = e.substr(e.lastIndexOf('\\') + 1).split('-')
    var GST = ((parseInt(check[0]) / 100) * 5)
    var PST = ((parseInt(check[0]) / 100) * 7)
    var total = ((parseInt(check[0]) + GST) + PST)
    var data = { ...points, "Amount": parseInt(check[0]), "Points": parseInt(check[1]), "GST": GST, "PST": PST, "total": total, "id": providerData._id }
    setpoints4(data)
    setPopup(true)
  }

  const topFunction2 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const fieldsrequired = () => {
    let fname = points.fname ? checkpoints.fname = "yuvy" : checkpoints.fname = '';
    let lname = points.lname ? checkpoints.lname = "yuvy" : checkpoints.lname = '';
    let address = points.address ? checkpoints.address = "yuvy" : checkpoints.address = '';
    let city = points.city ? checkpoints.city = "yuvy" : checkpoints.city = '';
    let country = points.country ? checkpoints.country = "yuvy" : checkpoints.country = '';
    let postal = points.postal ? checkpoints.postal = "yuvy" : checkpoints.postal = '';
    let province = points.province ? checkpoints.province = "yuvy" : checkpoints.province = '';
    toast.error("Please Complete Detail Fill")
    setcheckpoints4({ ...checkpoints })
  }

  const billdetail = (e) => {
    let data = { ...points, [e.target.name]: e.target.value }
    setpoints4(data)
    let data2 = { ...checkpoints, [e.target.name]: e.target.value }
    setcheckpoints4(data2)
    if (data.fname && data.lname && data.address && data.city && data.country && data.postal && data.province) {
      setdetailbill(true)
    } else {
      setdetailbill(false)
    }
  }

  const detailpoinst = () => {
    fetch(`${API}/professional/lastThreeTransaction?professionalId=` + providerData._id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => response.json().then(data => {
        setrecent(data.data)
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));

    // 
    fetch(`${API}/professional/lastOneMonth?professionalId=` + providerData._id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => response.json().then(data => {
        setmonth(data.data)
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
    // 

    fetch(`${API}/professional/lastOneWeek?professionalId=` + providerData._id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => response.json().then(data => {
        setweek(data.data)
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));

    // 


  }

  const costumfl = () => {
    setcstmbtn(true)
    let data = {
      "professionalId": providerData._id,
      "todate": (selectedDayRange.from.year + "-" + (selectedDayRange.from.month < 10 ? "0" + selectedDayRange.from.month : selectedDayRange.from.month) + "-" + (selectedDayRange.from.day < 10 ? "0" + selectedDayRange.from.day : selectedDayRange.from.day)),
      "fromdate": (selectedDayRange.to.year + "-" + (selectedDayRange.to.month < 10 ? "0" + selectedDayRange.to.month : selectedDayRange.to.month) + "-" + (selectedDayRange.to.day < 10 ? "0" + selectedDayRange.to.day : selectedDayRange.to.day))
    }

    fetch(`${API}/professional/dateRangeData`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json()
        .then(result => {
          if (result.status == 200) {
            setcostum(result.data)
            fiter3()
          } else {
            toast.error(result.msg)
          }

        }))
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }

  const fiter = () => {
    let data = { buy: 0, redeemed: 0, refund: 0 }
    month.map((e) => {
      if (e.paymentStatus == "buy") {
        data = {
          ...data, buy: (data.buy + e.totalPoint)
        }
      } else if (e.paymentStatus == "redeemed" || e.paymentStatus == "adminRedeemed") {
        data = {
          ...data, redeemed: (data.redeemed + e.totalPoint)
        }
      } else if (e.paymentStatus == "refund") {
        data = {
          ...data, refund: (data.refund + e.totalPoint)
        }
      } else {
      }
      setmonthpoint(data)
    })
  }
  const fiter2 = () => {
    let data = { buy: 0, redeemed: 0, refund: 0 }
    week.map((e) => {
      if (e.paymentStatus == "buy") {
        data = {
          ...data, buy: (data.buy + e.totalPoint)
        }
      } else if (e.paymentStatus == "redeemed" || e.paymentStatus == "adminRedeemed") {
        data = {
          ...data, redeemed: (data.redeemed + e.totalPoint)
        }
      } else if (e.paymentStatus == "refund") {
        data = {
          ...data, refund: (data.refund + e.totalPoint)
        }
      } else {
      }
      setweekpoint(data)
    })
  }
  const fiter3 = () => {
    setcount2(true)
    let data = { buy: 0, redeemed: 0, refund: 0 }
    costum.map((e) => {
      if (e.paymentStatus == "buy") {
        data = {
          ...data, buy: (data.buy + e.totalPoint)
        }
      } else if (e.paymentStatus == "redeemed" || e.paymentStatus == "adminRedeemed") {
        data = {
          ...data, redeemed: (data.redeemed + e.totalPoint)
        }
      } else if (e.paymentStatus == "refund") {
        data = {
          ...data, refund: (data.refund + e.totalPoint)
        }
      } else {
      }
    })
    setcostumpoint(data)
    setcstmbtn(false)
  }

  const datef = (e) => {
    let data = { ...date, [e.target.name]: e.target.value }
    setdate(data)
  }

  const purchase = () => {
    let bookingdata = {
      "professionalId": points.id,
      "totalAmmount": points.total,
      "succesToken": "abcdefghijklmnopqrst",
      "totalPoint": points.Points,
      "paymentStatus": "buy"
    }
    if (points.Amount) {
      fetch(`${API}/professional/transaction`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(bookingdata)
      })
        .then(res => res.json())
        .then(result => {
          toast.success("purchase Successful")
          setPopup4(true)
          setPopup3(false)
        })
        .catch(err => toast.error("Ooops!! Something went wrong."));
    } else {
      toast.error("select Packege")
    }

  }
  React.useEffect(() => {
    setTimeout(function () { topFunction2() }, 100);
    if (!count) {
      detailpoinst()
      setcount(true)
      fetch(`${API}/professional/getProfessinalToken?professionalId=` + providerData._id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
        .then(response => response.json().then(data => {
          setpoint(data.data.totalPoint)
        })
        )
        .catch(err => toast.error("Ooops!! Something went wrong."));
      todaydate()
    }
  }, [points, date, costumpoint, costum, costumpoint, token, monthdate])
  React.useEffect(() => {
    if (count2) {
      fiter3()
      setcount2(false)
    }
  }, [date, costumpoint, costum, costumpoint, count2, monthdate, checkpoints])

  const todaydate = () => {
    var today = new Date();
    var date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
    var date2 = today.getDate() + '-' + (today.getMonth()) + '-' + today.getFullYear();
    setmonthdate({ "old": date2, "now": date })

    var date3 = new Date();
    date3.setDate(date3.getDate() - 6);
    var date4 = date3.getDate() + '-' + (date3.getMonth() + 1) + '-' + date3.getFullYear();
    setweektime(date4)
  }
  return (
    <div className="container">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div className="row-width row mt-5">
        <div className="col-xs-2 col-6 col-md-2 col-lg-2" style={color == 'Earnings' ? { color: "#EA4337", borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid', textAlign: 'center' } : {}}>
          <button className="btn button activeclass" style={{ color: color3, width: "100%", padding: "6px 0px" }} id="button" onClick={() => { setColor('Earnings'); setColor4(''); setColor3('#EA4337') }}>Summary</button>
        </div>
        <div className="col-xs-2 col-6 col-md-2 col-lg-2" style={color == 'Points' ? { color: "#EA4337", borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid', textAlign: 'center' } : {}}>
          <button className="btn button activeclass" style={{ color: color4, width: "100%", padding: "6px 0px" }} onClick={() => { setColor('Points'); setColor4('#EA4337'); setColor3('') }}>Points</button>
        </div>
      </div>
      <hr className="activepastline"></hr>
      <div className="container" style={color == 'Points' ? { display: 'none' } : { padding: "0" }} >
        <div className="row" >
          <div className="col btn  card text-center dailyupdate" style={component == 'dailyupdate' ? { color: "white", background: "#EA4337" } : {}} onClick={() => setComponent('dailyupdate')}>
            <h4>Recent</h4>
          </div>
          <div className="col btn card text-center dailyupdate" style={component == 'Weekly' ? { color: "white", background: "#EA4337" } : {}} onClick={() => {
            setComponent('Weekly')
            fiter2()
          }}>
            <h4>Summary</h4>
          </div>
          {/* <div className="col btn card text-center dailyupdate" style={component == 'Custom' ? { color: "white", background: "#EA4337" } : {}} onClick={() => setComponent('Custom')}>
            <h4> Custom</h4>
          </div> */}
        </div>
        <div className="dailydetail" style={component == 'Weekly' ? { display: 'none' } : {}}>
          {/* <div className="card dailydetails mt-5 ">
            <span>Date:08-09-2020</span><br></br>
            <span>Daily Summary:Business Name</span><br></br>
            <span>Gross Earning:$ 200</span>
          </div> */}
          <div className="table-responsive mt-5">
            {/* <h3><span className="heading-2">Order</span>&nbsp;<span className="heading-2">Details</span></h3> */}
            <table className="table  table-bordered text-center">
              <thead>
                <tr className="table-heading">
                  <th className="table-heading-first" scope="col">Date</th>
                  <th scope="col">Points</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody>
                {
                  recent.map((e, index) => {
                    if (index < vl) {
                      return (
                        <tr>
                          <th scope="row">
                            {new Intl.DateTimeFormat(['ban', 'id']).format(new Date(e.createdAt))}
                          </th>
                          <td>{e.totalPoint}</td>
                          <td style={{ textTransform: "capitalize" }}>{e.paymentStatus ? e.paymentStatus : ""}</td>
                        </tr>
                      )
                    }
                  })
                }
              </tbody>
            </table>
            <div class="col-12 view mt-3">
              {vl <= 3 ? <Link onClick={e => setvl(100)}	>View More</Link> : <Link onClick={e => setvl(3)}	>View Less</Link>}

            </div>
          </div>
        </div>
        <div className="WeeklyDetails" style={component == 'dailyupdate' ? { display: "none" } : {}}>
          <div className=" row mt-5">
            <div className="col-sm-4 pl-0">
              <div className="card  w-80 text-center cstm">
                <tbody>
                  <tr style={{ display: "block" }}>
                    <th scope="row" className="statement text-center" ><button style={datetime == 'Weekly' ? { color: "white", background: "#EA4337", display: "block" } : { display: "block" }} onClick={() => {
                      setdatetime('Weekly')

                    }}>Weekly</button></th>
                  </tr>
                  <tr style={{ display: "block" }}>
                    <th scope="row" className="statement text-center" style={{ display: "block" }}><button style={datetime == 'Monthly' ? { color: "white", background: "#EA4337", display: "block" } : { display: "block" }} onClick={() => {
                      setdatetime('Monthly')
                      fiter()
                    }}>
                      Monthly
                    </button></th>
                  </tr>
                  <tr style={{ display: "block" }}>
                    <th scope="row" className="statement text-center" style={{ display: "block" }}><button style={datetime == 'Custom' ? { color: "white", background: "#EA4337", display: "block" } : { display: "block" }} onClick={() => setdatetime('Custom')}>
                      Custom
                    </button></th>
                  </tr>
                </tbody>
              </div>
            </div>
            <div className="col-sm-8 pr-0" style={datetime == 'Weekly' ? {} : { display: "none" }}>
              <h2 class="headi"> From {weektime}  To {monthdate.now}</h2>
              <div className="card">
                <div className="record">
                  <span>Total Balance</span>
                  <span>{weekpoint ? token : 0}</span>
                </div>
                <div className="record">
                  <span>Total Bought</span>
                  <span>{weekpoint.buy ? weekpoint.buy : 0}</span>
                </div>
                <div className="record">
                  <span>Total Redeemed</span>
                  <span>{weekpoint.redeemed ? weekpoint.redeemed : 0}</span>
                </div>
                <div className="record">
                  <span>Total Refunded</span>
                  <span>{weekpoint.refund ? weekpoint.refund : 0}</span>
                </div>
              </div>
            </div>
            <div className="col-sm-8" style={datetime == 'Monthly' ? {} : { display: "none" }}>
              <h2 class="headi">From {monthdate.old}  To {monthdate.now}</h2>
              <div className="card">
                <div className="record">
                  <span>Total Balance</span>
                  <span>{monthpoint ? token : 0}</span>
                </div>
                <div className="record">
                  <span>Total Bought</span>
                  <span>{monthpoint.buy ? monthpoint.buy : 0}</span>
                </div>
                <div className="record">
                  <span>Total Redeemed</span>
                  <span>{monthpoint.redeemed ? monthpoint.redeemed : 0}</span>
                </div>
                <div className="record">
                  <span>Total Refunded</span>
                  <span>{monthpoint.refund ? monthpoint.refund : 0}</span>
                </div>
              </div>
            </div>
            <div className="col-sm-8" style={datetime == 'Custom' ? { marginTop: "-47px" } : { display: "none" }}>
              <div className="datesearch">
                <DatePicker
                  value={selectedDayRange}
                  onChange={setSelectedDayRange}
                  inputPlaceholder="Select a day range"
                  shouldHighlightWeekends
                  class="clnd"
                  colorPrimary="#cd0506" // added this
                  colorPrimaryLight="rgba(234, 67, 55, 0.4)"
                  calendarPopperPosition="bottom"
                  dateFormat="DD/MM/YYYY"
                />
                <button id="search-button" type="button" class="btn btn-primary mr-5" onClick={e => selectedDayRange.to && selectedDayRange.from ? costumfl() : toast.error("select date")} disabled={cstmbtn}>Go</button>
              </div>
              <div className="card">
                <div className="record">
                  <span>Total Balance</span>
                  <span>{costumpoint.buy || costumpoint.redeemed || costumpoint.refund ? token : 0}</span>
                </div>
                <div className="record">
                  <span>Total Bought</span>
                  <span>{costumpoint.buy ? costumpoint.buy : 0}</span>
                </div>
                <div className="record">
                  <span>Total Redeemed</span>
                  <span>{costumpoint.redeemed ? costumpoint.redeemed : 0}</span>
                </div>
                <div className="record">
                  <span>Total Refunded</span>
                  <span>{costumpoint.refund ? costumpoint.refund : 0}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={color == 'Earnings' ? { display: 'none' } : {}}>
        <div className="row">
          {/* <div className="col btn  card text-center dailyupdate" style={component1 == 'Summary' ? { color: "white", background: "#EA4337" } : {}} onClick={() => setComponent1('Summary')}>
            <h4>Summary</h4>
          </div> */}
          {/* <div className="col btn card text-center dailyupdate" style={component1 == 'Theom' ? { color: "white", background: "#EA4337" } : {}} onClick={() => setComponent1('Theom')}>
            <h4>Buy More Theom Points</h4>
          </div> */}
        </div>
        <div className="row container mt-5" style={component1 == 'Theom' ? { display: 'none' } : {}}>
          <div className="summary col-sm-8">
            <div className="table-responsive">
              <table className="table  table-bordered point tablefix ">
                <thead>
                  <tr className="table-heading">
                    <th table-heading-first></th>
                    <th className=" text-center " scope="col">Monthly</th>
                    <th className="table-heading-last text-center" scope="col">YTD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>

                    <td>Theom Points bought </td>
                    <td className="text-center"> 100</td>
                    <td>1500</td>

                  </tr>
                  <tr>
                    <td>Theom points redeemed </td>
                    <td className="text-center">90</td>
                    <td>1400</td>
                  </tr>
                  <tr>
                    <td>Theom points available </td>
                    <td className="text-center">10</td>
                    <td>114</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row mt-2" style={component1 == 'Summary' ? { display: 'none' } : {}}>
          <div className="col-sm-12 erng">
            <div className="" >
              <div className="header">
                <p><strong>Hot Packages</strong></p>
              </div>
              <div className="header2 poinst">
                <div className="col-sm-12 erng">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-3 paddCss paddCss2 col-4">
                          <label><strong>60 <img src={theomo} style={{ width: "10px", marginRight: "2px", marginTop: "-7px" }} /> Points</strong></label>
                        </div>
                        <div className="col-sm-2 paddCss paddCss2 col-4">
                          <span className="light" >$60</span>
                          <span className="dark">$50</span>
                        </div>
                        <div className="col-sm-3 paddCss paddCss2 nd">
                        </div>
                        <div className="col-sm-2 paddCss paddCss2 col-3">
                          <button id="search-button" type="button" class="btn btn-primary mr-5" onClick={e => selectpoint("50 - 60")}>Buy</button>
                        </div>
                        <div className="col-sm-2 paddCss paddCss2 nd">
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-3 paddCss paddCss2 col-4">
                          <label><strong>130 <img src={theomo} style={{ width: "10px", marginRight: "2px", marginTop: "-7px" }} /> Points</strong></label>
                        </div>
                        <div className="col-sm-2 paddCss paddCss2 col-4">
                          <span className="light" >$130</span>
                          <span className="dark">$100</span>
                        </div>
                        <div className="col-sm-3 paddCss paddCss2  nd">
                        </div>
                        <div className="col-sm-2 paddCss paddCss2 col-3">
                          <button id="search-button" type="button" class="btn btn-primary mr-5" onClick={e => selectpoint("100 - 130")}>Buy</button>
                        </div>
                        <div className="col-sm-2 paddCss paddCss2 nd">
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-3 paddCss paddCss2 col-4">
                          <label><strong>350 <img src={theomo} style={{ width: "10px", marginRight: "2px", marginTop: "-7px" }} /> Points</strong></label>
                        </div>
                        <div className="col-sm-2 paddCss paddCss2 col-4">
                          <span className="light" >$350</span>
                          <span className="dark">$250</span>
                        </div>
                        <div className="col-sm-3 paddCss paddCss2  nd">
                        </div>
                        <div className="col-sm-2 paddCss paddCss2 col-3">
                          <button id="search-button" type="button" class="btn btn-primary mr-5" onClick={e => selectpoint("250 - 350")}>Buy</button>
                        </div>
                        <div className="col-sm-2 paddCss paddCss2 nd">
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 paddCss ">
                      <label><strong>Want to buy points individually</strong></label>
                    </div>
                    <div className="col-sm-2 paddCss ">
                      <label><strong>Buy Points</strong></label>
                    </div>
                    <div className="col-sm-8 paddCss ">
                      <div className="datesearch poi">
                        <input type="number" placeholder="Points" onChange={e => setcostumpoin(e.target.value)} style={{ textAlign: "left" }} />
                        <span></span>
                        <input type="text" placeholder="price" value={costumpoin ? "$" + costumpoin : ""} style={{ textAlign: "left" }} />
                        <button style={{ float: "right" }} id="search-button" class="btn btn-primary mr-5" onClick={e => {
                          costumpoin ? selectpoint(costumpoin + '-' + costumpoin) : toast.error("Select point")
                        }}>Buy</button>
                      </div>
                    </div>
                    {/* {
                      points.total ? <Payment points={points} /> : ""
                    } */}
                    <div className="popup row col-sm-12">
                      <Modal
                        show={showPopup}
                        id="questionForm"
                        onHide={handleCloseSignupForm}
                        size="lg"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title style={{ width: "100%" }}>
                            <div className="col-sm-12 paddCss" style={{ textAlign: "center" }}>
                              <strong>{"Order Summary"}</strong>
                            </div></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Container>
                            <Row>
                              <div className="col-sm-4 paddCss nd">
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>Theom points </strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.Points ? points.Points : 0}</label>
                              </div>
                              <div className="col-sm-3 paddCss nd">
                              </div><div className="col-sm-4 paddCss nd">
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>Amount</strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label> {points.Amount ? "$" + points.Amount : 0}</label>
                              </div>
                              <div className="col-sm-3 paddCss nd">
                              </div><div className="col-sm-4 paddCss nd">
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>GST – 5%</strong> </label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.GST ? "$" + points.GST : 0}</label>
                              </div>
                              <div className="col-sm-3 paddCss nd">
                              </div>
                              <div className="col-sm-4 paddCss nd">
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>PST – 7% </strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.PST ? "$" + points.PST : 0}</label>
                              </div>
                              <div className="col-sm-3 paddCss nd">
                              </div>
                              <div className="col-sm-4 paddCss nd">
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>Total Amount to paid</strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.total ? "$" + points.total : 0}</label>
                              </div>
                              <div className="col-sm-3 paddCss nd">
                              </div>
                              <div className="col-sm-10 paddCss nd">
                              </div>
                              <div className="col-sm-2 paddCss" style={{ textAlign: 'left' }}>
                                <button id="search-button" type="button" class="btn btn-primary mr-5" onClick={e => {
                                  setPopup(false)
                                  setPopup2(true)
                                }} >NEXT</button>
                              </div>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>
                      {/*  */}

                      <Modal
                        show={showPopup2}
                        id="questionForm"
                        onHide={handleCloseSignupForm}
                        size="lg"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            <div className="col-sm-12 paddCss" style={{ textAlign: "center" }}>
                              <strong>{"Billing Address"}</strong>
                            </div>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Container>
                            <Row>
                              <div className="col-sm-6">
                                <div className="col-sm-12 paddCss" >
                                  <label><strong>First Name</strong></label>
                                </div>
                                <div className="col-sm-12 paddCss" style={{ padding: "6px 10px " }}>
                                  <input type="text" className="form-control" name="fname" placeholder="First Name" onChange={e => billdetail(e)} required />
                                  {checkpoints.fname ? "" : <span className="errorMessage">required *</span>}
                                </div>
                              </div>
                              <div className="col-sm-6 ">
                                <div className="col-sm-12 paddCss" >
                                  <label><strong>Last Name</strong></label>
                                </div>
                                <div className="col-sm-12 paddCss" style={{ padding: "6px 10px " }}>
                                  <input type="text" className="form-control" name="lname" placeholder="Last Name" onChange={e => billdetail(e)} required />
                                  {checkpoints.lname ? "" : <span className="errorMessage">required *</span>}
                                </div>
                              </div>
                              <div className="col-sm-6 ">
                                <div className="col-sm-12 paddCss" >
                                  <label><strong>Street Address</strong></label>
                                </div>
                                <div className="col-sm-12 paddCss" style={{ padding: "6px 10px" }}>
                                  <input type="text" className="form-control" name="address" placeholder="Address" onChange={e => billdetail(e)} required />
                                  {checkpoints.address ? "" : <span className="errorMessage">required *</span>}
                                </div>
                              </div>
                              <div className="col-sm-6 ">
                                <div className="col-sm-12 paddCss" >
                                  <label><strong>City</strong></label>
                                </div>
                                <div className="col-sm-12 paddCss" style={{ padding: "6px 10px " }}>
                                  <input type="text" className="form-control" name="city" placeholder="City" onChange={e => billdetail(e)} required />
                                  {checkpoints.city ? "" : <span className="errorMessage">required *</span>}
                                </div>
                              </div>
                              <div className="col-sm-6 ">
                                <div className="col-sm-12 paddCss" >
                                  <label><strong>Country</strong></label>
                                </div>
                                <div className="col-sm-12 paddCss" style={{ padding: "6px 10px " }}>
                                  <input type="text" className="form-control" name="country" placeholder="Country" onChange={e => billdetail(e)} required />
                                  {checkpoints.country ? "" : <span className="errorMessage">required *</span>}
                                </div>
                              </div>
                              <div className="col-sm-6 ">
                                <div className="col-sm-12 paddCss" >
                                  <label><strong>Province</strong></label>
                                </div>
                                <div className="col-sm-12 paddCss" style={{ padding: "6px 10px " }}>
                                  <input type="text" className="form-control" name="province" placeholder="Province" onChange={e => billdetail(e)} required />
                                  {checkpoints.province ? "" : <span className="errorMessage">required *</span>}
                                </div>
                              </div>

                              <div className="col-sm-6 ">
                                <div className="col-sm-12 paddCss" >
                                  <label><strong>Postal Code</strong></label>
                                </div>
                                <div className="col-sm-12 paddCss" style={{ padding: "6px 10px " }}>
                                  <input type="text" className="form-control" name="postal" placeholder="Postal Code" onChange={e => billdetail(e)} required />
                                  {checkpoints.postal ? "" : <span className="errorMessage">required *</span>}
                                </div>
                              </div>
                              <div className="col-sm-6"></div>
                              <div className="col-sm-2 paddCss col-6" style={{ textAlign: 'left' }}>
                                <button id="search-button" type="button" class="btn btn-primary ml-3" onClick={e => {
                                  setPopup(true)
                                  setPopup2(false)
                                }} >Back</button>
                              </div>
                              <div className="col-sm-8 paddCss col-0 nd">
                              </div>
                              <div className="col-sm-2 paddCss col-6" style={{ textAlign: 'left' }}>
                                {
                                  detailbill == true ? <button id="search-button" type="button" class="btn btn-primary mr-5"
                                    onClick={e => {
                                      setPopup2(false)
                                      setPopup3(true)
                                    }} >NEXT</button> :
                                    <button id="search-button" type="button" class="btn btn-primary mr-5"
                                      onClick={e => {
                                        fieldsrequired()
                                      }} >NEXT</button>
                                }

                              </div>

                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>

                      {/*  */}
                      <Modal
                        show={showPopup3}
                        id="questionForm"
                        onHide={handleCloseSignupForm}
                        size="lg"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            <div className="col-sm-12 paddCss" style={{ textAlign: "center" }}>
                              <strong>{"Credit Card Details"}</strong>
                            </div>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Container>
                            <Row>
                              <Elements stripe={stripePromise}>
                                <CheckoutForm data={points} />
                              </Elements>
                              {/* <button className="button btn1" onClick={e => purchase()}>Place Order</button> */}
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>

                      <Modal
                        show={showPopup5}
                        id="questionForm"
                        onHide={handleCloseSignupForm}
                        size="sm"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            <div className="col-sm-12">
                              <strong  >Confirmation</strong>
                            </div>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <button className="button btn1" onClick={e => purchase()} style={{ margin: "0 auto", display: "block" }}>Place Order</button>
                        </Modal.Body>
                      </Modal>
                      <Modal
                        show={showPopup4}
                        id="questionForm"
                        onHide={handleCloseSignupForm}
                        size="lg"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            <div className="col-sm-12 paddCss">
                              <strong>{"Payment Summary"}</strong>
                            </div>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Container>
                            <Row>
                              <div className="col-sm-12 paddCss" style={{ paddingBottom: "0", paddingTop: "0" }}>
                                <h5><strong>{"Order Summary"}</strong></h5>
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>Theom points </strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.Points ? points.Points : 0}</label>
                              </div>
                              <div className="col-sm-2 paddCss">
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>Amount</strong></label>
                              </div>

                              <div className="col-sm-2 paddCss">
                                <label> {points.Amount ? "$" + points.Amount : 0}</label>
                              </div>



                              <div className="col-sm-3 paddCss">
                                <label><strong>GST – 5%</strong> </label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.GST ? "$" + points.GST : 0}</label>
                              </div>
                              <div className="col-sm-2 paddCss">
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>PST – 7% </strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.PST ? "$" + points.PST : 0}</label>
                              </div>

                              <div className="col-sm-3 paddCss">
                                <label><strong>Total Amount to paid</strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.total ? "$" + points.total : 0}</label>
                              </div>
                              <div className="col-sm-7 paddCss">
                              </div>
                              <div className="col-sm-12 paddCss mt-2 " style={{ paddingBottom: "0" }}>
                                <h5><strong>{"Billing Address"}</strong></h5>
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>First name</strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.fname}</label>
                              </div>
                              <div className="col-sm-2 paddCss">
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>Last name</strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.lname}</label>
                              </div>

                              <div className="col-sm-3 paddCss">
                                <label><strong>Street Address</strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.address}</label>
                              </div>
                              <div className="col-sm-2 paddCss">
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>City</strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.city}</label>
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>Country</strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.country}</label>
                              </div>
                              <div className="col-sm-2 paddCss">
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>Province</strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.province}</label>
                              </div>
                              <div className="col-sm-3 paddCss">
                                <label><strong>Postal Code</strong></label>
                              </div>
                              <div className="col-sm-2 paddCss">
                                <label>{points.postal}</label>
                              </div>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>



                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
