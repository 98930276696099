import React from 'react';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import service5 from '../assets/homepage/service1.png';
import { Star as AddIcon } from '@material-ui/icons';
import Showreview from "../review/Showreview";
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import saveIcon from '../assets/Icons/save.png';
import Admin from './Admin.css';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom'
import $ from 'jquery';


export default function Liveoperation() {

  let redirectId = []
  // const adminData = localStorage.getItem("user")
  const [tableData, setTableData] = useState([]);
  const [tableDatafix, setTableDatafix] = useState([]);
  const [value, setValue] = useState({});
  const [redirect, setredirect] = useState(false);
  const [count, setcount] = useState(false);
  const [reflect, setreflect] = useState([])
  useEffect(() => {
    async function providerDetails() {
      if (!count) {
        setcount(true)
        fetch(`${API}/user/getAllOrder`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
          .then(response => response.json().then(data => {
            if (response.status == 200) {
              setTableData(data)
              data.map((e) => tableDatafix.push(e))
            } else {
              toast.error("Ooops!! Failed to fetch data.");
            }
          })
          )
          .catch(err => toast.error("Ooops!! Something went wrong."));
      }
    }
    providerDetails();
  }, [tableData, tableDatafix, count, reflect]);



  const name = (data) => {
    if (data.target.value.length > 0) {
      if (tableData.length) {
        let input = []
        let find = tableData.map(x => {
          x.professionalId.map((y) => {
            if ((y.professionalId.first_name + y.professionalId.last_name).toLowerCase().search(data.target.value.replace(/ /g, "").toLowerCase()) >= 0 || y.professionalId.first_name.toLowerCase().search(data.target.value.toLowerCase()) >= 0) {
              x.professionalId = [y]
              input.push(x)
            }
          })

        })
        setTableData(input)
      }
    }
    else {
      $(".admintable").addClass("d-nonee");
      setTableData(tableDatafix)
    }
  }
  const username = (data) => {
    if (data.target.value.length > 0) {
      if (tableData.length) {
        let input = data.target.value
        let find = tableData.filter(x => (x.userId.first_name + x.userId.last_name).toLowerCase().search(data.target.value.replace(/ /g, "").toLowerCase()) >= 0 || x.userId.first_name.toLowerCase().search(data.target.value.toLowerCase()) >= 0)
        setTableData(find)
      }
    }
    else {
      $(".admintable").addClass("d-nonee");
      setTableData(tableDatafix)
    }
  }
  const service = (data) => {
    if (data.target.value.length > 0) {
      if (tableData.length) {
        let find = tableData.filter(x => x.subCategory ? x.subCategory.toLowerCase().search(data.target.value.toLowerCase()) >= 0 : "")
        setTableData(find)
      }
    }
    else {
      setTableData(tableDatafix)
    }
  }
  const clicked = (data) => {
    // setValue(id)
    localStorage.setItem(
      'ordercurrentlyClicked', JSON.stringify(data)
    )
    // setredirect(true)
  }
  const ordernumber = (data) => {
    if (data.target.value.length > 0) {
      if (tableData.length) {
        let find = tableData.filter(x => x.orderID.toString().search(data.target.value) >= 0)
        setTableData(find)
      }
    }
    else {
      setTableData(tableDatafix)
    }
  }
  const status = (data) => {
    let table = []
    if (data.target.value.length > 0) {
      let find = tableDatafix.map(x => {
        return (x.professionalId.map(y => {
          if (y.status.toLowerCase() == data.target.value.toLowerCase()) {
            x.professionalId = [y]
            table.push(x)
          }
        })
        )
      })
      setTableData(table)
    }
    else {
      $(".admintable").addClass("d-nonee");
      setTableData(tableDatafix)
    }
  }

  const providerTable = ((x, i) => {
    return (
      x.isSeen == true ? x.professionalId.map((e) => {
        if (e.status == "Completed" || e.status == "Cancelled" || e.status == "Accepted" || e.status == "Reject") {
          return (
            <tr key={i} className={x.isAdmin == true ? "highlight" : ""}>
              <td><Link to={{ pathname: "/newAdmin/live-operations-details", state: x }} className="nav-link" onClick={e => clicked(x)}>{x.orderID}</Link></td>
              <td>{x.subCategory}</td>
              <td>
                {
                  e.professionalId.first_name + " " + e.professionalId.last_name
                }
              </td>
              <td>{x.userId.first_name + " " + x.userId.last_name}</td>
              <td>{e.status == "Reject" ? e.status + "ed" : e.status}</td>
            </tr>
          )
        }
      }) :
        <tr key={i} className={x.isAdmin == true ? "highlight" : ""}>
          <td><Link to={{ pathname: "/newAdmin/live-operations-details", state: x }} className="nav-link" onClick={e => clicked(x)}>{x.orderID}</Link></td>
          <td>{x.subCategory}</td>
          <td>
            {
              <select class="ordermulty">
                {
                  x.professionalId.map((e) => {
                    return (
                      <option>{e.professionalId.first_name + " " + e.professionalId.last_name}</option>
                    )
                  })
                }
              </select>
            }
          </td>
          <td>{x.userId.first_name + " " + x.userId.last_name}</td>
          <td>{x.professionalId[0].status}</td>
        </tr>
    )
    // return (
    //   x.uploadDocument.length > 0 ?

    //     :

    // )
  })

  return (
    <div className="AdminLiveOperation">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div className="row headerHeading">
        <div className="menuHeading">
          <h3 className="mt-4 mb-4"><span className="heading-1">Live Operations</span></h3>
        </div>
      </div>

      <form>
        <div className="row form-content-liveOperation mt-2">
          <div className="col-sm-2 live-operation-div taskButton">
            <div class="form-group ">
              <input type="text" className="form-control" name="order_no" onChange={e => ordernumber(e)} name="nameProfessional" placeholder="Order No." />
            </div>
          </div>
          <div className="col-sm-3 live-operation-div serviceType">
            <div class="form-group ">
              <input type="text" className="form-control" name="service_type" placeholder="Name of Service" onChange={e => service(e)} />
            </div>
          </div>

          <div className="col-sm-3 live-operation-div customerName">
            <div class="form-group ">
              <input type="text" className="form-control" name="professional_name" placeholder="Professional Name" onChange={e => name(e)} />
            </div>
          </div>
          <div className="col-sm-3 live-operation-div customerName">
            <div class="form-group ">
              <input type="text" className="form-control" name="customer_name" placeholder="Customer Name" onChange={e => username(e)} />
            </div>
          </div>

          {/* <div className="col-sm-3 live-operation-div contactNo">
            <div class="form-group ">
              <input type="text" className="form-control" name="contact_no" placeholder="Contact No." onChange={e => ordernumber(e)} />
            </div>
          </div> */}
          {/* <div className="col-sm-2 live-operation-div">
                   <button type="button" class="btn btn-block admin-status-btn">Search</button>
                </div> */}
          <div className="col-sm-2 live-operation-div  orderstatus">
            <div className="responsive">
              <div class="form-group ">
                <select class="form-control" onChange={e => status(e)}>
                  <option value="" selected>Order Status</option>
                  <option value="pending">Pending</option>
                  <option value="accepted">Accepted</option>
                  <option value="reject">Rejected</option>
                  <option value="completed">completed</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="table-responsive">
        <table className="table admintable text-center tableLayout mt-2">
          <thead>
            <tr className="tableHeading">
              <th className="admiTable-first tableHeadingColumn" scope="col">Order No.</th>
              <th className="tableHeadingColumn" scope="col">Name of Service</th>
              <th className="tableHeadingColumn" scope="col">Professional Name</th>
              <th className="tableHeadingColumn" scope="col">Customer Name</th>
              <th className="admiTable-last tableHeadingColumn" scope="col">Order Status</th>
            </tr>
          </thead>
          <tbody>
            {tableData.length > 0 ? tableData.map(providerTable)
              : ""}
          </tbody>
        </table>
      </div>
    </div>
  )
}
