import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 130,
  }, notInThisMonthDayPaper: {
    width: "35px",
    height: "35px",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "50%",
    padding: "9px",
    cursor: "pointer",
    textAlign: "center"
  },
  normalDayPaper: {
    width: "35px",
    height: "35px",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "50%",
    padding: "9px",
    cursor: "pointer",
    textAlign: "center",
  },
  selectedDayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "#0060df",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "50%",
    padding: "9px",
    cursor: "pointer",
    textAlign: "center",
    color: "white"
  },
  todayPaper: {
    width: "35px",
    height: "35px",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "50%",
    padding: "9px",
    cursor: "pointer",
    textAlign: "center",
  },
}));

export default function Availability() {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState("2021-05-22");
  const [date, setdate] = useState('')
  const [tableData, setTableData] = useState([]);
  const [tableDatafix, setTableDatafix] = useState([]);
  const [count, setcount] = useState(false);
  const [component, setComponent] = useState('dashboard')
  const [availibility, setavailibility] = useState([])
  const [checkab, setcheckab] = useState('')
  const [checkab2, setcheckab2] = useState([])
  const [providerData, setproviderData] = React.useState(JSON.parse(sessionStorage.getItem("providerData")))
  const [fromt, setfrom] = useState('')
  const [totime, settotime] = useState('')
  const [updated, setupdated] = useState({})
  const [again, setagain] = useState('')
  const [weekly, setweekly] = useState(false)
  const [monthly, setmonthly] = useState(false)

  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const handleDateChange = (date) => {
    setagain(date)
    setcheckab2([])
    setcheckab('')
    setfrom('')
    settotime('')
    let newdate = new Date(date).getDate() + '/' + (new Date(date).getMonth() + 1) + '/' + new Date(date).getFullYear()
    let newdate2 = (new Date(date).getMonth() + 1) + '/' + new Date(date).getDate() + '/' + new Date(date).getFullYear()
    setSelectedDate(newdate2);
    setdate(newdate2)
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    fetch(`${API}/professional/dateWiseData?userId=` + providerData._id + `&date=` + newdate2, requestOptions)
      .then(response => response.json())
      .then(result => {
        setavailibility(result.data)
        if (result.data.length > 0) {
          setcheckab(result.data)
          setcheckab2(result.data)
        } else {
          let x = [{ "monthly": false, "weekly": false }]
          setcheckab2(x)
        }

      })
      .catch(err => toast.error("Ooops!! Something went wrong."));
  };

  const updateabl = (e, eid, time) => {
    setupdated({ ...updated, [time]: e, ["_id"]: eid, ["weekly"]: weekly, ["monthly"]: monthly })
  }


  const deflchk = () => {
    let data2 = document.getElementById("weekly3")
    setmonthly(false)
    if (data2.checked == true) {
      data2.checked = false
    }
  }
  const deflchk2 = () => {
    let data3 = document.getElementById("weekly2")
    setweekly(false)
    if (data3.checked == true) {
      data3.checked = false
    }
  }

  const addsedule = () => {
    if (fromt != '' && totime != '') {
      let data = {
        "userId": providerData._id,
        "date": date,
        "startTime": fromt,
        "endTime": totime,
        "weekly": weekly,
        "monthly": monthly,
      }
      fetch(`${API}/professional/saveUserAvailibility`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json()
          .then(result => {
            handleDateChange(again)
            toast.success(result.msg)
            setcount(false)
            setweekly(false)
            setmonthly(false)
          }))
        .catch(err => toast.error("Ooops!! Something went wrong."));
    } else {
      if (updated._id != undefined) {
        let data = updated
        fetch(`${API}/professional/updateAvailibility`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        })
          .then(res => res.json()
            .then(result => {
              toast.success("Updated Successfully")
            }))
          .catch(err => toast.error("Ooops!! Something went wrong."));
      }
      else {
        // toast.error("Please Change Time")
        saveNewSetting()
      }
    }
  }



  useEffect(() => {
    if (!count) {
      setcount(true)
      let newdate = new Date()
      let newdate2 = new Date(newdate).getFullYear() + '-' + (new Date(newdate).getMonth() + 1) + '-' + new Date(newdate).getDate()
      setSelectedDate(newdate2);
      handleDateChange(newdate2)
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      fetch(`${API}/user/userAvailibility/` + providerData._id, requestOptions)
        .then(response => response.json())
        .then(result => {
          result.map((date) => {
            return date.date ? sunnyDays.push(date.date) : ""
          })
        })
        .catch((err) => toast.error("Ooops!! Something went wrong. user"));
    }
  }, [availibility, providerData, selectedDate, count, weekly, monthly])

  const saveNewSetting = () => {
    let get = JSON.parse(sessionStorage.getItem("providerData"))
    if (get) {
      fetch(`${API}/professional/updatePersonalSetting`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(get)
        })
        .then(response => response.json().then(data => {
          if (response.status == 200) {
            toast.success("Details Updated Successfully .");
            sessionStorage.setItem('providerData', JSON.stringify(data))
          }
          else {
            toast.error("Ooops!! Updation Failed .");
          }
        })
        )
        .catch(err => toast.error("Ooops!! Something went wrong."));
    }
  }


  const [today, settoday] = useState(new Date()); // just Date object of today
  const [sunnyDays, setsunnyDays] = useState([]) // array of sunny days 1st,6th etc
  const getDayElement = (day, selectedDate2, isInCurrentMonth, dayComponent) => {
    let isSunny = false
    const nnn = sunnyDays.map((e) => {
      if (day.getDate() == (new Date(e).getDate() + 1) && day.getMonth() == (new Date(e).getMonth()) && day.getFullYear() == (new Date(e).getFullYear())) {
        isSunny = true
      }
    })
    const isSelected = day.getDate() === selectedDate2.getDate() && day.getMonth() === selectedDate2.getMonth() && day.getFullYear() == (selectedDate2.getFullYear());
    const isToday = day.getDate() === today.getDate() && day.getMonth() === today.getMonth() && day.getFullYear() == (today.getFullYear());
    let dateTile
    if (isInCurrentMonth && day.getDate() >= today.getDate() && day.getMonth() == today.getMonth()) { //conditionally return appropriate Element of date tile.
      if (isSunny) {
        dateTile = (
          <Paper className={isSelected ? classes.selectedDayPaper : isToday ? classes.todayPaper : classes.normalDayPaper} style={isSelected ? {} : { backgroundColor: "green", color: "white" }}>
            <Grid item >
              {day.getDate()}
            </Grid>
          </Paper>)
      } else {
        dateTile = (
          <Paper className={isSelected ? classes.selectedDayPaper : isToday ? classes.todayPaper : classes.normalDayPaper}>
            <Grid item> {day.getDate()}</Grid>
          </Paper>)
      }

    } else {
      dateTile = (<Paper className={classes.notInThisMonthDayPaper}>
        <Grid item style={{ color: "lightGrey" }}>
          {day.getDate()}
        </Grid>
      </Paper>)
    }
    return dateTile
  }

  const onPickerViewChange = async (date) => {
    let x = new Date()
    if (x.getMonth() == date.getMonth()) {
      settoday(x)
    } else (
      settoday(date)
    )
  }
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div className="container avlbt">
        <div className="row-width row mt-5">
          <div className="col-xs-6 col-sm-6 col-md-7 col-lg-7">
            <div className="table-responsive design">
              <table className="table  table-bordered text-center">
                <thead>
                  <tr className="table-heading">
                    <th className="table-heading-first" scope="col">Days</th>
                    <th scope="col">Time</th>
                  </tr>
                </thead>
                <tbody>
                  {checkab != '' ?
                    availibility.map((e) => {
                      return (
                        <>
                          <tr>
                            <th scope="row" style={{ color: "green" }}>{days[new Date(selectedDate).getDay()]}</th>
                            <td>
                              <form className={classes.container} noValidate>
                                <TextField
                                  id="timefield"
                                  type="time"
                                  defaultValue={e.startTime}
                                  className={classes.textField}
                                  onChange={(x) => updateabl(x.target.value, e._id, "startTime")}
                                  inputProps={{
                                    step: 300, // 5 min
                                  }}
                                />To<TextField
                                  id="timefield"
                                  type="time"
                                  defaultValue={e.endTime}
                                  className={classes.textField}
                                  onChange={(x) => updateabl(x.target.value, e._id, "endTime")}
                                  inputProps={{
                                    step: 300, // 5 min
                                  }}
                                />
                              </form>
                            </td>
                          </tr>
                        </>
                      )
                    })
                    :
                    <>
                      <tr>
                        <th scope="row" style={{ color: "red" }}>{days[new Date(selectedDate).getDay()]}</th>
                        <td>
                          <form className={classes.container} noValidate>
                            <TextField
                              id="timefield"
                              type="time"
                              defaultValue="00:00"
                              name="from"
                              className={classes.textField}
                              onChange={(e) => setfrom(e.target.value)}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                            /><span>To</span><TextField
                              id="timefield"
                              type="time"
                              name="to"
                              defaultValue="00:00"
                              className={classes.textField}
                              onChange={(e) => settotime(e.target.value)}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                            />
                          </form>
                        </td>
                      </tr>
                    </>
                  }
                </tbody>
              </table>
            </div>
            <div class="fom" id="tblFruits">
              <div className="form-control fd">
                <div className="row ">
                  <div className="col-sm-8 col-10">
                    <div className="">
                      <label for="1">Repeat availibility for 2 Weeks</label>
                    </div>
                  </div>
                  <div className="col-sm-4 col-2">
                    <div className="">
                      {
                        checkab2 != [] ? checkab2.map((e) => {
                          if (e.weekly != "undefined") {
                            return <input type="checkbox" name="weekly" id="weekly2" value="weekly" onChange={(e) => {
                              setweekly(e.target.checked)
                              setTimeout(function () {
                                deflchk()
                              }, 1000);
                            }} defaultChecked={e.weekly} />
                          } else {
                            return <input type="checkbox" name="weekly" id="weekly2" defaultChecked={weekly} value="weekly" onChange={(e) => {
                              setweekly(e.target.checked)
                            }} />
                          }
                        }) : ""
                      }
                      {/* <input type="checkbox" name="weekly" id="weekly" value="weekly" onChange={(e) => setweekly(e.target.checked)} /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-control fd">
                <div className="row">
                  <div className="col-sm-8 col-10">
                    <div className="">
                      <label for="2">Repeat availibility  Monthly</label>
                    </div>
                  </div>
                  <div className="col-sm-4 col-2">
                    <div className="">
                      {
                        checkab2 != [] ? checkab2.map((e) => {
                          if (e.monthly != "undefined") {
                            return <input type="checkbox" name="weekly" id="weekly3" value="weekly" onChange={(e) => {
                              setmonthly(e.target.checked)
                              setTimeout(function () {
                                deflchk2()
                              }, 1000);
                            }} defaultChecked={e.monthly} />
                          } else {
                            return <input type="checkbox" name="weekly" id="weekly3" defaultChecked={monthly} value="weekly" onChange={(e) => {
                              setmonthly(e.target.checked)
                            }
                            } />
                          }
                        }) : ""
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              {/* <div className="col-sm-4">
                <button className="button btn1">Cancel</button>
              </div> */}
              <div className="col-sm-6">
                <button className="button btn1" onClick={e => addsedule()}> Save</button>
              </div>
            </div>

          </div>
          <div className="col-xs-6 col-sm-6 col-md-5 col-lg-5">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="date-picker-inline"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  onMonthChange={onPickerViewChange}
                  renderDay={(day, selectedDate2, isInCurrentMonth, dayComponent) => getDayElement(day, selectedDate2, isInCurrentMonth, dayComponent)}
                  disablePast={true}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>
    </>
  );
}
