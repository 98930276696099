import React from "react";
import { CardElement, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { API } from "../backend";

export const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isPaymentLoading, setPaymentLoading] = React.useState(false);
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": true,
        "Access-Control-Allow-Credentials": true,
    }
};

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentLoading(true);
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
      card: cardExpiryElement,
      card: cardCvcElement,
      // card: elements.getElement(CardElement),
      billing_details: {
        address: { city: props.data.city, country: 'CA', line1: props.data.address, postal_code: props.data.postal },
        // email: 'rajendradeveloper11@gmail.com',
        name: props.data.fname + ' ' + props.data.lname,
        // phone: '7042250788'
      },

    });

    if (!error) {
      try {
        const amounttotal = (props.data.total * 100)
        const { id } = paymentMethod;
        const response = await axios.post(
          "http://localhost:3001/stripe/charge",
          {
            amount: amounttotal,
            id: id,
            currency: 'INR',
            description: 'All Payments Done by' + (props.data.fname + ' ' + props.data.lname),
          },axiosConfig
        );

        if (response.data.success) {
          purchase()
          toast.success("payment successful!")
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      toast.error(error.message)
    }
    setPaymentLoading(false);
  };


  const purchase = () => {
    let bookingdata = {
      "professionalId": props.data.id,
      "totalAmmount": props.data.total,
      "succesToken": "abcdefsdsghijklmnopqrst",
      "totalPoint": props.data.Points,
      "paymentStatus": "buy"
    }
    fetch(`${API}/professional/transaction`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bookingdata)
    })
      .then(res => res.json().then(result => {
        toast.success("purchase Successful please check total points")
        // setTimeout(function () { window.location.reload(false) }, 2000);
      }))
      .catch(error => console.log('error', error));
  }



  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />

      <form onSubmit={handleSubmit} className="row col-sm-12 ">
        <div className="col-sm-6 ">
          <div className="col-sm-12 paddCss" style={{ padding: "6px 10px 0" }}>
            <label><strong>Name on Card</strong></label>
          </div>
          <div className="col-sm-12 paddCss" style={{ padding: "6px 10px " }}>
            <input type="text" className="form-control" name="" placeholder="Name" />
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="col-sm-12 paddCss" style={{ padding: "6px 10px 0" }}>
            <label><strong>Card Number</strong></label>
          </div>
          <div className="col-sm-12 paddCss" style={{ padding: "6px 10px " }}>
            <CardNumberElement className="form-control payformd" />
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="col-sm-12 paddCss" style={{ padding: "6px 10px 0" }}>
            <label><strong>Expiry</strong></label>
          </div>
          <div className="col-sm-12 paddCss" style={{ padding: "6px 10px" }}>
            <CardExpiryElement className="form-control payformd" />
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="col-sm-12 paddCss" style={{ padding: "6px 10px 0" }}>
            <label><strong>CVV</strong></label>
          </div>
          <div className="col-sm-12 paddCss" style={{ padding: "6px 10px" }}>
            <CardCvcElement className="form-control payformd" />
          </div>
        </div>

        <div className="col-sm-2 paddCss" style={{ textAlign: 'left' }}>
        </div>
        <div className="col-sm-8 paddCss">
        </div>
        <div className="col-sm-2 paddCss" style={{ textAlign: 'left' }}>
          <div class="make_payment">
            <button
              className="pay-button"
              disabled={isPaymentLoading}
            >
              {isPaymentLoading ? "Loading..." : "Pay"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};