import React, { useState } from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import { Link } from 'react-router-dom';
import './Admin.css';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import $, { get } from 'jquery';
export default function SearchEarning() {
  const [component, setComponent] = useState('dailyupdate')
  const [color1, setColor1] = useState('#EA4337')
  const [color2, setColor2] = useState('#EA4337')
  const [color3, setColor3] = useState('#EA4337')
  const [color4, setColor4] = useState('#EA4337')
  const [color5, setColor5] = useState('#ccc')
  const [vl, setvl] = React.useState(3)
  const [color6, setColor6] = useState('')
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  });
  const [display1, setDisplay1] = useState('none')
  const [display2, setDisplay2] = useState()
  const [display3, setDisplay3] = useState('none')
  var currentlyClicked;
  const getcurrentlyClicked = (() => {
    currentlyClicked = JSON.parse(localStorage.getItem('currentlyClicked'));
  });
  const [updateprofile, setupdateprofile] = React.useState({})
  const [error, seterror] = useState({
    Transit: " 5 characters required",
    Financial: " 3 characters required",
    Account: " 12 characters required",
  })

  // const [component2, setComponent] = useState('dailyupdate')
  const [recent, setrecent] = useState([])
  const [month, setmonth] = useState([])
  const [monthpoint, setmonthpoint] = useState({})
  const [week, setweek] = useState([])
  const [weekpoint, setweekpoint] = useState({})
  const [costum, setcostum] = useState([])
  const [datetime, setdatetime] = useState('Weekly')
  const [costumpoint, setcostumpoint] = useState({})
  const [count, setcount] = useState(false)
  const [count2, setcount2] = useState(false)
  const [date, setdate] = useState({})
  const [point, setpoint] = useState('')
  const [point2, setpoint2] = useState('')
  const [reflect, setreflect] = useState('')
  const [monthdate, setmonthdate] = useState({
    now: "",
    old: ""
  })
  const [weektime, setweektime] = useState()
  const [cstmbtn, setcstmbtn] = useState(false)
  const [token, setpoint3] = useState(0)

  React.useEffect(() => {
    if (!count) {
      detailpoinst()
      setcount(true)
      todaydate()
    }
  }, [color4, month, week, recent, count, reflect, costumpoint, token])
  React.useEffect(() => {
    if (count2) {
      fiter3()
      setcount2(false)
    }
  }, [date, costumpoint, costum, costumpoint, count2, token])
  const detailpoinst = () => {
    document.getElementById("loder").style.display = "block"
    fetch(`${API}/professional/lastThreeTransaction?professionalId=` + currentlyClicked._id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => response.json().then(data => {
        setrecent(data.data)
        setreflect("hbibi")
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));

    // 
    fetch(`${API}/professional/getProfessinalToken?professionalId=` + currentlyClicked._id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => response.json().then(data => {
        setpoint3(data.data.totalPoint)
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
    todaydate()
    // 
    fetch(`${API}/professional/lastOneMonth?professionalId=` + currentlyClicked._id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => response.json().then(data => {
        setmonth(data.data)
        setreflect("hhlknpip")
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
    // 

    fetch(`${API}/professional/lastOneWeek?professionalId=` + currentlyClicked._id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => response.json().then(data => {
        setweek(data.data)
        document.getElementById("loder").style.display = "none"
        setreflect("hkbhibui")
        console.log(data.data)
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));

    // 


  }
  const costumfl = () => {
    setcstmbtn(true)
    let data = {
      "professionalId": currentlyClicked._id,
      "todate": (selectedDayRange.from.year + "-" + (selectedDayRange.from.month < 10 ? "0" + selectedDayRange.from.month : selectedDayRange.from.month) + "-" + (selectedDayRange.from.day < 10 ? "0" + selectedDayRange.from.day : selectedDayRange.from.day)),
      "fromdate": (selectedDayRange.to.year + "-" + (selectedDayRange.to.month < 10 ? "0" + selectedDayRange.to.month : selectedDayRange.to.month) + "-" + (selectedDayRange.to.day < 10 ? "0" + selectedDayRange.to.day : selectedDayRange.to.day))
    }

    fetch(`${API}/professional/dateRangeData`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json()
        .then(result => {
          if (result.status == 200) {
            setcostum(result.data)
            fiter3()

          } else {
            toast.error(result.msg)
          }

        }))
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }

  const fiter2 = () => {
    let data = { buy: 0, redeemed: 0, refund: 0 }
    week.map((e) => {
      if (e.paymentStatus == "buy") {
        data = {
          ...data, buy: (data.buy + e.totalPoint)
        }
      } else if (e.paymentStatus == "redeemed" || e.paymentStatus == "adminRedeemed") {
        data = {
          ...data, redeemed: (data.redeemed + e.totalPoint)
        }
      } else if (e.paymentStatus == "Refunded") {
        data = {
          ...data, refund: (data.refund + e.totalPoint)
        }
      } else {
      }
      setweekpoint(data)
    })
  }
  const fiter3 = () => {
    setcount2(true)
    let data = { buy: 0, redeemed: 0, refund: 0 }
    costum.map((e) => {
      if (e.paymentStatus == "buy") {
        data = {
          ...data, buy: (data.buy + e.totalPoint)
        }
      } else if (e.paymentStatus == "redeemed" || e.paymentStatus == "adminRedeemed") {
        data = {
          ...data, redeemed: (data.redeemed + e.totalPoint)
        }
      } else if (e.paymentStatus == "Refunded") {
        data = {
          ...data, refund: (data.refund + e.totalPoint)
        }
      } else {
      }

    })
    setcostumpoint(data)
    setcstmbtn(false)
  }
  const refund = () => {
    let bookingdata = {
      "professionalId": currentlyClicked._id,
      "totalPoint": point,
      "paymentStatus": "Refunded"
    }
    if (point) {
      fetch(`${API}/professional/transaction`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(bookingdata)
      })
        .then(res => res.json())
        .then(result => {
          toast.success("Refund Successful")
          detailpoinst()
          setComponent('dailyupdate')
          setDisplay1('none'); setDisplay2('block'); setDisplay3('none');
          setpoint('')
        })
        .catch(err => toast.error("Ooops!! Something went wrong."));
    } else {
      toast.error("Fill Refund")
    }
  }
  const redeem = () => {
    let bookingdata = {
      "professionalId": currentlyClicked._id,
      "totalPoint": point2,
      "paymentStatus": "adminRedeemed"
    }
    if (point2) {
      fetch(`${API}/professional/transaction`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(bookingdata)
      })
        .then(res => res.json())
        .then(result => {
          toast.success("Redeemed Successful")
          detailpoinst()
          setComponent('dailyupdate')
          setDisplay1('none'); setDisplay2('block'); setDisplay3('none');
          setpoint2('')
        })
        .catch(err => toast.error("Ooops!! Something went wrong."));
    } else {
      toast.error("Fill Redeemed")
    }
  }

  const fiter = () => {
    let data = { buy: 0, redeemed: 0, refund: 0 }
    month.map((e) => {
      if (e.paymentStatus == "buy") {
        data = {
          ...data, buy: (data.buy + e.totalPoint)
        }
      } else if (e.paymentStatus == "redeemed" || e.paymentStatus == "adminRedeemed") {
        data = {
          ...data, redeemed: (data.redeemed + e.totalPoint)
        }
      } else if (e.paymentStatus == "Refunded") {
        data = {
          ...data, refund: (data.refund + e.totalPoint)
        }
      } else {
      }
    })
    setTimeout(function () { setmonthpoint(data) }, 2000);

  }

  const handleChange = (cName, e) => {
    if (updateprofile == '') {
      setupdateprofile(currentlyClicked)
    } else {
      let newdata = { ...currentlyClicked, [e.target.name]: e.target.value }
      localStorage.setItem('currentlyClicked', JSON.stringify(newdata))
      // setupdateprofile(newdata)
      currentlyClicked[cName] = e.target.value
    }
  }
  const remove = (a, name, e) => {
    $(a).addClass("d-none");
    handleChange(name, e)
  }

  const handleChangeacount = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "transit_number":
        value.length < 5 ? $(".Transit").removeClass("d-none") : remove(".Transit", name, e);
        break;
      case "Financial_institution_number":
        value.length < 3 ? $(".Financial").removeClass("d-none") : remove(".Financial", name, e);
        break;
      case "Account_Number":
        value.length < 12 ? $(".Account").removeClass("d-none") : remove(".Account", name, e);
        break;
    }
  }


  const todaydate = () => {
    var today = new Date();
    var date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
    var date2 = today.getDate() + '-' + (today.getMonth()) + '-' + today.getFullYear();
    setmonthdate({ "old": date2, "now": date })

    var date3 = new Date();
    date3.setDate(date3.getDate() - 6);
    var date4 = date3.getDate() + '-' + (date3.getMonth() + 1) + '-' + date3.getFullYear();
    setweektime(date4)
  }
  return (
    <div className="container status-content">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div class="loder" id="loder">
        <div class="wrap">
          <div class="loading">
            <div class="bounceball"></div>
            <div class="text">NOW LOADING</div>
          </div>
        </div>
      </div>
      {getcurrentlyClicked()}
      <div className="contentBorder" style={{ overflow: 'visible' }}>
        <div className="row-width row">
          {/* <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={{ color: color1, boderBottomColor: color1, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid' }}>
            <button className="btn button activeclass" id="button" style={{ color: color3 }} onClick={() => { setColor1('#EA4337'); setColor2('#ccc'); setDisplay1('block'); setDisplay2('none'); setDisplay3('none'); setColor4(''); setColor5('#ccc'); setColor3('#EA4337'); setColor6('') }}>Booking Info</button>
          </div> */}
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{ color: color2, boderBottomColor: color2, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid ', textAlign: "center" }}>
            <button className="btn button pastclass" style={{ color: color4 }} onClick={() => { setColor1('#EA4337'); setColor2('#EA4337'); setDisplay1('none'); setDisplay2('block'); setDisplay3('none'); setColor3('#EA4337'); setColor5('#ccc'); setColor4('#EA4337'); setColor6('') }}>Summary</button>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{ color: color5, boderBottomColor: color5, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid ', textAlign: "center" }}>
            <button className="btn button pastclass" style={{ color: color6 }} onClick={() => { setColor1('#ccc'); setColor2('#ccc'); setDisplay1('none'); setDisplay2('none'); setColor3(''); setColor4(''); setColor5('#EA4337'); setColor6('#EA4337'); setDisplay3('block'); fiter() }}>Theom Points</button>
          </div>
        </div>
        <hr className="activepastline"></hr>
        <div className="responsive" style={{ display: display1 }}>
          <div class="form-group " style={{ marginBottom: 0 }}>
            <table className="table" style={{ marginBottom: 0 }}>
              <tr>
                <th>Transit number</th>
                <td><input type="text" name="transit_number" className="form-control" placeholder="5 digits" onChange={(e) => handleChangeacount(e)} defaultValue={currentlyClicked.transit_number} style={{ border: "none" }} />
                  {<span className="errorMessage Transit d-none">{error.Transit}</span>}
                </td>
              </tr>
              <tr>
                <th>Financial institution number</th>
                <td>
                  <input type="text" className="form-control" name="Financial_institution_number" placeholder="3 digits" onChange={(e) => handleChangeacount(e)} defaultValue={currentlyClicked.Financial_institution_number} style={{ border: "none" }} />
                  {<span className="errorMessage Financial d-none">{error.Financial}</span>}
                </td>
              </tr>
              <tr>
                <th>Account number</th>
                <td>
                  <input type="text" className="form-control" name="Account_Number" placeholder="Upto  12 digits" onChange={(e) => handleChangeacount(e)} defaultValue={currentlyClicked.Account_Number} style={{ border: "none" }} />
                  {<span className="errorMessage Account d-none">{error.Account}</span>}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="responsive" style={{ display: display2 }}>
          <div className="row col-md-12 ml-1" >
            <div className="col btn  card text-center dailyupdate" style={component == 'dailyupdate' ? { color: "white", background: "#EA4337 " } : {}} onClick={() => setComponent('dailyupdate')}>
              <h4>Recent</h4>
            </div>
            <div className="col btn card text-center dailyupdate" style={component == 'Weekly' ? { color: "white", background: "#EA4337" } : {}} onClick={() => {
              setComponent('Weekly')
              fiter2()
            }}>
              <h4>Summary</h4>
            </div>

          </div>
          <div className="dailydetail" style={component == 'Weekly' ? { display: 'none' } : {}}>

            <div className="table-responsive mt-5 col-md-12">
              <table className="table  table-bordered text-center" style={{ borderRadius: "8px", overflow: "hidden" }}>
                <thead>
                  <tr className="table-heading">
                    <th className="table-heading-first" scope="col">Date</th>
                    <th scope="col">Points</th>
                    <th scope="col">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {

                    recent.map((e, index) => {
                      if (index < vl) {
                        return (
                          <tr>
                            <th scope="row">
                              {new Intl.DateTimeFormat(['ban', 'id']).format(new Date(e.createdAt))}
                            </th>
                            <td>{e.totalPoint}</td>
                            <td style={{ textTransform: "capitalize" }}>{e.paymentStatus ? e.paymentStatus : ""}</td>
                          </tr>
                        )
                      }
                    })
                  }
                </tbody>
              </table>
              <div class="col-12 view mt-3">
                {vl <= 3 ? <Link onClick={e => setvl(100)}	>View More</Link> : <Link onClick={e => setvl(3)}	>View Less</Link>}

              </div>
            </div>
          </div>
          <div className="WeeklyDetails" style={component == 'dailyupdate' ? { display: "none" } : {}}>
            <div className="row-width row mt-5">
              <div className="col-sm-4">
                <div className="card  w-80 text-center cstm">
                  <tbody>
                    <tr style={{ display: "block" }}>
                      <th scope="row" className="statement text-center" ><button style={datetime == 'Weekly' ? { color: "white", background: "#EA4337", display: "block" } : { display: "block" }} onClick={() => {
                        setdatetime('Weekly')

                      }}>Weekly</button></th>
                    </tr>
                    <tr style={{ display: "block" }}>
                      <th scope="row" className="statement text-center" style={{ display: "block" }}><button style={datetime == 'Monthly' ? { color: "white", background: "#EA4337", display: "block" } : { display: "block" }} onClick={() => {
                        setdatetime('Monthly')
                        fiter()
                      }}>
                        Monthly
                      </button></th>
                    </tr>
                    <tr style={{ display: "block" }}>
                      <th scope="row" className="statement text-center" style={{ display: "block" }}><button style={datetime == 'Custom' ? { color: "white", background: "#EA4337", display: "block" } : { display: "block" }} onClick={() => setdatetime('Custom')}>
                        Custom
                      </button></th>
                    </tr>
                  </tbody>
                </div>
              </div>
              <div className="col-sm-8 col-12 margn" style={datetime == 'Weekly' ? {} : { display: "none" }}>
                <h2 class="h2set">From {weektime}  To {monthdate.now}</h2>
                <div className="card">
                  <div className="record">
                    <span>Total Balance</span>
                    <span>{weekpoint ? token : 0}</span>
                  </div>
                  <div className="record">
                    <span>Total Bought</span>
                    <span>{weekpoint.buy ? weekpoint.buy : 0}</span>
                  </div>
                  <div className="record">
                    <span>Total Redeemed</span>
                    <span>{weekpoint.redeemed ? weekpoint.redeemed : 0}</span>
                  </div>
                  <div className="record">
                    <span>Total Refunded</span>
                    <span>{weekpoint.refund ? weekpoint.refund : 0}</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-8" style={datetime == 'Monthly' ? {} : { display: "none" }}>
                <h2 class="h2set">From {monthdate.old}  To {monthdate.now}</h2>
                <div className="card">
                  <div className="record">
                    <span>Total Balance</span>
                    <span>{monthpoint ? token : 0}</span>
                  </div>
                  <div className="record">
                    <span>Total Bought</span>
                    <span>{monthpoint.buy ? monthpoint.buy : 0}</span>
                  </div>
                  <div className="record">
                    <span>Total Redeemed</span>
                    <span>{monthpoint.redeemed ? monthpoint.redeemed : 0}</span>
                  </div>
                  <div className="record">
                    <span>Total Refunded</span>
                    <span>{monthpoint.refund ? monthpoint.refund : 0}</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-8" style={datetime == 'Custom' ? { marginTop: "-47px" } : { display: "none" }}>
                <div className="datesearch">
                  <DatePicker
                    value={selectedDayRange}
                    onChange={setSelectedDayRange}
                    inputPlaceholder="Select a day range"
                    shouldHighlightWeekends
                    class="clnd"
                    colorPrimary="rgb(234, 67, 55)" // added this
                    colorPrimaryLight="rgba(234, 67, 55, 0.4)"
                    calendarPopperPosition="bottom"
                    dateFormat="dd/MM/yyyy"
                  />
                  {/* <input type="text" name="start" placeholder="YYYY-MM-DD" onChange={e => datef(e)} />
                  <span>To</span>
                  <input type="text" name="end" placeholder="YYYY-MM-DD" onChange={e => datef(e)} /> */}
                  <button id="search-button" type="button" class="btn btn-primary mr-5" onClick={e => selectedDayRange.to && selectedDayRange.from ? costumfl() : toast.error("select date")} disabled={cstmbtn}>Go</button>
                </div>
                <div className="card">
                  <div className="record">
                    <span>Total Balance</span>
                    <span>{costumpoint.buy || costumpoint.redeemed || costumpoint.refund ? token : 0}</span>
                  </div>
                  <div className="record">
                    <span>Total Bought</span>
                    <span>{costumpoint.buy ? costumpoint.buy : 0}</span>
                  </div>
                  <div className="record">
                    <span>Total Redeemed</span>
                    <span>{costumpoint.redeemed ? costumpoint.redeemed : 0}</span>
                  </div>
                  <div className="record">
                    <span>Total Refunded</span>
                    <span>{costumpoint.refund ? costumpoint.refund : 0}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="responsive" style={{ display: display3 }}>
          <div className="contentBorder admner" style={{ border: "none", marginBottom: "0" }}>
            <table className="table">
              <tr>
                <td>Total Balance</td>
                <td>{monthpoint ? token : 0}</td>
              </tr>
              <tr>
                <td>Total Bought</td>
                <td>{monthpoint.buy ? monthpoint.buy : 0}</td>
              </tr>
              <tr>
                <td>Total Redeemed</td>
                <td>{monthpoint.redeemed ? monthpoint.redeemed : 0}</td>
              </tr>
              <tr>
                <td>Total Refunded</td>
                <td>{monthpoint.refund ? monthpoint.refund : 0}</td>
              </tr>
              <tr>
                <td>Add Points</td>
                <td><input name="refund" className='sma' placeholder="Refund" value={point} onChange={e => {
                  setpoint(e.target.value)
                  setpoint2('')
                }} /></td>
              </tr>
              <tr>
                <td>Redeemed Points</td>
                <td><input name="refund" className='sma' placeholder="Redeem" value={point2} onChange={e => {
                  setpoint2(e.target.value)
                  setpoint('')
                }} /></td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div className="bottom-status">
        <div className="row mt-3">
          <div className="col-sm-2">
            <button className="button admin-status-btn" onClick={(e) => point2 ? redeem() : point ? refund() : toast.error("Please Select and fill ")}>Save</button>
          </div>
          <div className="col-sm-2">
            {/* <button className="button admin-status-btn">Edit</button> */}
          </div>
          <div className="col-sm-2">
            {/* <button className="button admin-status-btn">Cancel</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
