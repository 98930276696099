import React from 'react'
import Footer from './Footer'
import Header from '../homepage/Header'
import ReactStars from "react-rating-stars-component";
import service5 from '../assets/homepage/images.png';
import PhotoAndVideo from "./PhotoAndVideo";
import { Modal, Container, Row } from 'react-bootstrap';
import { colors } from '@material-ui/core';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import photography from '../assets/homepage/photography.jpg';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { useRef } from 'react';
import Signup from '../components/Signup'
import { Link } from 'react-router-dom';
import Showreview from '../review/Showreview';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { get } from 'jquery';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 133,
  },
}));

const emailRegex = RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);
const mobile_numberRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
const Review = () => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [count, setcount] = useState(false);
  const [count2, setcount2] = useState(false);
  const [tableDatafix, setTableDatafix] = useState([]);
  const [questionall, setquestionall] = useState([])
  const Reviews = localStorage.getItem('review');
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [bookdate, setbookdate] = useState([])
  const [bookdatedisable, setbookdatedisable] = useState([])
  const [questions, setquestions] = useState([])
  const [totalprice, settotalprice] = useState('')
  const [selectquestion, setselectquestion] = useState([])
  const [bookselectdate, setbookselectdate] = useState([])
  const [aveableboking, setaveableboking] = useState([])
  const [current, setcurrent] = useState(1)
  const [showPopup, setPopup] = useState(false);
  const [showPopup2, setPopup2] = useState(false);
  const [pending, setpending] = useState("ioni")
  const [bookingdata, setbookingdata] = useState({
    bookingDate: '',
    professionalId: '',
    userId: '',
    subCategory: '',
    totalAmount: '',
    tokenPrice: '',
    startTime: '',
    endTime: '',
    selectPrice: [],
    uploadImage: [],
    addNote: ''
  })
  const [from, setfrom] = useState()
  const [to, setto] = useState()
  const [isOpen, setIsOpen] = useState(false);
  const [slid, setslid] = useState(1)
  const sliderRef = useRef();
  let subcategory = localStorage.getItem("searchsubcategory_question")
  const [imgname, setimgname] = useState()
  const [checkfield, setcheckfield] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    postal_code: "",
  })
  const [vl, setvl] = React.useState(0)
  const [formErrors, setformErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    postal_code: "",
  })
  let userlogin = localStorage.getItem("userid")
  const [loginty, setloginty] = useState(userlogin)
  const [fulltearday, setfulltearday] = useState([])
  const [subcat, setsubcat] = useState(0);
  const [monthsyear, setmonthsyear] = useState('')
  const [reviewa, setreview] = React.useState([])
  const [similar, setsimilar] = React.useState(false)
  const [completeprs, setcompleteprs] = React.useState([])
  const [datecalander, setdatecalander] = React.useState()
  const [readmore, setreadmore] = React.useState(150)
  const [mob, setmob] = React.useState('')


  const handleCloseSignupForm = () => {
    setPopup(false)
    window.location.replace("/orders");
  };
  const handleCloseSignupForm2 = () => {
    setPopup2(false)
    window.location.reload();
  };
  const getDaysInMonth = (month, year) => {
    var date = new Date(year, month, 1);
    while (date.getMonth() === month) {
      var month = date.getMonth()
      var day = date.getDate()
      let sum = false
      bookdatedisable.map((z) => {
        if (z.month == month) {
          if (z.day == day) {
            sum = true

          }
        }
      })
      if (sum == false) {
        fulltearday.push({ "month": month, "day": day })
      }
      date.setDate(date.getDate() + 1);
    }
    if (monthsyear < 12) {
      let m = monthsyear + 1
      setmonthsyear(m)
      setTimeout(function () { setcount2(false) }, 1000);
    }
  }

  const loginformdata = (e, b) => {
    if (e == "email") {
      let checkemail = emailRegex.test(b) ? '' : "invalid email address";
      checkfield.email = checkemail
      if (checkemail == "") {
        let data = { ...formErrors, [e]: b }
        setformErrors(data)
      } else {
        let data = { ...formErrors, [e]: "" }
        setformErrors(data)
      }
    } else if (e === "mobile_number") {
      let checkemail = mobile_numberRegex.test(b) ? '' : "invalid Mobile number";
      checkfield.mobile_number = checkemail
      if (b.length <= 10) {
        setmob(b)
        if (checkemail == "") {
          let data = { ...formErrors, [e]: b }
          setformErrors(data)
        } else {
          let data = { ...formErrors, [e]: "" }
          setformErrors(data)
        }
      }
    }
    else {
      let data = { ...formErrors, [e]: b }
      setformErrors(data)
    }
  }

  const loginguest = () => {
    if (formErrors.first_name == "" || formErrors.email == "" || formErrors.last_name == "" || formErrors.mobile_number == "" || formErrors.postal_code == "") {
      toast.error("All fields are Mandatory");
    }
    else {
      // document.getElementById("loginbtndisable").disabled
      let bookingdata = {
        "first_name": formErrors.first_name,
        "last_name": formErrors.last_name,
        "email": formErrors.email,
        "address": "jjkbkjb",
        "postal_code": formErrors.postal_code,
        "mobile_number": formErrors.mobile_number
      }
      fetch(`${API}/user/isGuestUser`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(bookingdata)
      })
        .then(res => res.json().then(result => {
          if (result.token) {
            toast.success("Login Successful continue booking")
            localStorage.setItem("userid", result.result._id)
            setloginty(result.result._id)
            setTimeout(() => {
              handleDateChange(datecalander)
            }, 1000);
            document.getElementById("time_on").style.display = "block"
            document.getElementById("guestlogin").style.display = "none"
          } else {
            toast.error("this email already login ")
          }

        }))
        .catch(err => toast.error("Ooops!! Something went wrong."));
    }
  }

  useEffect(() => {
    var dt = new Date();
    if (!count2) {
      if (bookdatedisable) {
        setcount2(true)
        getDaysInMonth(monthsyear, dt.getFullYear())
      }
      tableData.map((e) => {
        return (setslid(e.profileImages.length <= 3 ? e.profileImages.length : 4))
      })
    }
  }, [count2, monthsyear, bookdatedisable, subcat, pending])


  useEffect(() => {
    let dayexit = new Date()
    let monthexit = dayexit.getMonth()
    setmonthsyear(monthexit)
    async function providerDetails() {
      if (!count) {

        fetch(`${API}/user/getProfessionalOrder/` + Reviews,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
          .then(response => response.json().then(data => {
            if (response.status == 200) {
              data.data.map((e) => {
                if (e.uploadDocument.length > 0) {
                  completeprs.push(e)
                }
              })
            }
          })
          )
          .catch(err => toast.error("Ooops!! Something went wrong."));
        review()
        document.getElementById("loder").style.display = "block"
        fetch(`${API}/admin/getAllProfessional`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
          .then(response => response.json().then(data => {
            if (response.status == 200) {
              data.result.map((cat) => {
                if (cat._id == Reviews) {
                  setcount(true)
                  tableData.push(cat)
                  let filt = cat.pricesQues.map((e) => {
                    if (e.subcategory == subcategory) {
                      questions.push(e)
                    }
                  })
                  document.getElementById("loder").style.display = "none"
                }
              })
              setTableDatafix(data)
            } else {
              toast.error("Ooops!! Failed to fetch data.");
            }
          })
          )
          .catch(err => toast.error("Ooops!! Something went wrong."));

        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        fetch(`${API}/user/userAvailibility/` + Reviews, requestOptions)
          .then(response => response.json())
          .then(result => {
            result.map((date) => {
              var month = (new Date(date.date).getMonth() + 1)
              var day = new Date(date.date).getDate() + 1
              bookdatedisable.push({ "month": (month - 1), "day": day })
            })
          })
          .catch((err) => toast.error("Ooops!! Something went wrong. user"));

        // category point
        fetch(`${API}/admin/getAllCategory`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) =>
            response.json().then((data) => {
              if (response.status == 200) {
                data.map((e) => {
                  e.subcategory.map((x) => {
                    if (x.value.toLowerCase() == subcategory.toLowerCase()) {
                      setsubcat(x.tokenPrice)
                    }
                  })
                })
              } else {
                toast.error("Ooops!! Failed to fetch data.");
              }
            })
          )
          .catch((err) => toast.error("Ooops!! Something went wrong."));

        // 
        fetch(`${API}/professional/getProfessinalPrice?professinalId=` + Reviews,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
          .then(response => response.json().then(data => {
            if (response.status == 200) {
              data.ProfessinalPrice.map((e) => {
                if (e.subcategory == subcategory) {
                  questionall.push(e)
                }
              })
            }
          })
          )
          .catch(err => toast.error("Ooops!! Something went wrong."));
        setcount(true)
      }
    }
    providerDetails();
  }, [tableData, tableDatafix, bookdate, questions, current, isOpen, checkfield, subcat]);


  const bookingapi = () => {
    let ind = 0
    setpending("")
    var formdata = new FormData();
    bookingdata.uploadImage.map((e, i) => {
      ind = i + 1
      return formdata.append("uploadImage" + i, e)
    })

    formdata.append("userId", bookingdata.userId);
    formdata.append("professionalId", bookingdata.professionalId);
    formdata.append("addNote", bookingdata.addNote);
    formdata.append("bookingDate", bookingdata.bookingDate);
    formdata.append("totalAmount", bookingdata.totalAmount);
    formdata.append("startTime", bookingdata.startTime);
    formdata.append("endTime", bookingdata.endTime);
    formdata.append("selectPrice", JSON.stringify(bookingdata.selectPrice));
    // formdata.append("uploadImage", bookingdata.uploadImage);
    formdata.append("subCategory", bookingdata.subCategory);
    formdata.append("tokenPrice", bookingdata.tokenPrice);
    formdata.append("similar", similar)
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    if (ind == bookingdata.uploadImage.length) {

      fetch(`${API}/user/bookProfessional`, requestOptions)
        .then(response => response.json()
          .then(result => {
            if (response.status == 200) {

              setPopup(true)
              setpending("hbi")
              let x = sessionStorage.getItem("token")
              if (x != null) {
              } else {
                localStorage.removeItem("userid");
              }
            } else {
              toast.error("booking not successful")
            }
          }))
        .catch(err => toast.error("Ooops!! Something went wrong."));

    }

  }

  const getdisabledate = (dates) => {

  }

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: parseInt(slid),
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  var settings2 = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings2: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      }
    ]
  };





  const providerTable2 = ((x, index) => {

    var check = x.substr(x.lastIndexOf('\\') + 1).split('.')[1]
    if (check == "mp4") {
      return (
        <div class="slide-items zoom">
          <div className="item" >
            <a data-fancybox=" gallery" href={`${API}/files/${x}`}>
              <video class="image_Preview slider-3 img-fluid radius-image-full" width="240px" height="172px" style={{ objectFit: "fill" }} controls>
                <source src={`${API}/files/${x}`} type="video/mp4"></source>
              </video>
            </a>
          </div>
        </div>
      )
    } else {
      return (
        <div class="slide-items zoom">
          <div className="item">
            <a data-fancybox=" gallery" href={`${API}/files/${x}`}>
              <img className="slider-3 img-fluid radius-image-full" style={{ width: "240px", height: "172px", objectFit: "fill" }} src={`${API}/files/${x}`} alt="" />
            </a>

          </div>
        </div>
      )
    }

  })

  const providerTable3 = ((x, index) => {

    var check = x.substr(x.lastIndexOf('\\') + 1).split('.')[1]
    if (check == "mp4") {
      return (
        <div class="col-sm-3 col-12 zoom">
          <a data-fancybox=" gallery" href={`${API}/files/${x}`}>
            <video class="image_Preview slider-3 img-fluid radius-image-full" width="240px" height="172px" style={{ objectFit: "cover" }} controls>
              <source src={`${API}/files/${x}`} type="video/mp4"></source>
            </video>
          </a>
        </div>
      )
    } else {
      return (
        <div class="col-sm-3 col-12 zoom">
          <a data-fancybox=" gallery" href={`${API}/files/${x}`}>
            <img className="slider-3 img-fluid radius-image-full" style={{ width: "240px", height: "172px", objectFit: "cover" }} src={`${API}/files/${x}`} alt="" />
          </a>
        </div>
      )
    }

  })

  const handleDateChange = (date) => {
    setIsOpen(false)
    setdatecalander(date)
    userlogin = localStorage.getItem("userid")
    if (userlogin == null) {
      document.getElementById("guestloginselect").style.display = "block"
    } else {
      let newdate = new Date(date).getDate() + '/' + (new Date(date).getMonth() + 1) + '/' + new Date(date).getFullYear()
      let newdate2 = new Date(date).getFullYear() + '/' + (new Date(date).getMonth() + 1) + '/' + new Date(date).getDate()
      booking(newdate2, "bookingDate")

      //all  booking time 
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      fetch(`${API}/user/getDateWiseBooking?professionalId=` + Reviews + `&bookingDate=` + newdate2, requestOptions)
        .then(response => response.json())
        .then(result => {
          setbookselectdate(result.data)
        })
        .catch(err => toast.error("Ooops!! Something went wrong."));

      // professional service time
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      fetch(`${API}/professional/dateWiseData?userId=` + Reviews + `&date=` + newdate2, requestOptions)
        .then(response => response.json())
        .then(result => {
          setaveableboking(result.data)
        })
        .catch(err => toast.error("Ooops!! Something went wrong."));



      let questionpop = document.getElementById("time_on").style.display = "block"
    }
  };
  const noneqsn = (e) => {
    let questionpop = document.getElementById(e).style.display = "none"
  }
  const slideropen = () => {
    if (bookingdata.startTime == "" || bookingdata.endTime == "") {
      toast.error("Please select time");
    } else {
      let questionpop = document.getElementById("questions").style.display = "block"
      let questionpop2 = document.getElementById("time_on").style.display = "none"
    }
  }
  const notepad = () => {
    if (selectquestion == "") {
      questionall.map((data, index) => {
        data.answer.map((data2, index2) => {
          if (index == 0 && index2 == 0) {
            let selectPrice = { "question": data.question, "ansawer": (data2.option + '-' + data2.answer) }
            selectquestion.push(selectPrice)
            booking(selectquestion, "selectPrice")

            totalpriceadd()
          }
        })
      })

    }
    totalpriceadd()
    console.log(selectquestion)
    let questionpop = document.getElementById("notpad").style.display = "block"
    let questionpop2 = document.getElementById("questions").style.display = "none"
  }
  const timeontab = (prvu, crnt) => {
    let questionpop = document.getElementById(prvu).style.display = "block"
    let questionpop2 = document.getElementById(crnt).style.display = "none"
  }
  const successtab = () => {
    let questionpop = document.getElementById("successtab").style.display = "block"
    let questionpop2 = document.getElementById("notpad").style.display = "none"
  }
  const similer = () => {
    let questionpop = document.getElementById("similer").style.display = "block"
    let questionpop2 = document.getElementById("successtab").style.display = "none"
  }
  const providerTable = ((x, index) => {
    return (
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-9">
            <div className="user_image">
              <img className="review-image img-fluid" src={x.professionalImage == undefined ? service5 : `${API}/files/${x.professionalImage}`} />
              <h4 className="reviewer-name"><strong>{x.first_name + ' ' + x.middle_name + ' ' + x.last_name}</strong>
                <span className="review">
                  <p>{completeprs.length} similar jobs done near you</p>
                </span>
                <div className="ave rating cursoin_none" style={{ float: "none" }}>
                  <Showreview star={x.getAvgRating} />
                </div>
              </h4>
            </div>
            <p style={{ fontSize: "19px", fontWeight: "700", marginTop: "15px", marginBottom: "0px" }}>Introduction</p>
            <div className="dasktopintro">
              <p dangerouslySetInnerHTML={{ __html: x.intro }} className="introall"></p>
            </div>
            <div className="mobileintro">
              {
                x.intro ? x.intro.length <= 150 ? <p dangerouslySetInnerHTML={{ __html: x.intro.slice(0, readmore) }} className="introall"></p> :
                  <p><span dangerouslySetInnerHTML={{ __html: x.intro.slice(0, readmore) + "..." }} className="introall"></span><span className='buttonrm'>{readmore <= 150 ? <button className='viewMore viewbtn' onClick={e => setreadmore(100000)} >Read More</button> : <button className='viewMore viewbtn' onClick={e => setreadmore(150)} >Read Less</button>}</span></p> : ""
              }
            </div>
          </div>
          <div className="col-md-3">
            <div className="estimate">
              <h3>{x.getAvgPrice >= 0 ? x.getAvgPrice + "$" : ''}</h3>
              <h5>Estimated Cost</h5>
              <hr></hr>
              <button id="joinas" type="button" class="btn btn-primary">Book Now</button>
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <Grid container justify="space-around" className="calender">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    shouldDisableDate={disableWeekends}
                    KeyboardButtonProps={{
                      onFocus: e => {
                        setIsOpen(true);
                      }
                    }}
                    disablePast={true}
                    PopoverProps={{
                      disableRestoreFocus: true,
                      onClose: () => {
                        setIsOpen(false);
                      }
                    }}
                    InputProps={{
                      onFocus: () => {
                        setIsOpen(true);
                      }
                    }}
                    open={isOpen}

                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="col-sm-12 slidervideo">
            <h1 className="secheading mt-5 mb-3"><span className="heading-1">Photos/Videos</span></h1>
            {/* <PhotoAndVideo images="x.profileImages" /> */}

            {
              x.profileImages.length < 4 ?
                <div className="row distroy" style={{ textAlign: "center" }}>
                  {x.profileImages.map(providerTable3)}
                </div>
                :
                <Slider {...settings} id="Slider-3">
                  {x.profileImages.map(providerTable2)}
                </Slider>
            }


          </div>
        </div>
        <div className="col-sm-12 mb-5">
          <h3 className="mt-5 mb-4"><span className="heading-1">REVIEWS</span></h3>
          <div className=" row-width  reviews row">
            <div className=" col-xs-12  col-sm-12 col-md-12">
              {reviewa.map(ratingrv)}
            </div>
          </div>
        </div> <div class="col-12 view mt-3">
          {
            vl < 100 ? <Link onClick={e => setvl(100)}	>View All</Link> : <Link onClick={e => setvl(0)}	>View Less</Link>
          }

        </div>
      </div>
    )
  })

  const ratingrv = (e, index) => {
    if (index <= vl) {
      return (
        <>
          <section className="pb-2 pt-2 imagessliderprofessional">
            <div className="" id="zoomid">
              <div className="user_image">
                <img className="review-image img-fluid" src={service5} />
                <h4 className="reviewer-name">{e.userId.first_name + " " + e.userId.last_name} <p style={{ marginBottom: "0", float: "right" }}><span class="ml-5">{(e.createdAt.substr(e.createdAt.lastIndexOf('\\') + 1).split('T')[0])}</span></p>
                  <p ><span class="star cursoin_none" ><Showreview star={e.rating} /></span> <span class="ml-2">({e.rating}/5)</span> </p>
                  <span className="review">{e.addUserReview} </span>
                </h4>
              </div>
            </div>
          </section>
        </>
      )
    }
  }




  const disableWeekends = (date) => {
    const dateInterditesRaw =
      fulltearday.map((x) => {
        return (
          new Date(date.getFullYear(), x.month, x.day)
        )
      })
      ;
    const dateInterdites = dateInterditesRaw.map((arrVal) => {
      return arrVal.getTime()
    });
    return dateInterdites.includes(date.getTime());
  }

  const totalpriceadd = (e) => {
    let dat = []
    let tot = 0
    selectquestion.map((y) => {
      let ac = y.ansawer.substr(y.ansawer.lastIndexOf('\\') + 1).split('#')[1]
      tot = tot + parseInt(ac)
      console.log(ac, tot, y)
    })
    let data = { ...bookingdata, "totalAmount": tot, "selectPrice": selectquestion }
    setbookingdata(data)
    console.log(bookingdata);
  }

  const review = () => {
    fetch(`${API}/professional/getProfessinalWiseReview?professionalId=` + Reviews, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    })
      .then(response => response.json().then(data => {
        if (data.status == 200) {
          setreview(data.data)
        } else {
          toast.error(data.message)
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }

  const checkedexit = (qsn, e, optn) => {
    let check = e.target.checked;
    let sum = false
    if (check == true) {
      selectquestion.map((ext) => {
        let ch = ext.ansawer.substr(ext.ansawer.lastIndexOf('\\') + 1).split('#')[0]
        if (optn == ch) {
          sum = true
          ext.ansawer = (optn + '#' + e.target.value)
        }
      })
      if (sum == false) {
        let selectPrice = { "question": qsn, "ansawer": (optn + '#' + e.target.value) }
        selectquestion.push(selectPrice)
      }
      booking(selectquestion, "selectPrice")

    }
    else {
      selectquestion.map((ext, index) => {
        let ch = ext.ansawer.substr(ext.ansawer.lastIndexOf('\\') + 1).split('#')[0]
        if (optn == ch) {
          selectquestion.splice(index, 1);
        }
      })
      booking(selectquestion, "selectPrice")
    }
  }

  const question = ((y, index) => {
    if (0 == 0) {
      return (
        <div class="slide-items">
          <div className="item">
            <div class=" Slider-4">
              {/* shadow
            questionpopup */}
              <div class="">
                <p> {y.question} </p>
                <form>
                  {
                    y.answer.map((z, index2) => {
                      return (
                        <div class="option">

                          <input type="checkbox" style={{ width: "auto" }} name="option" id={index + '-' + index2} onChange={(e) => checkedexit(y.question, e, z.option)} value={z.answer} />

                          <label for={index + '-' + index2}>{z.option} </label>
                        </div>
                      )
                    })
                  }
                </form>
              </div>

              <div class="buttons" >
                {index == 0 ? <button class="buttonn preview" onClick={e => timeontab("time_on", "questions")}> Previous</button> :
                  <button class="buttonn preview" onClick={e => gotoprvu()}> Previous</button>
                }
                {questionall.length == (index + 1) ?
                  <button class="buttonn next upsbtnide" onClick={e => notepad()}> Next</button> :
                  <button class="buttonn next" onClick={e => gotoNext()}> Next</button>
                }
              </div>
              {/* <div class="buttons">
                  <button class="buttonn preview" onClick={e => gotoprvu()}> Previous</button>
                  <button class="buttonn next" onClick={e => gotoNext()}> Next</button>
                </div> */}
            </div>
          </div>
        </div>
      )
    }
  })

  const timecheck = (e, name) => {
    if (from && to) {
      var check = from.substr(from.lastIndexOf('\\') + 1).split(':')
      var check2 = to.substr(to.lastIndexOf('\\') + 1).split(':')
      aveableboking.map(a => {
        if (parseInt(a.startTime.substr(a.startTime.lastIndexOf('\\') + 1).split(':')[0]) <= parseInt(check[0]) && parseInt(a.endTime.substr(a.endTime.lastIndexOf('\\') + 1).split(':')[0]) >= parseInt(check2[0])) {
          slideropen()
        } else {
          toast.error("This time professional not available")
        }
      })
    } else {
      toast.error("Please Time Select")
    }

  }

  const booking = (e, name) => {
    if ("startTime" == name || "endTime" == name) {
      var check = e.substr(e.lastIndexOf('\\') + 1).split(':')
      if (check[0] > 12) {
        let t = (check[0] - 12) + ":" + check[1] + " PM"
        let data = { ...bookingdata, [name]: t, "professionalId": Reviews, "userId": loginty, "subCategory": subcategory, "tokenPrice": subcat }
        setbookingdata(data)
      } else {
        let data = { ...bookingdata, [name]: e + " AM", "professionalId": Reviews, "userId": loginty, "subCategory": subcategory, "tokenPrice": subcat }
        setbookingdata(data)
      }
    } else {
      let data = { ...bookingdata, [name]: e, "professionalId": Reviews, "userId": loginty, "subCategory": subcategory, "tokenPrice": subcat }
      setbookingdata(data)
    }
    console.log(bookingdata);
  }

  const imagesupload = (e) => {
    setimgname(e.target.files[0].name)
    if (bookingdata.uploadImage) {
      bookingdata.uploadImage.push(e.target.files[0])
    }
    else {
      let data = { ...bookingdata, "uploadImage": [e.target.files[0]] }
      setbookingdata(data)
    }
    // setimgname(e.target.files[0])
  }


  const gotoNext = () => {
    sliderRef.current.slickNext();
  }
  const gotoprvu = () => {
    sliderRef.current.slickPrev();
  }
  return (
    <div class="costumetsf">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div class="loder" id="loder">
        <div class="wrap">
          <div class="loading">
            <div class="bounceball"></div>
            <div class="text">NOW LOADING</div>
          </div>
        </div>
      </div>
      <Header />

      {tableData.map(providerTable)}



      <div class="shadow" id="guestloginselect">
        <div className=" questionpopup">
          <button onClick={e => noneqsn("guestloginselect")}><svg class="MuiSvgIcon-root makeStyles-actioncolor-30" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button>
          <div class="withoutlogin">
            <p>You are not logged in. Please select  login type and continue booking.</p>
            <div class="buttons" >
              <Link > <button class="buttonn preview design" ><Signup /> </button></Link>
              <button class="buttonn next" style={{ padding: "4px 0" }} onClick={e => {
                document.getElementById("guestlogin").style.display = "block"
                document.getElementById("guestloginselect").style.display = "none"
              }}>Guest checkout</button>
            </div>
          </div>
        </div>
      </div>



      <div class="shadow" id="guestlogin">
        <div className="datepopup">
          <button onClick={e => noneqsn("guestlogin")}><svg class="MuiSvgIcon-root makeStyles-actioncolor-30" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button>
          <div className="signUp-form2">
            <h3 className="titleSignUp">Quick Login</h3>
            <form>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group first_name">
                    <label>First name</label>
                    <input
                      type="text"
                      className=""

                      name="first_name"
                      required
                      onChange={e => loginformdata("first_name", e.target.value)}
                    />
                  </div>
                </div>
                <div className=" col-sm-6">
                  <div className="form-group last_name">
                    <label>Last name</label>
                    <input
                      type="text"
                      className=""
                      name="last_name"
                      required
                      onChange={e => loginformdata("last_name", e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="email">
                <label>Email Address</label>
                <input
                  type="email"
                  className={checkfield.email.length > 0 ? "error" : ""}
                  name="email"
                  onChange={e => loginformdata("email", e.target.value)}
                  required
                />
                {checkfield.email.length > 0 && (
                  <span className="errorMessage">{checkfield.email}</span>
                )}
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="phone">
                    <label>Mobile Number</label>
                    <input
                      type="number"
                      id="phone" name="phone"
                      className={checkfield.mobile_number.length > 0 ? "error" : ""}
                      onChange={e => loginformdata("mobile_number", e.target.value)}
                      name="mobile_number"
                      required
                      placeholder="000-000-0000"
                      value={mob}
                    />
                    {checkfield.mobile_number.length > 0 && (
                      <span className="errorMessage">{checkfield.email}</span>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="postal_code">
                    <label>Postal Code</label>
                    <input
                      type="text"
                      className=""
                      name="postal_code"
                      onChange={e => loginformdata("postal_code", e.target.value)}
                      required
                      maxlength="6"
                    />
                  </div>
                </div>
              </div>

            </form>
            <div className="submitForm">
              <button className="btn signUpBtn" id="loginbtndisable" onClick={e => loginguest()}>Submit</button>
            </div>
          </div>
        </div>
      </div>





      <div class="shadow" id="time_on">
        <div className="datepopup">
          <button onClick={e => noneqsn("time_on")}><svg class="MuiSvgIcon-root makeStyles-actioncolor-30" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button>
          <h2>Service Time</h2>
          <div className="booktime" style={{ marginBottom: 0 }}>
            {
              aveableboking.map((time, index) => {
                if (index == 0) {
                  return (
                    <p><span><strong>Provider Available </strong></span><span>Start Time {(time.startTime.substr(time.startTime.lastIndexOf('\\') + 1).split(':')[0]) > 12 ? ((time.startTime.substr(time.startTime.lastIndexOf('\\') + 1).split(':')[0] - 12) + ":" + time.startTime.substr(time.startTime.lastIndexOf('\\') + 1).split(':')[1]) + " PM" : time.startTime + " AM"}</span> <span>End Time {(time.endTime.substr(time.endTime.lastIndexOf('\\') + 1).split(':')[0]) > 12 ? ((time.endTime.substr(time.endTime.lastIndexOf('\\') + 1).split(':')[0] - 12) + ":" + time.endTime.substr(time.endTime.lastIndexOf('\\') + 1).split(':')[1]) + " PM" : time.endTime + " AM"}</span></p>
                  )
                }
              })
            }
          </div>
          <form className={classes.container} noValidate>
            <span>Select Your Time</span>
            <TextField
              id="time"
              type="time"
              defaultValue={bookingdata.startTime}
              className={classes.textField}
              onChange={(x) => {
                booking(x.target.value, "startTime")
                setfrom(x.target.value)
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            /><span>To</span>
            <TextField
              id="time"
              type="time"
              defaultValue={bookingdata.endTime}
              className={classes.textField}
              onChange={x => {
                booking(x.target.value, "endTime")
                setto(x.target.value)
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </form>
          <div class="buttons" >
            <button class="buttonn preview" disabled> Previous</button>
            <button class="buttonn next" onClick={e => slideropen()}> Next</button>
          </div>
        </div>
      </div>
      <div class="shadow" id="questions" >
        <div class="questionpopup">
          <button onClick={e => noneqsn("questions")}><svg class="MuiSvgIcon-root makeStyles-actioncolor-30" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button>
          <Slider ref={sliderRef} {...settings2} id="Slider-4">
            {questionall.length > 0 ? questionall.map(question) : ''}
          </Slider>
        </div>
      </div>
      <div className="shadow" id="notpad">
        <div class="notepad">
          <button onClick={e => noneqsn("notpad")}><svg class="MuiSvgIcon-root makeStyles-actioncolor-30" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button>
          <div class="first">
            <h2>Add Note</h2>
            <textarea id="w3review" name="w3review" rows="3" placeholder="Type Here" onChange={e => booking(e.target.value, "addNote")} />
          </div>
          <div class="second" >
            <h2>Upload Photos</h2>
            <div class="header2" style={{ overflow: "hidden" }}>
              <div class="col-sm-12">
                <div class="position">
                  {
                    bookingdata.uploadImage && bookingdata.uploadImage.map((e, i) => {
                      return <p style={{ textAlign: "left", marginTop: "5px", fontSize: "13px" }}>{i + 1 + " )" + '   ' + e.name}</p>
                    })
                  }
                  <input type="file" name="" onChange={e => imagesupload(e)} />
                  <div class="">
                    {/* {imgname ? <img src={imgname} /> : ''} */}
                    <i class="fa fa-upload" aria-hidden="true" style={imgname ? { color: "green" } : {}}></i><p>{imgname ? <strong style={{ color: "green" }}>{imgname}</strong> : "Drop Photo Here"}</p>
                    <span>OR</span>
                    <button class="chose"> Choose File</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <button class="buttonn preview" onClick={e => timeontab("questions", "notpad")}> Previous</button>
            <button class="buttonn next" onClick={e => successtab()}> Next</button>
          </div>
        </div>

      </div>

      <div className="shadow" id="successtab">
        <div class="questionpopup successtab">
          <button onClick={e => noneqsn("successtab")}><svg class="MuiSvgIcon-root makeStyles-actioncolor-30" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button>
          <button onClick={e => noneqsn("successtab")}><svg class="MuiSvgIcon-root makeStyles-actioncolor-30" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button>
          <p>Service professional will contact you in 24-48 hours. Do you wish to continue?</p>
          <form>
            <div class="option">
              <input type="radio" style={{ width: "auto" }} name="option" id={"o1"} />
              <label for={"o1"}>Yes </label>
            </div>
            <div class="option">
              <input type="radio" style={{ width: "auto" }} name="option" id={"o2"} onClick={e => {
                setPopup2(true)
              }} />
              <label for={"o2"} >No </label>
            </div>
          </form>
          <div type="button" id="cancelPost" class="btn btn-primary mt-5">{bookingdata?.totalAmount} $ Estimated Cost</div>
          <div class="buttons">
            <button class="buttonn preview mt-3" onClick={e => timeontab("notpad", "successtab")}> Previous</button>
            <button class="buttonn next mt-3" onClick={e => similer()}> Next</button>
          </div>
        </div>
      </div>

      <div className="shadow" id="similer">
        <div class="questionpopup successtab">
          <button onClick={e => noneqsn("similer")}><svg class="MuiSvgIcon-root makeStyles-actioncolor-30" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button>
          <p>Do you wish to be contacted by similar professionals for the project? </p>
          <form>
            <div class="option">
              <input type="radio" style={{ width: "auto" }} name="option" id={"o3"} onClick={e => setsimilar(true)} />
              <label for={"o3"}>Yes </label>
            </div>
            <div class="option">
              <input type="radio" style={{ width: "auto" }} name="option" id={"o4"} onClick={e => setsimilar(false)} />
              <label for={"o4"}>No </label>
            </div>
          </form>
          <div class="buttons">
            <button class="buttonn preview" onClick={e => timeontab("successtab", "similer")}> Previous</button>
            {
              pending ? <button class="buttonn next" id="ordersb" onClick={e => bookingapi()}> Submit</button> : <button class="buttonn next" id="ordersb" > Processing..</button>
            }
          </div>
        </div>
      </div>
      <Modal show={showPopup} id="questionForm"
        onHide={handleCloseSignupForm} size="md" aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="successfulbook">
              Your order request has been placed.
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <Modal show={showPopup2} id="questionForm"
        onHide={handleCloseSignupForm2} size="md" aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="successfulbook">
              Please contact us at 2048876406 or email us at support@theom.ca to discuss your project details. Thank you!
            </Row>
          </Container>
        </Modal.Body>
      </Modal>



      <Footer />
    </div>
  )
}
export default Review;

