import React from 'react'
import Footer from './Footer'
import Header from '../homepage/Header'
import "../Style.css";
import careerBanner from '../assets/Career Page/career-banner.jpg';
const Aboutus = () => {

	return (

		<div ref={window.scrollTo(0, 0)} className="privacyPolicies">
			<Header />
			<div className="">
				<div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
					<img className="Banner" src={careerBanner} alt="Responsive image" />
					<div className="bannerText">
						<h1 className="secheading mt-5 mb-5"><span className="bannerHeading" style={{ textTransform: "uppercase" }}>About Us</span></h1>
					</div>
				</div>
			</div>
			<div className="privacyBody">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<h2 className="heading mb-4" style={{ fontWeight: "500" }} >
								Our mission
							</h2>
							<p className="home-content">
								“A Canadian company, envisioned on quality, value and commitment, serving to solve our consumer's daily needs by connecting them with certified service providers for every project.”
							</p>
							<h2 className="heading mb-4" style={{ fontWeight: "500" }} >
								More about Theom!!
							</h2>
							<p className="home-content">
								We have always asked a simple question to ourselves, wouldn’t it be easy if we could have a same platform to review and hire a service professional
							</p>
							<p className="home-content">
								Finding the right service provider for your dream project has always been a hustle for most of us, be it a hair stylist, cleaner or any professional! With an envision to simplifying the process of choosing and hiring service providers, we have launched a platform where all service providers are listed with verified credentials to give you the quality work you deserve.
							</p>
							<p className="home-content mt-5	mb-4">Our Team </p>
							<p className="home-content mt-4 mb-5">When you put your trust In us, we make miracles happen. </p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}
export default Aboutus