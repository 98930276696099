import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { CheckoutForm } from '../stripe/Checkout';

const stripePromise = loadStripe('pk_test_51JO1iSSDo0gJpYTejY7QVhbtnhnTGzRQaCZSA5Pzxj9RaV6FsYEu67H0E71cuBCw2R6TIEWLknAHyUH6QiuJqyrH00TlxEIUXM');

export default function Payment(props) {
  const [points, setpoints4] = useState(props.points)
  useEffect(() => {
  }, [])
  return (
    <>
      <div className="popup row col-sm-12">
        <div className="col-sm-3 paddCss">
          <label><strong>Net Purchase</strong></label>
        </div>

        <div className="col-sm-2 paddCss">
          <label> {points.Amount ? "$" + points.Amount : 0}</label>
        </div>
        <div className="col-sm-2 paddCss">
        </div>
        <div className="col-sm-3 paddCss">
          <label><strong>GST – 5%</strong> </label>
        </div>
        <div className="col-sm-2 paddCss">
          <label>{points.GST ? "$" + points.GST : 0}</label>
        </div>
        <div className="col-sm-3 paddCss">
          <label><strong>Theom Points</strong></label>
        </div>
        <div className="col-sm-2 paddCss">
          <label>{points.Points ? points.Points : 0}</label>
        </div>
        <div className="col-sm-2 paddCss">
        </div>
        <div className="col-sm-3 paddCss">
          <label><strong>PST – 7% </strong></label>
        </div>
        <div className="col-sm-2 paddCss">
          <label>{points.PST ? "$" + points.PST : 0}</label>
        </div>

        <div className="col-sm-3 paddCss">
          <label><strong>Amount to be paid</strong></label>
        </div>
        <div className="col-sm-2 paddCss">
          <label>{points.total ? "$" + points.total : 0}</label>
        </div>

        <div className="col-sm-12 paddCss mt-5">
          <label><strong>Credit Card Details</strong></label>
        </div>
        <div className="col-sm-5 row">
          <div className="col-sm-12 paddCss" style={{ paddingBottom: 0 }}>
            <label><strong>Name on Card</strong></label>
          </div>
          <div className="col-sm-12 paddCss">
            <input type="text" className="form-control" name="" placeholder="Your Name" />
          </div>
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-5 row">
          <div className="col-sm-12 paddCss" style={{ paddingBottom: 0 }}>
            <label><strong>Card Number</strong></label>
          </div>
          <div className="col-sm-12 paddCss">
            <input type="text" className="form-control" name="" placeholder="00000000000" />
          </div>
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-5 row">
          <div className="col-sm-12 paddCss" style={{ paddingBottom: 0 }}>
            <label><strong>Expiry</strong></label>
          </div>
          <div className="col-sm-12 paddCss">
            <input type="text" className="form-control" name="" placeholder="08-2028" />
          </div>
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-5 row">
          <div className="col-sm-12 paddCss" style={{ paddingBottom: 0 }}>
            <label><strong>CVV</strong></label>
          </div>
          <div className="col-sm-12 paddCss">
            <input type="text" className="form-control" name="" placeholder="000" />
          </div>
        </div>
        {/* <button className="button btn1" onClick={e => purchase()}>Place Order</button> */}
      </div>
      <Elements stripe={stripePromise}>
        <CheckoutForm data={points} />
      </Elements>
    </>
  );
};
