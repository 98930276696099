import React, { useState, useEffect } from 'react';
import AfterLoginHeader from '../homepage/Header'
import Footer from '../common/Footer'
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom'

export default function AllOrders() {
  const [component, setComponent] = useState('#EA4337')
  const [color1, setColor1] = useState('#EA4337')
  const [color2, setColor2] = useState('#ccc')
  const [color3, setColor3] = useState('#EA4337')
  const [color4, setColor4] = useState('')
  const [color5, setColor5] = useState('#ccc')
  const [color6, setColor6] = useState('')

  const [display1, setDisplay1] = useState()
  const [display2, setDisplay2] = useState('none')
  const [display3, setDisplay3] = useState('none')
  const [count, setcount] = useState(false);
  const providerData = localStorage.getItem("userid")
  const [orderdata, setorderdata] = useState([])
  const [active, setactive] = useState([])
  const [total, settotal] = useState([])

  useEffect(() => {
    async function providerDetails() {
      if (!count) {
        setcount(true)
        fetch(`${API}/user/getUserOrder/` + providerData,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
          .then(response => response.json().then(data => {
            if (response.status == 200) {
              data.data.map((actv) => {
                actv.professionalId.map((y) => {
                  total.push(y)
                  if (y.status == "Accepted") {
                    active.push(actv)
                  }
                })
              })
              setorderdata(data.data)
            }
          })
          )
          .catch(err => toast.error("Ooops!! Something went wrong."));
      }
    }
    providerDetails()
  }, [])


  const clicked = (data, y) => {
    data.professionalId = [y]
    localStorage.setItem(
      'custordclick', JSON.stringify(data)
    )
  }
  const professional = ((x) => {
    return (
      <option value={x.professionalId.first_name + " " + x.professionalId.last_name} onChange={e => professionalstatus()}>{x.professionalId.first_name + " " + x.professionalId.last_name}</option>
    )
  })
  const professionalstatus = ((x) => {
    return (
      <option value={x.professionalId.first_name + " " + x.professionalId.last_name}>{x.professionalId.first_name + " " + x.professionalId.last_name}</option>
    )
  })
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <AfterLoginHeader />
      <hr className="headerLine"></hr>
      <div className="all-Orders">
        <div className="row mb20" style={{ backgroundColor: "#e8ecec", minHeight: 100 }}>
          <div className="col-md-2"></div>
          <div className="col-sm-4 col-md-3  text-center" style={{ paddingTop: 30 }}>
            <div className="totalOrders">
              <span>Total Orders</span> : <span>{total.length}</span>
            </div>
          </div>
          <div className="col-md-2 "></div>
          <div className="col-sm-4 col-md-3 text-center" style={{ paddingTop: 30 }}>
            <div className="totalOrders">
              <span>Currently Active</span> : <span>{active.length}</span>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
        <div className="container">
          <div className="row-width row mt-1">
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2" style={{ color: color1, boderBottomColor: color1, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid' }}>
              <button className="btn button activeclass" id="button" style={{ color: color3, width: "100%" }} onClick={() => { setColor1('#EA4337'); setColor2('#ccc'); setDisplay1('block'); setDisplay2('none'); setDisplay3('none'); setColor4(''); setColor5('#ccc'); setColor3('#EA4337'); setColor6('') }}>All</button>
            </div>
            {/* <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2" style={{ color: color2, boderBottomColor: color2, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid ' }}>
              <button className="btn button pastclass" style={{ color: color4 }} onClick={() => { setColor1('#ccc'); setColor2('#EA4337'); setDisplay1('none'); setDisplay2('block'); setDisplay3('none'); setColor3(''); setColor5('#ccc'); setColor4('#EA4337'); setColor6('') }}>Current</button>
            </div>
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2" style={{ color: color5, boderBottomColor: color5, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid ' }}>
              <button className="btn button pastclass" style={{ color: color6 }} onClick={() => { setColor1('#ccc'); setColor2('#ccc'); setDisplay1('none'); setDisplay2('none'); setColor3(''); setColor4(''); setColor5('#EA4337'); setColor6('#EA4337'); setDisplay3('block') }}>Previous</button>
            </div> */}
          </div>
          <hr className="activepastline"></hr>
          <div className="table-responsive" style={{ display: display1 }}>
            <table className="table  table-bordered text-center tablefix ordrblock">
              <thead>
                <tr className="table-heading">
                  <th className="table-heading-first" scope="col">Order Number</th>
                  <th scope="col" class="hidetable">Service Name</th>
                  <th scope="col" >Professional Name</th>
                  <th scope="col">Est. Amount</th>
                  <th scope="col"><span>Status</span></th>
                </tr>
              </thead>
              {
                orderdata && orderdata.map((data, index) => {
                  console.log(data)
                  return (
                    <tbody>
                      {
                        data.professionalId.map((y, index2) => {
                          if (data.isUser == true) {
                            return (
                              <tr className="highlight">
                                <th scope="row"><Link to={{ pathname: "/order-summary", state: data }} className="nav-link" onClick={e => clicked(data, y)}>{data.orderID}</Link></th>
                                <td class="hidetable">{data.subCategory}</td>
                                <td >
                                  {y.professionalId.first_name + " " + y.professionalId.last_name}
                                </td>
                                <td>{"$ " + data.totalAmount}</td>
                                <td>{y.status}</td>
                              </tr>
                            )
                          } else {
                            return (
                              <tr>
                                <th scope="row"><Link to={{ pathname: "/order-summary", state: data }} className="nav-link" onClick={e => clicked(data, y)}>{data.orderID}</Link></th>
                                <td class="hidetable">{data.subCategory}</td>
                                <td >
                                  {y.professionalId.first_name + " " + y.professionalId.last_name}
                                </td>
                                <td>{"$ " + data.totalAmount}</td>
                                <td>{y.status}</td>
                              </tr>
                            )
                          }
                        })
                      }
                    </tbody>
                  )
                })
              }

            </table>
          </div>
          <div class="col-12 mt-1 dtview">
            To view full slide right.
          </div>
          <div className="table-responsive" style={{ display: display2 }}>
            <table className="table  table-bordered text-center">
              <thead>
                <tr className="table-heading">
                  <th className="table-heading-first" scope="col">Order Number</th>
                  <th scope="col">Service Name</th>
                  <th scope="col">$&nbsp;Amount</th>
                  <th scope="col"><span>Status</span><br></br><span>Active/Inprogress</span></th>
                  <th className="table-heading-last" scope="col">Order Type</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>House Cleaning</td>
                  <td>$115</td>
                  <td><a href="/order-summary"> Active </a></td>
                  <td>Order</td>

                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Accounting</td>
                  <td>$115</td>
                  <td><a href="/order-summary"> Active </a></td>
                  <td>Order</td>

                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td >Web Design</td>
                  <td>$112</td>
                  <td><a href="/order-summary">Inprogress</a></td>
                  <td>Request</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td >Web Design</td>
                  <td>$150</td>
                  <td><a href="/order-summary">Inprogress</a></td>
                  <td>Request</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td >Web Design</td>
                  <td>$112</td>
                  <td><a href="/order-summary">Inprogress</a></td>
                  <td>Request</td>
                </tr>
                <tr>
                  <th scope="row">6</th>
                  <td >Web Design</td>
                  <td>$112</td>
                  <td><a href="/order-summary"> Active </a></td>
                  <td>Order</td>
                </tr>
                <tr>
                  <th scope="row">7</th>
                  <td >Web Design</td>
                  <td>$112</td>
                  <td><a href="/order-summary">Inprogress</a></td>
                  <td>Request</td>
                </tr>
                <tr>
                  <th scope="row">8</th>
                  <td >Web Design</td>
                  <td>$112</td>
                  <td><a href="/order-summary"> Active </a></td>
                  <td>Order</td>
                </tr>
                <tr>
                  <th scope="row">9</th>
                  <td >Web Design</td>
                  <td>$112</td>
                  <td><a href="/order-summary"> Active </a></td>
                  <td>Order</td>
                </tr>
                <tr>
                  <th scope="row">10</th>
                  <td >Web Design</td>
                  <td>$50</td>
                  <td><a href="/order-summary">Inprogress</a></td>
                  <td>Request</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-responsive" style={{ display: display3 }}>
            <table className="table  table-bordered text-center">
              <thead>
                <tr className="table-heading">
                  <th className="table-heading-first" scope="col">Order Number</th>
                  <th scope="col">Service Name</th>
                  <th scope="col">$&nbsp;Amount</th>
                  <th scope="col"><span>Status</span><br></br><span>Active/Inprogress</span></th>
                  <th className="table-heading-last" scope="col">Order Type</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>House Cleaning</td>
                  <td>$115</td>
                  <td><a href="/order-summary"> Active </a></td>
                  <td>Order</td>

                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Accounting</td>
                  <td>$115</td>
                  <td><a href="/order-summary"> Active </a></td>
                  <td>Order</td>

                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td >Web Design</td>
                  <td>$112</td>
                  <td><a href="/order-summary">Inprogress</a></td>
                  <td>Request</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td >Web Design</td>
                  <td>$150</td>
                  <td><a href="/order-summary">Inprogress</a></td>
                  <td>Request</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td >Web Design</td>
                  <td>$112</td>
                  <td><a href="/order-summary">Inprogress</a></td>
                  <td>Request</td>
                </tr>
                <tr>
                  <th scope="row">6</th>
                  <td >Web Design</td>
                  <td>$112</td>
                  <td><a href="/order-summary"> Active </a></td>
                  <td>Order</td>
                </tr>
                <tr>
                  <th scope="row">7</th>
                  <td >Web Design</td>
                  <td>$112</td>
                  <td><a href="/order-summary">Inprogress</a></td>
                  <td>Request</td>
                </tr>
                <tr>
                  <th scope="row">8</th>
                  <td >Web Design</td>
                  <td>$112</td>
                  <td><a href="/order-summary"> Active </a></td>
                  <td>Order</td>
                </tr>
                <tr>
                  <th scope="row">9</th>
                  <td >Dog Training</td>
                  <td>$123</td>
                  <td>Active</td>
                  <td>Order</td>
                </tr>
                <tr>
                  <th scope="row">10</th>
                  <td >Web Design</td>
                  <td>$50</td>
                  <td><a href="/order-summary">Inprogress</a></td>
                  <td>Request</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
