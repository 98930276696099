import React from 'react';
import { Switch, Route } from "react-router-dom";
import General from './General';
import Status from './Status';
import SearchAccount from './SearchAccount';
import Search from './Search';
import SearchService from './SearchService';
import SearchEarning from './SearchEarning';
import SearchChangeLog from './SearchChangeLog';

export default function Content() {

    return (
        <Switch>
            <Route exact path="/newAdmin/search" component={General} />
            <Route path="/newAdmin/general" component={General} />
            <Route path="/newAdmin/status" component={Status} />
            <Route path="/newAdmin/search-account" component={SearchAccount} />
            <Route path="/newAdmin/search-service" component={SearchService} />
            <Route path="/newAdmin/search-earning" component={SearchEarning} />
            <Route path="/newAdmin/search-change-log" component={SearchChangeLog} />
        </Switch>
    )
}
