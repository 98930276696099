
import React, { useState } from 'react';
import CKEditor from 'ckeditor4-react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import service5 from '../assets/homepage/images.png';
import { useEffect } from "react";
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery';

const validPassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

export default function PersonalSettings() {
  const [color, setColor] = useState('Earnings')
  const [component1, setComponent1] = useState('Summary')
  const [component, setComponent] = useState('dailyupdate')
  const [tableData, settableData] = useState('')
  const [profile, setprofile] = useState('')
  let providerData;
  const getd = () => {
    providerData = JSON.parse(sessionStorage.getItem("providerData"))
  }
  const [userPass, setuserPass] = useState({})
  const [userPass2, setuserPass2] = useState({
    current: "",
    newpass: "",
    confirm: ""
  })
  const [updateprofile, setupdateprofile] = React.useState({

  })
  const [error, seterror] = useState({
    Transit: " 5 Number required",
    Financial: " 3 Number required",
    Account: " 12 Number required",
  })
  const [Noti, setNoti] = useState({})




  React.useEffect(() => {
    setTimeout(function () { topFunction2() }, 100);
    let data2 = document.getElementById("textNotification")
    let data = document.getElementById("emailNotification")
    let data3 = document.getElementById("newNotification")

    if (providerData.emailNotification == true) {
      data.checked = true
    }
    if (providerData.textNotification == true) {
      data2.checked = true
    }
    if (providerData.newNotification == true) {
      data3.checked = true
    }
  }, [tableData, providerData, profile])

  React.useEffect(() => {
  }, [userPass2, userPass])
  const topFunction2 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  const saveNewSetting = () => {
    let get = JSON.parse(sessionStorage.getItem("providerData"))
    if (get) {
      fetch(`${API}/professional/updatePersonalSetting`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(get)
        })
        .then(response => response.json().then(data => {
          if (response.status == 200) {
            toast.success("Details Updated Successfully .");
            sessionStorage.setItem('providerData', JSON.stringify(data))
          }
          else {
            toast.error("Ooops!! Updation Failed .");
          }
        })
        )
      // .catch(err => toast.error("Ooops!! Something went wrong."));
    }
  }
  const changePassword = (data) => {
    console.log(data)
    if (data) {
      data._id = providerData._id
      fetch(`${API}/professional/changePassword`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        })
        .then(response => response.json().then(data => {
          console.log(data)
          if (response.status == 200) {
            toast.success(data.msg);
            setuserPass2({})
          }
          else {
            toast.error("Current Password doesn't match");
            setuserPass2({ ...userPass2, ["current"]: "niui" })
          }
        })
        )
      // .catch(err => toast.error("Ooops!! Something went wrong."));
    }
  }

  const imgchange = (e, id) => {
    var formdata = new FormData();
    formdata.append("user_id", id);
    formdata.append("photo_id", e.target.files[0]);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${API}/professional/uploadprofileimage`, requestOptions)
      .then(response => response.json())
      .then(result => {
        toast.success("Profile picture has been updated")
        setprofile(result.image_path)
        againdatafetch()
      })
      .catch(error => toast.error('error', error));
  }


  const againdatafetch = () => {
    fetch(`${API}/admin/getAllProfessional`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json()
        .then(result => {
          result.result.map((exitag) => {
            if (exitag._id == providerData._id) {
              sessionStorage.setItem('providerData', JSON.stringify(exitag))
              setTimeout(function () {
              }, 1000);

            }
          })
        }))
      .catch(error => toast.error('error', error));

  }

  const handleChange = (cName, e) => {
    if (updateprofile == '') {
      setupdateprofile(providerData)
    } else {
      let newdata = { ...providerData, [e.target.name]: e.target.value }
      sessionStorage.setItem('providerData', JSON.stringify(newdata))
      // setupdateprofile(newdata)
      providerData[cName] = e.target.value
    }
  }



  const localsave = () => {
    // sessionStorage.setItem('providerData', JSON.stringify(updateprofile))
    saveNewSetting()
  }


  const fakesuccess = () => {
    if (Noti.key) {
      let abc = Noti.key
      let data = {
        "_id": providerData._id,
        [Noti.key]: Noti.stat
      }
      fetch(`${API}/professional/updatePersonalSetting`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        })
        .then(response => response.json().then(data => {
          if (response.status == 200) {
            sessionStorage.setItem('providerData', JSON.stringify(data))
            toast.success("Saved successfully");
          }
          else {
            toast.error(data.msg);
          }
        })
        )
        .catch(err => toast.error("Ooops!! Something went wrong."));
    }
    else {
      toast.error("Change Manage Notifications  Setting");
    }
  }


  const cecked = (e) => {
    let check = e.target.checked
    let val = e.target.name
    setNoti({ "key": val, "stat": check })
  }

  const handlePassword = (cName, e) => {

    switch (e.target.name) {
      case "current": userPass2.current = e.target.value.length > 7 ? "" : "fdf"
        break;
      case "newpass": userPass2.newpass = validPassword.test(e.target.value) ? "" : "Test@123"
      case "confirm": userPass2.confirm = userPass.newpass == e.target.value ? "" : "notsame"
        break;
      case "confirm": userPass2.confirm = userPass.newpass == e.target.value ? "" : "notsame"
        break
      default:
        break;
    }
    // userPass[cName] = e.target.value
    setuserPass({ ...userPass, [cName]: e.target.value })
  }
  return (
    <>
      {getd()}
      <div className="container">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
        <div className="row mt-5 profset">
          <div className="col-sm-12">
            <div className="borderP" >
              <div className="header">
                <p>Account Information</p>
              </div>
              <div className="row">
                <div className="col-sm-8">
                  <div className="row col-12">
                    <div className="form-group col-sm-4">
                      <input type="text" className="" onChange={(e) => handleChange("first_name", e)} name="first_name" id="fName" placeholder="First Name" defaultValue={providerData.first_name} />
                    </div>
                    <div className="form-group col-sm-4">
                      <input type="text" className="" onChange={(e) => handleChange("middle_name", e)} name="middle_name" id="mName" placeholder="Middle Name" defaultValue={providerData.middle_name} />
                    </div>
                    <div className="form-group col-sm-4">
                      <input type="text" className="" onChange={(e) => handleChange("last_name", e)} name="last_name" id="lName" placeholder="Last Name" defaultValue={providerData.last_name} />
                    </div>
                  </div>
                  <div className="row  col-12">
                    <div className="form-group col-sm-12">
                      <input type="email" className="" onChange={(e) => handleChange("email", e)} id="email" name="email" placeholder="Email Address" defaultValue={providerData.email} />
                    </div>
                  </div>
                  <div className="row col-12">
                    <div className="form-group col-sm-12">
                      <input type="text" className="" onChange={(e) => handleChange("mobile_number", e)} id="phone" name="mobile_number" placeholder="Phone Number" defaultValue={providerData.mobile_number} />
                    </div>
                  </div>

                  <div className="row col-12">

                    <div className="form-group col-sm-12">
                      <input id="" className="" onChange={(e) => handleChange("address", e)} name="address" placeholder="Address" defaultValue={providerData.address} />
                    </div>
                  </div>
                  <div className="row col-12">
                    <div className="form-group col-sm-6">
                      <input id="city" className="" onChange={(e) => handleChange("city", e)} name="city" placeholder="City" defaultValue={providerData.city} />

                    </div>
                    <div className="form-group col-sm-6">
                      <input id="state" className="" onChange={(e) => handleChange("state", e)} name="state" placeholder="State" defaultValue={providerData.state} />
                    </div>
                  </div>
                  <div className="row col-12">
                    <div className="form-group col-sm-6">
                      <input id="country" className="" onChange={(e) => handleChange("country", e)} name="country" placeholder="Country" defaultValue={providerData.country} />
                    </div>
                    <div className="form-group col-sm-6">
                      <input id="postal_code" className="" onChange={(e) => handleChange("postal_code", e)} name="postal_code" placeholder="Zip/Postal Code" defaultValue={providerData.postal_code} />

                    </div>
                    <button onClick={() => localsave()} className="button btn1 float-left">Save</button>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="profile">
                    <div className="profile-img">
                      <img className="profileimage" src={profile == '' ? providerData.professionalImage ? `${API}/files/${providerData.professionalImage}` : service5 : `${API}/files/${profile}`} />
                      <div className="upload-button">
                        <div className="file btn tab-btns" >
                          <button class="btn btn-primary">Change</button>
                          <input type="file" name="profileImages" id="profile_pic_upload" onChange={(e) => imgchange(e, providerData._id)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 profset">
          <div className="col-sm-12">
            <div className="borderP">
              <div className="header">
                <p>Change Password</p>
              </div>

              <div className="header2">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-4 paddCss">
                      <label>Current Password</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="password" name="current" onBlur={(e) => handlePassword("current", e)} className={userPass2.current.length > 1 ? "error form-control" : "form-control"} placeholder="Type here" />
                      {userPass2.current.length > 0 && (<span className="errorMessage">{"Not Correct"}</span>)}
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Enter New Password</label>
                    </div>
                    <div className="col-sm-8 paddCss rlative passwordsug" style={{ paddingBottom: "0" }}>
                      <input type="password" onBlur={(e) => handlePassword("newpass", e)} className={userPass2.newpass.length > 1 ? "error form-control" : "form-control"} name="newpass" placeholder="Type here" class="" />
                      <div id="messagepassford" style={{ marginLeft: "10px" }}>
                        <h3>Password must contain the following:</h3>
                        <p id="capital" class="invalid">A <b>Capital (Uppercase)</b> letter</p>
                        <p id="letter" class="invalid">A <b>Lowercase</b> letter</p>
                        <p id="number" class="invalid">A <b>Special Characters</b></p>
                        <p id="number" class="invalid">A <b>Number</b></p>
                        <p id="length" class="invalid">Minimum <b>8 Characters</b></p>
                      </div>
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Re-enter New Password</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="password" onBlur={(e) => handlePassword("confirm", e)} className={userPass2.confirm != "" ? "error form-control" : "form-control"} name="confirm" placeholder="Type here" />
                      {userPass2.confirm.length > 0 && (<span className="errorMessage">{"Password Not Same"}</span>)}
                    </div>
                  </div>
                  <button onClick={() => changePassword(userPass)} className="button btn1">Change</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 profset">
          <div className="col-sm-12">
            <div className="borderP">
              <div className="header">
                <p>Manage Notifications</p>
              </div>
              <div className="header2 paddCss1">
                <div className="row">
                  <div className="col-sm-1 col-1">
                    <input type="checkbox" id="emailNotification" name="emailNotification" value="Bike" onChange={e => cecked(e)} />
                  </div>
                  <div className="4 col-11">
                    <label>Email about new job*</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-1 col-1">
                    <input type="checkbox" id="textNotification" name="textNotification" value="Bike" onChange={e => cecked(e)} />
                  </div>
                  <div className="4 col-11">
                    <label>Text new job (Charges may apply)</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-1 col-1">
                    <input type="checkbox" id="newNotification" name="newNotification" value="Bike" />
                  </div>
                  <div className="4 col-11">
                    <label>New Offers/Promotions</label>
                  </div>
                </div>

                <button onClick={() => fakesuccess()} className="button btn1">Save</button>
              </div>
            </div>
          </div>
        </div>


        {/* <div className="row mt-5">
          <div className="col-sm-12">
            <div className="borderP">
              <div className="header">
                <p>Bank Details</p>
              </div>

              <div className="header2">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-4 paddCss">
                      <label>Transit Number</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="number" name="transit_number" className="form-control" placeholder="5 digits" onChange={(e) => handleChangeacount(e)} defaultValue={providerData.transit_number} />
                      {<span className="errorMessage Transit d-none">{error.Transit}</span>}
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Financial institution number</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="number" className="form-control" name="Financial_institution_number" placeholder="3 digits" onChange={(e) => handleChangeacount(e)} defaultValue={providerData.Financial_institution_number} />
                      {<span className="errorMessage Financial d-none">{error.Financial}</span>}
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Account Number</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="number" className="form-control" name="Account_Number" placeholder="Upto  12 digits" onChange={(e) => handleChangeacount(e)} defaultValue={providerData.Account_Number} />
                      {<span className="errorMessage Account d-none">{error.Account}</span>}
                    </div>
                  </div>
                  <button onClick={() => localsave()} className="button btn1">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
