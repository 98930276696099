import React, { useState } from 'react';
import './Admin.css';
import { ToastContainer, toast } from 'react-toastify';
import { API } from "../backend";

export default function SearchChangeLog() {
  const [changenot, setchangenot] = useState('');
  const [reflect, setreflect] = useState([]);
  let user, currentlyClicked;
  const getcurrentlyClicked = (() => {
    currentlyClicked = JSON.parse(localStorage.getItem('currentlyClicked'));
  });

  React.useEffect(() => {

  }, [currentlyClicked, changenot, reflect])

  const changelog = (id) => {
    if (changenot) {
      let dataapi = {
        "_id": id,
        "addchangeLog": changenot
      }
      fetch(`${API}/professional/adminProfessionalLog`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataapi)
      })
        .then(response => response.json().then(data => {
          toast.success("Admin Note Successful Add")
          setchangenot('')
          let dat = { ...data.data }
          let abc = [dat]
          let sets = currentlyClicked.adminChangeLog.map((e) => {
            abc.push(e)
          })
          let set = { ...currentlyClicked, adminChangeLog: abc }
          localStorage.setItem("currentlyClicked", JSON.stringify(set))
          setreflect(abc)
        })
        )
    } else {
      toast.error("Please Type Your Note")
    }
  }

  return (
    <div className="container status-content">
      {getcurrentlyClicked()}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div>
        <textarea className="form-control textarea-search-change-log" rows="3" placeholder="Add notes" onChange={e => setchangenot(e.target.value)} value={changenot}></textarea>
        <button className="button btn btn-info col-sm-2 btn-lg mt-3 mb-5 admin-status-btn search-change-log-btn" onClick={e => setchangenot('')}>Cancel</button>
        <button className="button btn btn-info col-sm-2 btn-lg mt-3 mb-5 admin-status-btn search-change-log-btn" onClick={e => changelog(currentlyClicked._id)}>Save</button>
      </div>
      <div className="heading-change-log">
        <h3>Previous Notes</h3>
      </div>
      <div className="contentBorder">
        {
          currentlyClicked.adminChangeLog.map((log, index) => {
            return (
              <div className="sec1">
                <span>{index + 1}</span>
                <span>{log.addchangeLog}</span>
                <span>{(log.createdAt.substr(log.createdAt.lastIndexOf('\\') + 1).split('T')[0])}</span>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
