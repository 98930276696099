import React, { useState, useEffect } from 'react';
import Footer from './Footer'
import Header from '../homepage/Header'
import { Link } from 'react-router-dom'
import serviceBanner from '../assets/AllServices/services-banner.jpg';
import Homegarden from '../assets/homepage/home-&-garden.png'
import Health from '../assets/homepage/Helth-&-wellbeing.png'
import wedding from '../assets/homepage/wedding-&-events.png'
import Business from '../assets/homepage/Business-services.png'
import Lessons from '../assets/homepage/Lessons-&-training.png'
import beauty from '../assets/homepage/beauty-services.jpg'
import cleaning from '../assets/homepage/cleaning-services.jpg'
import electician from '../assets/homepage/electician.jpg'
import fitness from '../assets/homepage/health-fitness.jpg'
import pet from '../assets/homepage/pet-services.jpg'
import { API } from "../backend";
const OurServices = () => {
  const [readMore, setReadMore] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showBussiness, setShowBussiness] = useState(false);
  const [showCarpet, setShowCarpet] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableDatafix, setTableDatafix] = useState([]);
  const [count, setcount] = useState(false);
  useEffect(() => {
    async function providerDetails() {
      if (!count) {
        fetch(`${API}/admin/getAllCategory`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
          .then(response => response.json().then(data => {
            if (response.status == 200) {
              data.map((e) => tableData.push(e))
              data.map((e) => tableDatafix.push(e))
              setcount(true)
            } else {
              alert("Ooops!! Failed to fetch data.");
            }
          })
          )
          .catch(err => alert("Ooops!! Something went wrong."));
      }
    }
    providerDetails();
  }, [tableDatafix, tableData]);


  const providerTable = ((x, index) => {
    return (
      <div className="col-md-3 col-sm-6 pl-2 pr-2 mb-2 mt-4" >
        <Link to={"/service/" + x._id} style={{ padding: "14px", overflow: "hidden", display: "block" }}>
          <img className="service" src={x.categoryImage ? `${API}/files/${x.categoryImage}` : beauty} alt="" />
          <div className="popular_services mt-2">
            <Link to={"/service/" + x._id}>{x.category}</Link>
          </div>
        </Link>
      </div>
    )
  })
  const togal = (e) => {
    console.log("hivyug")
    var element = document.getElementById(e);
    element.classList.toggle("mystyle");
  }
  const providerTable2 = ((x, index) => {
    return (
      <div className="row mb-4">
        <div className="col-md-12 mt-2 col-10 head11" >
          <h4 onClick={e => togal("tog" + index)}>{x.category}</h4>
        </div>
        <div className="col-md-1 mt-2 mv col-1">
          <h4 onClick={e => togal("tog" + index)}><i class="fa fa-angle-down" aria-hidden="true"></i></h4>
        </div>
        <div className="row col-12 hidepart " id={"tog" + index}>
          {
            x.subcategory.map((y) => {
              return (
                <div className="col-md-3 mb-3 col-sm-6 col-12">
                  <a className="cardLink text-center" href={"/search-professional/" + y.value}>{y.value}</a>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  })


  return (
    <>
      <Header />
      <div className="Banner">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <img className="Banner" src={serviceBanner} alt="Responsive image" />
            <div class="bannerText">
              <h1 className="secheading mt-5 mb-5"><span className="bannerHeading">OUR SERVICES</span></h1>
            </div>
          </div>
        </div>

      </div>
      <div className="container mt-3 servicesfull">
        <div className="row phonehide mb-4">
          {tableData.length > 0 ? tableData.map(providerTable) : ""}
        </div>
        {readMore }
        <div className="row  mb-4">
          <div className="col-md-12 mb-2">
            <h3 class=" mb-4"><span class="heading-1">All</span>&nbsp;<span class="heading-2">Services</span></h3>
          </div>
        </div>
        {tableData.length > 0 ? tableData.map(providerTable2) : ""}
      </div>
      <Footer />
    </>
  )
}
export default OurServices