import React from 'react';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import service5 from '../assets/homepage/service1.png';
import { Star as AddIcon } from '@material-ui/icons';
import Showreview from "../review/Showreview";
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import saveIcon from '../assets/Icons/save.png';
import Admin from './Admin.css';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom'
import { Modal, Container, Row } from "react-bootstrap";

export default function Liveoperationsdetails() {
  const [component, setComponent] = useState('#EA4337')
  const [color1, setColor1] = useState('#EA4337')
  const [color2, setColor2] = useState('#ccc')
  const [color3, setColor3] = useState('#EA4337')
  const [color4, setColor4] = useState('')
  const [color5, setColor5] = useState('#ccc')
  const [color6, setColor6] = useState('')
  const [color7, setColor7] = useState('#ccc')
  const [color8, setColor8] = useState('')

  const [display1, setDisplay1] = useState()
  const [display2, setDisplay2] = useState('none')
  const [display3, setDisplay3] = useState('none')
  const [display4, setDisplay4] = useState('none')
  const [ordergetData, setordergetData] = useState(JSON.parse(localStorage.getItem("ordercurrentlyClicked")))
  const [totalprice, settotalprice] = useState(0)
  const [count, setcount] = useState(false);
  const [intro, setintro] = useState('');
  const [changenot, setchangenot] = useState('');
  let total = 0;
  let [comp, setcom] = useState('')
  const [statussetdata, setstatusset] = React.useState('')
  const [pid, sepid] = React.useState(0)
  const [showPopup, setPopup] = useState(false);
  const [showPopup2, setPopup2] = useState(false);

  const [dubq, setdubq] = useState([])



  const dublicate = () => {
    ordergetData.selectPrice.map((x) => {
        let answersfit = x.ansawer.substr(x.ansawer.lastIndexOf('\\') + 1).split('#')[0]
      let some = false
      dubq.map((y, index) => {
        if (x.question == y.question) {
          some = true
          y.ansawer = (y.ansawer + ' <br/> ' +  answersfit )
        }
      })
      if (some == false) {
        dubq.push({ "question": x.question, "ansawer": answersfit }  )
      }

    })
  }

  const handleCloseSignupForm = () => {
    setPopup(false)
    setPopup2(false)
  };
  const [redirect, setredirect] = React.useState('')
  useEffect(() => {
    ordergetData.selectPrice.map((e) => {
      total = parseInt(total) + parseInt(e.ansawer)
      settotalprice(total)
    })
    if (!count) {
      dublicate()
      setcount(true)
      let dataapi = [{
        "bookingId": ordergetData._id,
        "isAdmin": false
      }]
      fetch(`${API}/admin/updateNotification`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataapi)
      })
        .then(response => response.json().then(data => {
          if (response.status == 200) {
          }
        })
        )
        .catch(err => toast.error("Ooops!! Something went wrong."));
    }

  }, [ordergetData, totalprice, changenot])

  const statuschange = () => {
    let dataapi = {
      "_id": pid.id,
      "status": statussetdata
    }
    fetch(`${API}/user/UpdateStatus`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(dataapi)
    })
      .then(response => response.json().then(data => {
        if (response.status == 200) {
          toast.success("Successful")
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));

  }


  const statusset = (id, pid) => {
    setredirect(id)
    if (statussetdata == "") {
      toast.error("Please select status")
    }
    else if (statussetdata != "Accepted") {
      let dataapi = {
        "_id": id,
        "status": statussetdata,
        "professionalId": pid
      }
      fetch(`${API}/user/UpdateStatus`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataapi)
      })
        .then(response => response.json().then(data => {
          if (response.status == 200) {
            toast.success("Successful")
            setagain(id)
          }
        })
        )
        .catch(err => toast.error("Ooops!! Something went wrong."));
      setcount(false)
    } else {
      setPopup2(true)
    }
  }
  const clicked = () => {
    let dataapi = {
      "professionalId": pid.id,
      "totalPoint": pid.point,
      "paymentStatus": "redeemed"
    }
    fetch(`${API}/professional/transaction`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(dataapi)
    })
      .then(response => response.json().then(data => {
        if (data.result) {
          // toast.success("Successful")
          acceptbooking()

        } else {
          setPopup(true)
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }

  const setagain = (id) => {
    fetch(`${API}/user/getAllOrder`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => response.json().then(data => {
        if (response.status == 200) {
          data.map((e) => {
            if (e._id == id) {
              localStorage.setItem(
                'ordercurrentlyClicked', JSON.stringify(e)
              )
              setordergetData(e)
            }
          })
        } else {
          toast.error("Ooops!! Failed to fetch data.");
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }

  const acceptbooking = () => {
    let dataapi = {
      "_id": redirect,
      "status": statussetdata,
      "professionalId": pid.id
    }
    fetch(`${API}/user/UpdateStatus`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(dataapi)
    })
      .then(response => response.json().then(data => {
        if (response.status == 200) {
          toast.success("Successful")
          setcount(false)
          setPopup2(false)
          setagain(redirect)
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }

  const introadd = (id) => {
    if (intro) {

      let dataapi = {
        "_id": id,
        "adminNotes": intro
      }
      fetch(`${API}/admin/addAdminNotes`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataapi)
      })
        .then(response => response.json().then(data => {
          toast.success("Admin Note Successful Update")
        })
        )
    }
  }

  const changelog = (id) => {
    if (changenot) {
      let dataapi = {
        "_id": id,
        "addchangeLog": changenot
      }
      fetch(`${API}/admin/addChangeLog`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataapi)
      })
        .then(response => response.json().then(data => {
          toast.success("Admin Note Successful Add")
          let dat = { ...data.data }
          let abc = [dat]
          let sets = ordergetData.adminChangeLog.map((e) => {
            abc.push(e)
          })
          let set = { ...ordergetData, adminChangeLog: abc }
          localStorage.setItem("ordercurrentlyClicked", JSON.stringify(set))
          setordergetData(set)
          setchangenot('')
        })
        )
    } else {
      toast.error("Please Type Your Note")
    }
  }

  const document = (e) => {
    var formdata = new FormData();
    formdata.append("_id", ordergetData._id);
    formdata.append("image", e.target.files[0]);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${API}/professional/workDoneDocument`, requestOptions)
      .then(response => response.json())
      .then(result => {
        toast.success(result.message)
        let set = { ...ordergetData, uploadDocument: result.data }
        localStorage.setItem("ordercurrentlyClicked", JSON.stringify(set))
        setordergetData(set)
      })
      .catch(error => toast.error('error', error));
  }

  const noneqsn = (e) => {
    let questionpop = document.getElementById("point").style.display = "none"
  }
  const pointcut = (e) => {
    let questionpop = document.getElementById("point").style.display = "block"
  }
  return (
    <div className="AdminLiveOperation">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />

      <Modal
        show={showPopup}
        id="questionForm"
        onHide={handleCloseSignupForm}
        size="lg"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title><strong>{"Order Summary"}</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <div class="popupprovider questionpopup successtab">
                <p>You have no points so please by points and check order</p>
              </div>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <div className="row headerHeading">
        <div className="menuHeading">
          <h3 className="mt-4 mb-4"><span className="heading-1">Live Operations</span></h3>
        </div>
      </div>

      <div className="contentBorder mt-3">

        <div className="row-width row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textcenter" style={{ color: color1, boderBottomColor: color1, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid' }}>
            <button className="btn button activeclass pastclass  liveopbtn" id="button" style={{ color: color3 }} onClick={() => { setColor1('#EA4337'); setColor2('#ccc');; setDisplay1('block'); setDisplay2('none'); setDisplay3('none'); setColor4(''); setColor5('#ccc'); setColor3('#EA4337'); setColor6(''); setColor7('#ccc'); setColor8(''); setDisplay4('none') }}>Order Management</button>
          </div>
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textcenter" style={{ color: color2, boderBottomColor: color2, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid ' }}>
            <button className="btn button pastclass liveopbtn" style={{ color: color4 }} onClick={() => { setColor1('#ccc'); setColor2('#EA4337'); setDisplay1('none'); setDisplay2('block'); setDisplay3('none'); setColor3(''); setColor5('#ccc'); setColor4('#EA4337'); setColor6(''); setColor7('#ccc'); setColor8(''); setDisplay4('none') }}>Order Status</button>
          </div>
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textcenter" style={{ color: color5, boderBottomColor: color5, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid ' }}>
            <button className="btn button pastclass liveopbtn" style={{ color: color6 }} onClick={() => { setColor1('#ccc'); setColor2('#ccc'); setDisplay1('none'); setDisplay2('none'); setColor3(''); setColor4(''); setColor5('#EA4337'); setColor6('#EA4337'); setDisplay3('block'); setColor7('#ccc'); setColor8('#'); setDisplay4('none') }}>Order  Summary</button>
          </div>
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textcenter" style={{ color: color7, boderBottomColor: color7, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid ' }}>
            <button className="btn button pastclass liveopbtn" style={{ color: color8 }} onClick={() => { setColor1('#ccc'); setColor2('#ccc'); setDisplay1('none'); setDisplay2('none'); setDisplay3('none'); setColor4(''); setColor5('#ccc'); setColor6(''); setColor7('#EA4337'); setColor8('#EA4337'); setDisplay4('block'); setColor3('') }}>Change Log</button>
          </div>
        </div>
        {/* <hr className="activepastline"></hr> */}
        <div className="responsive" style={{ display: display1 }}>
          <div className="row mt-5">
            <div className="col-sm-12">
              <div className="header2">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-4 paddCss">
                      <label>Service Provider Name</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      {/* <input type="text" name="" className="form-control" value={ordergetData.professionalId.first_name + ' ' + ordergetData.professionalId.last_name} /> */}

                      {
                        ordergetData.uploadDocument.length > 0 ? ordergetData.professionalId.map((e) => {
                          if (e.status == "Completed" || e.status == "Cancelled") {
                            return (
                              <input type="text" class="form-control" value={e.professionalId.first_name + " " + e.professionalId.last_name} />
                            )
                          }
                        }) :
                          <select class="form-control">
                            {
                              ordergetData.professionalId.map((e) => {
                                return (
                                  <option>{e.professionalId.first_name + " " + e.professionalId.last_name}</option>
                                )
                              })
                            }
                          </select>
                      }
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Service</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="text" className="form-control" name="" value={ordergetData.subCategory} />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Date & Time</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="text" className="form-control" name="" value={ordergetData.startTime + " - " + ordergetData.endTime + (ordergetData.bookingDate ? ' | ' + (ordergetData.bookingDate.substr(ordergetData.bookingDate.lastIndexOf('\\') + 1).split('T')[0]) : "")} />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Address</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <textarea type="text" className="form-control" name="" value={ordergetData.professionalId.address} />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Amount</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="text" className="form-control" name="" value={"$" + ordergetData.totalAmount} />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Customer Notes</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="text" className="form-control" name="" value={ordergetData.addNote} />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Service Provider Notes</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="text" className="form-control" name="" defaultValue={ordergetData.adminNotes ? ordergetData.adminNotes : intro} onChange={e => setintro(e.target.value)} />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Additional Amount</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="text" className="form-control" name="" />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Customer Name</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="text" className="form-control" name="" value={ordergetData.userId.first_name + ' ' + ordergetData.userId.last_name} />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Email</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="email" className="form-control" name="" value={ordergetData.userId.email} />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Phone Number</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="number" className="form-control" name="" value={ordergetData.userId.mobile_number} />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Customer Address</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="email" className="form-control" name="" value={ordergetData.userId.address} />
                    </div>
                    <div className="col-sm-4 paddCss">
                    </div>
                    <div className="col-sm-3 paddCss">
                      <button type="button" class="btn btn-block admin-status-btn" onClick={e => introadd(ordergetData._id)}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="responsive" style={{ display: display2 }}>
          <div className="row mt-2">
            <div className="col-sm-12">
              <div className="header2">
                <div className="col-sm-12">
                  {/* <div className="row">
                    <div className="col-sm-4 paddCss">
                      <label>Time Placed</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="text" name="" className="form-control" />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Confirmed</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="text" className="form-control" name="" />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Reschedule</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="text" className="form-control" name="" />
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-sm-12 paddCss">
                      <label class="status"><strong>Theom Request Status</strong></label>
                    </div>
                    <Modal
                      show={showPopup2}
                      id="questionForm"
                      onHide={handleCloseSignupForm}
                      size="lg"
                      keyboard={false}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title><strong>{"Order Summary"}</strong></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Container>
                          <Row>
                            <div class="shadow" id="point" style={{ display: "block" }}>
                              <div class="popupprovider questionpopup successtab">
                                <p>You will be  charged {pid.point} points to see the details of the customer,if you agree click YES otherwise NO</p>
                                <div class="buttons" >
                                  <button class="buttonn preview" onClick={e => clicked()}> YES</button>
                                  <button class="buttonn next" onClick={e => setPopup2(false)}> NO</button>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </Container>
                      </Modal.Body>
                    </Modal>

                    <div className="col-sm-12  paddCss">
                      <div class="table-responsive">
                        <table className="table contentBorder admintable text-center tableLayout mt-2" style={{ textAlign: 'center' }}>
                          <thead>
                            <tr className="tableHeading table-heading">
                              <th className="table-heading-first" scope="col" style={{ border: "none" }}>Professional Name</th>
                              <th scope="col " class="table-heading-first" style={{ border: "none" }}>Status</th>
                              <th scope="col" style={{ border: "none" }}>Points</th>
                              <th scope="col" style={{ border: "none" }}></th>
                            </tr>
                          </thead>
                          {
                            ordergetData.professionalId.map((e, index) => {
                              return (
                                <tbody style={{ border: "1px solid #ced4da" }}>
                                  <tr>
                                    <td>{e.professionalId.first_name + ' ' + e.professionalId.last_name}</td>
                                    <td>
                                      {
                                        e.status != "Pending" ? e.status :
                                          <form>
                                            <select className="select-action" onChange={e => {
                                              setstatusset(e.target.value)
                                              sepid({ "id": ordergetData.professionalId[index].professionalId._id, "point": ordergetData.tokenPrice })
                                            }} style={{ width: "120px" }}>
                                              <option value="Pending">Pending</option>
                                              <option value="Accepted">Accepted</option>
                                              <option value="Reject">Reject</option>
                                            </select>

                                          </form>
                                      }
                                    </td>
                                    <td>
                                      {ordergetData.tokenPrice}
                                    </td>

                                    <td>
                                      {
                                        e.status != "Pending" ? <Button><img className="select-image img-fluid" src={saveIcon} />
                                        </Button> : <Button onClick={e => statusset(ordergetData._id, ordergetData.professionalId[index].professionalId._id)} style={{ minWidth: "45px", padding: "6px 0" }} style={{ minWidth: "45px" }}><img className="select-image img-fluid" src={saveIcon} />
                                        </Button>
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })
                          }


                          {/* <tbody style={{ border: "1px solid #ced4da" }}>
                          <tr>
                            <td>{ordergetData.professionalId.first_name + ' ' + ordergetData.professionalId.last_name}</td>
                            <td>
                              <form>
                                <select className="select-action" onChange={e => statuschange(e, ordergetData._id)} style={{ width: "120px" }}>
                                  <option value="Pending">Pending</option>
                                  <option value="Accept">Accept</option>
                                  <option value="Reject">Reject</option>
                                </select>

                              </form>
                            </td>
                            <td>
                              {ordergetData.tokenPrice}
                            </td>

                            <td>
                              <Button><img className="select-image img-fluid" src={saveIcon} />
                              </Button>
                            </td>
                          </tr>
                        </tbody> */}
                        </table>
                      </div>
                      <div className="col-sm-12 paddCss">
                        <label class="status"><strong>Complete Booking Document</strong></label>
                      </div>
                      <div className="bottom-status" style={{ whiteSpace: "nowrap" }}>
                        <div className="row mt-3">
                          <div className="col-sm-12 mb-4" >
                            {
                              ordergetData.uploadDocument.map((e) => {
                                return (
                                  <a data-fancybox=" gallery" href={`${API}/files/${e}`}>
                                    <img className="documetimg ml-2" src={`${API}/files/${e}`} />
                                  </a>
                                )
                              })
                            }
                          </div>
                          <div className="col-sm-2">
                            <a className="cancel-Post"><button type="button" id="cancelPost" className="btn btn-primary" style={{ width: "130px" }}>Document</button></a>
                            <input type="file" onChange={e => document(e)} className="fileset" />
                          </div>
                        </div>
                      </div>
                      {/* <div class="responsive" style={{ display: "block" }}>
                        <div class="form-group ">
                          <table class="table text-center">
                            <tr>
                              <td style={{ textAlign: "left" }}>

                              </td>
                              <td></td>
                              <td><p class="table-row-content">Profile Image</p></td>
                            </tr>
                          </table>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-sm-4 paddCss">
                      <label>Pro reach time</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="text" name="" className="form-control" />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Pro end time</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="text" className="form-control" name="" />
                    </div>
                    <div className="col-sm-4 paddCss">
                      <label>Extra time hold</label>
                    </div>
                    <div className="col-sm-8 paddCss">
                      <input type="text" className="form-control" name="" />
                    </div>
                    <div className="col-sm-4 paddCss">
                    </div>
                    <div className="col-sm-3 paddCss">
                      <button type="button" class="btn btn-block admin-status-btn">Save</button>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="table-responsive" style={{ display: display3 }}>
                <table className="table  tableHeading text-center">
                  <thead>
                    <tr className="table-heading">
                      <th className="table-heading-first" scope="col">Professinal Name</th>
                      <th className="table-heading-last" scope="col">Status</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>$ 20</td>
                      <td>
                        <p>12:00 PM</p>
                        <p>01-09-2020</p>
                      </td>
                      <td>Rejected</td>

                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>$ 20</td>
                      <td> <p>12:00 PM</p>
                        <p>01-09-2020</p></td>
                      <td>Rejected</td>

                    </tr>


                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="responsive" style={{ display: display3 }}>
          <div className="row">
            <div className="col-sm-12">
              <div className="header2 questionadmin">
                <div className="col-sm-12">
                  {
                    dubq && dubq.map((y, index) => {
                      return (
                        <div class="mt-3">
                          <p> <strong>{(index + 1) + " )  " + y.question}</strong> </p>
                          <div class="option">
                            <label for={"o1"} dangerouslySetInnerHTML={{ __html: y.ansawer}}></label>
                          </div>
                        </div>
                      )
                    })
                  }

                  <button type="button" id="cancelPost" class="btn btn-primary mt-5" >{ordergetData.totalAmount + " $ " + "Estimated Cost"}</button>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <hr style={{ float: "left", width: "100%", }}></hr>
              <div className="header2 questionadmin">
                <div className="col-sm-12">
                  <div class="first">
                    <h2>Note</h2>
                    <textarea id="w3review" name="w3review" rows="4" placeholder="Type Here" value={ordergetData.addNote} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <hr style={{ float: "left", width: "100%", }}></hr>
              <div className="header2 questionadmin">
                <div className="col-sm-12">
                  <div class="first">
                    <h2>Photo</h2>
                    <div class="first row">
                      {
                        ordergetData.uploadImage.length > 0 ? ordergetData.uploadImage.map((e) => {
                          return (<div className="col-md-2">
                            <a data-fancybox=" gallery" href={`${API}/files/${e}`}><img src={`${API}/files/` + e} style={{ width: "160px", height: "160px" }} /></a>
                          </div>)
                        }) : ""
                      }
                    </div>
                    {/* {ordergetData.uploadImage ? <a data-fancybox=" gallery" href={`${API}/files/${ordergetData.uploadImage}`}><img src={`${API}/files/` + ordergetData.uploadImage} style={{ maxWidth: "200px", maxHeight: "200px" }} /></a> : " "} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="resposive" style={{ display: display4 }}>
          <div className="row mt-5">
            <div className="col-sm-12">
              <div className="header2">
                <div className="col-sm-12">
                  <div className="row pbtn">
                    <textarea className="form-control textarea-search-change-log" rows="3" placeholder="Add notes" onChange={e => setchangenot(e.target.value)} value={changenot}></textarea>
                    <button className="button btn btn-info col-sm-2 btn-lg mt-3 mb-5 admin-status-btn search-change-log-btn" onClick={e => setchangenot('')}>Cancel</button>
                    <button className="button btn btn-info col-sm-2 btn-lg mt-3 mb-5 admin-status-btn search-change-log-btn" onClick={e => changelog(ordergetData._id)}>Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="resposive" style={{ display: display4 }}>
        <div className="mt-3">
          <h3>Previous Notes</h3>
        </div>
        <div className="contentBorder">
          {
            ordergetData.adminChangeLog.map((log, index) => {
              return (
                <div className="sec1">
                  <span>{index + 1}</span>
                  <span>{log.addchangeLog}</span>
                  <span>{(log.createdAt.substr(log.createdAt.lastIndexOf('\\') + 1).split('T')[0])}</span>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
