import React from 'react';
import Footer from './Footer'
import Header from '../homepage/Header'
import "../Style.css";
import careerBanner from '../assets/Career Page/career-banner.jpg';
function PrivacyPolicies() {

	return (
		<>
			<div ref={window.scrollTo(0, 0)} className="privacyPolicies">
				<Header />
				<div className="">
					<div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
						<img className="Banner" src={careerBanner} alt="Responsive image" />
						<div className="bannerText">
							<h1 className="secheading mt-5 mb-5"><span className="bannerHeading" style={{ textTransform: "uppercase" }}>privacy policy</span></h1>
						</div>
					</div>
				</div>
				<div className="privacyBody">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<h1 style={{ textAlign: "center" }}>Privacy Policy</h1>
								<h2 className="heading mb-4" style={{ fontWeight: "500" }} >
									Last Updated: December 6, 2021
								</h2>
								<p className="home-content">
									Theom,  Inc.  also,  its  subsidiaries  (by  and  large  "Theom,"  "we,"  "our,"  or  "us")  esteem  your
									security. In this Privacy ("Policy"), we portray the data we gather, how we use it, and when and
									with  whom  we  share  it.  This  Policy  applies  to  all  destinations,  versatile  applications,  and  other
									online administrations (by and large, "Stage") made accessible by Theom, including
									www.theom.com  and  www.setter.com.  Besides  as  in  any  case  expressly  gave  thus,  this  Policy
									applies just to data gathered on or through the Platform, and it doesn't matter to data gathered or
									acquired by or through some other means (counting, without impediment, data gathered
									disconnected, face to face, via phone as well as via mail, or from outsiders outside the Platform).
									Unclear promoted terms utilized thus will have the definitions as gone ahead in our Terms of Use.
									By getting to or utilizing the Platform, you consent to this Policy. On the off chance that <span> YOU DO
										NOT AGREE TO THIS POLICY, PLEASE DO NOT ACCESS, OR USE THE PLATFORM. </span>

								</p>
							</div>
						</div>
						<div className="row mt-5 mb-5">
							<div className="col-lg-12">
								<h2 className="heading mb-4" style={{ fontWeight: "500" }}>KEY TERMS
								</h2>
								<p className="home-content">"Customer  Member"  means  a  member  who  is  registered  to  receive  quotes  for  Pro  Services,
									requests  quotes  for  Pro  Services,  or  otherwise  uses  the  Platform  to  receive,  pay  for,  review,  or
									facilitate  the  receipt  of  Pro  Services  (including,  for  the  avoidance  of  doubt,  a  member  with  a
									consumer account who has directly or indirectly requested Pro Services via the Theom Platform).
								</p>
								<p className="home-content">"Member" means a person or entity who completes Theom's account registration process, a person
									or entity who submits or receives a request through Theom, including but not limited to Service
									Members and Customer Members, or a person with a consumer (sometimes referred to as a Theom
									login) account. </p>
								<p className="home-content">"Platform" means all Theom websites, mobile or other applications, software, processes, and any
									other services provided  by or through  Theom (including, for the avoidance of doubt, any of the
									foregoing branded as Theom company). </p>
								<p className="home-content">"Pro  Services"  means  the  services  listed,  quoted,  scheduled,  offered,  or  provided  by  Service
									Members, or sought, scheduled, or received by Customer Members, through the Platform. </p>
								<p className="home-content">"Service Member" means a member who is registered to send quotes for Pro Services, sends quotes
									for Pro Services, or otherwise uses the Platform to offer, provide, receive payment for, or facilitate
									the provision of Pro Services. </p>
								<p className="home-content">"Theom Content" means all Content Theom makes available on or through the Platform, including
									any Content licensed from a third party, but excluding User Content. </p>
								<p className="home-content">"User  Content"  means  all  Content  submitted,  posted,  uploaded,  published,  or  transmitted  on  or
									through the Platform by any Member or other user of the Platform, including but not limited to
									photographs, profile information, descriptions, postings, reviews, requests, messages, and
									payments made through the Platform, but excluding Theom Content and Feedback.</p>
								<ul>
									<li>
										<h3>1. How and why do we use your personal data</h3>
										<p className="home-content">We  process  your  personal  data  for  multiple  purposes,  relevant  to  our  relationship  and
											engagement. We are always required by the law to have a lawful basis and for processing
											your  personal  data.  We  use  the  collected  personal  data  for  various  number  of  reasons,
											including but not limited to providing our services with and/or to you, to communicate with
											you and verify your identity. We also use personal data to better serve you and make sure
											that our website and services are functioning properly alongside making sure that we are
											providing you with the best possible services and keeping accurate records for all the legal
											reasons.</p>
										<p className="home-content">We rely on several different lawful grounds or basis to process your personal data including
											your consent towards processing your data. We need your personal data to make a contract
											with you and to comply with our legal obligations and /or for our legitimate interests (or
											the legitimate interest of the third party). </p>
										<p className="home-content">Please be aware that our processing of your personal data is either (i) necessary for us to
											comply  with  a  legal  obligation,  or  (ii)  necessary  for  us  to  take  steps,  at  your  request,
											towards potentially entering a contract with you, or to enter or continue our contract with
											you or provision of our services.  </p>
										<p className="home-content">We may convert your personal data into statistical or aggregated form to better protect your
											privacy and your identity through your data present in our system making sure that you are
											not identifiable from it. Anonymous data cannot be linked back to you. We may use the
											data  to  conduct  research  and  analysis,  including  production  of  statistical  research  and
											reports. For instance, helping us understand and further improve the use of our  platform
											for you.</p>

									</li>
									<li>
										<h3>2. Whom do we share your personal data with and why? Where is your personal data
											transferred to? How long we keep it for and how do we safeguard it? And your rights
											in relation to your personal data.  </h3>
										<p className="home-content">We may use certain trusted third-party firms and/or individuals to help us provide, analyse,
											and improvise our services (including but not limited to Buyer/Professional verification,
											data storage, maintenance services, database management, web analytics, payment
											processing,  and  improvement  of  our  services).  These  third  parties  will  process  your
											personal data on our behalf (as our processor). We will disclose your personal data to these
											firms so that they can perform the necessary functions.</p>
										<p className="home-content">We may also share your information with third parties, who then use  your personal data
											for their own purposes. For instance, processing payments from you or where you choose
											to access our services through such an application. These organizations will also use your
											personal data for their own, separate purposes (also as a controller) – they will have their
											own  privacy  notices  which  you  should  read,  and  they  have  their  own  responsibilities  to
											comply with applicable data protection laws.</p>
										<p className="home-content">In  certain  circumstances,  wherever  necessary  for  the  set  purposes,  we  will  also  disclose
											your personal data to: </p>
										<p className="home-content">(a)  Consultants and professional advisors including legal advisors, auditors and
											accountants; </p>
										<p className="home-content">(b) Business partners and joint ventures; </p>
										<p className="home-content">(c)  A prospective seller or buyer of our business and their advisors;</p>
										<p className="home-content">(d) Insurers; </p>
										<p className="home-content">(e)  Courts, court-appointed persons/entities, receivers, and liquidators;</p>
										<p className="home-content">(f)  Third parties where necessary to comply with a legal obligation, to enforce a contract
											or to protect the rights, property or safety of our employees, customers, or others; and </p>
										<p className="home-content">(g) To  governmental  departments,  local  government,  statutory  and  regulatory  bodies
											including Canadian Federal Government, the information Commissioner’s Office, the
											Canadian Police Department and Customs Canada.</p>
										<p className="home-content">When  we  share  personal  data  about  you  including  but  not  limited  to  name,  contact  and
											banking details and as set out above, this may also include a transfer of your personal data
											to  recipients  outside  Canada.  If  any  disclosure  of  your  personal  data  means  that  your
											personal  data  will  be  transferred  outside  the  area  of  North  American  Continent,  we  will
											only make that transfer in accordance with our obligations under applicable data protection
											laws.</p>
										<p className="home-content">We only retain your personal data for a limited period and for no longer than is necessary
											for the purpose for which we are processing it for (including purposes necessary to comply
											with our legal or regulatory obligations, resolve disputes, and enforce our agreements.)</p>
										<p className="home-content">You  hold  certain  legal  rights  in  relation  to  your  personal  data  with  our  database.  If  you
											have any concerns or queries about this notice or on how we process your personal data,
											please contact us <a href="info@theom.ca ">info@theom.ca </a></p>

									</li>
									<li>
										<h3>3. We may display your information like your name and business name publicly on your
											profile upon your consent </h3>
										<p className="home-content">We  may  display  information  related  to  your  business,  including  your  name  or  your
											business’s name, publicly on your profile at our platform, except to the extent we specify
											that we will not display such information. If the information provided during sign up for
											your account differs from the information you provide us as part of verification data that
											we  collect,  we  have  sole  discretion  in  determining  which  information  we  will  display
											publicly on your profile. For instance; if you are a service provider at Theom Technologies
											Inc,  then  we  will  have  your  profile  shown  to  (but  not  limited  to)  your  prospective
											customers. On the contrary; if you are a customer, we will have your information like (but
											not limited to) name address and contact information with the service provider that you are
											booking  your  next  appointment  with.  We  may  also  combine  all  the  information  derived
											from your sign up and from your verifications and/or any affiliated or non-affiliated third-
											party platforms and any other platform or service we provide. We may aggregate and/or
											de-identify information collected through various platforms. We may use de-identified or
											aggregated  data  for  any  purpose  including  but  not  limited  to  research  and  marketing
											purpose  and  may  also  share  such  data  with  ay  third  party  including  but  not  limited  to
											advertisers, promotional partners, sponsors, event promoters and/or others. </p>
										<p className="home-content">Please be advised that some of your information will be publicly accessible. For instance,
											you sign in through Google, we hold the rights to access your full name  and DOB from
											your social media platforms.</p>

									</li>
									<li>
										<h3>4. Online analytics and tailored advertising </h3>
										<p className="home-content">We may use third party web analytics services on the Platform, such as those of Google
											Analytics. These service providers use the sort of technology described in the Information
											that is passively collected to analyze how users use the Platform, including the third-party
											website  from  which  the  information  is  collected.  The  information  collected  by  the
											technology will be disclosed to or collected directly by these service providers, who use
											this information. </p>
										<p className="home-content"><strong>Tailored  Advertising </strong> –  Third  parties  whose  products  or  services  are  accessible  or
											marketed via the Platform may also place cookies or other tracking technologies on your
											computer,  mobile  phone  or  other  devices  with  an  active  internet  access  to  collect
											information  about  your  use  of  the  Platform  in  order  to  (a)  inform,  optimize,  and  serve
											marketing content based on past visits to our website and other online platforms and (b)
											report  how  our  marketing  content  impressions,  other  users  of  marketing  services,  and
											interactions with these marketing impressions and services relate to visits on our website
											and/or affect the type of content you view at Theom.ca . We do allow third party services
											to  serve  tailored  marketing  to  you  and  to  access  their  own  cookies  and  other  tracking
											technologies on your computer, mobile phone, or any other device with and active access
											to internet services.</p>

									</li>
									<li>
										<h3>5. Business transfer/Acquisition/Merger</h3>
										<p className="home-content">In case we decide on selling the company; we have rights to transfer all the information in
											our data base with the new owner and will transfer all the data handling responsibilities as
											well. </p>
									</li>
									<li>
										<h3>6. Information collected from prospective employees and how is it handled </h3>
										<p className="home-content">With prospective times of hiring external part time and full-time employees (including but
											not limited to job applicants); we do hold rights of storing and processing information of
											our  prospective  part  time  and  full-time  employees  working  at  Theom  Technologies  Inc.
											their legal and personal information will be collected and stored in the company data base
											making  sure  about  the  safety  of  the  respective  data  and  coming  along  with  all  the  legal
											limits  and  regulations.  We  do  hold  the  rights  of  sharing  the  information  with  legal
											departments for (not limited to) background checks and verifications.</p>
									</li>
									<li>
										<h3>7. Use of Cookies</h3>
										<p className="home-content">A cookie is a tiny element of data that the Theom.ca can send to your browser, which may
											then be stored on your web browser or mobile device so we can recognize you when you
											return. The information that cookies collect includes the date and time of your visit, your
											registration information, session identification number, and your navigational history and
											preferences.</p>
										<p className="home-content">Complying  with  the  industry  standards;  we  use  these  cookies  for  analytical  purposes  on
											our platform with all the major browsers and smartphones initially set to  accept them on
											your behalf and are subject to change upon user’s consent in the settings of their browser
											and/or smartphone.</p>
										<p className="home-content">You may set your web browser or smartphone to notify you when you receive a cookie or
											to  not  accept  certain  cookies.  However,  if  you  decide  not  to  accept  cookies  from  the
											theom.ca, you may not be able to take advantage of all its features. </p>


									</li>
									<li>
										<h3>8. Disclosure of your information by THEOM </h3>
										<p className="home-content">We  hold  all  the  rights  of  disclosing  your  information  with  (not  limited  to)  the  legal  and
											government authorities whenever and however requested. </p>

									</li>
									<li>
										<h3>9. Changes to this policy </h3>
										<p className="home-content">This  policy  is  current  as  of  the  effective  date  set  forth  above,  Theom  Technologies  Inc.
											may, in its sole and absolute discretion, change this policy at any time. Theom will post its
											updated  policy  on  the  Platform,  send  you  a  message  or  otherwise  notify  you  30  days  in
											advance and when you are logged into your account. Theom encourages you to review this
											policy regularly for any changes, your continued access to or use of the Platform will be
											subject to the terms of the then current policy.</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</>
	)
}

export default PrivacyPolicies;