// MyComponent.js
import React from 'react';
import { API } from "../backend";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const emailRegex = RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);
const formValid = ({ formErrors, ...rest }) => {
  let valid = true;
  if (formErrors) {
    Object.values(formErrors).forEach(val => {
      val && val.length > 0 && (valid = false);
    });
  }
  // validate the form was filled out
  Object.values(rest).forEach(val => {
    val === null && val.length > 0 && (valid = false);
  });

  return valid;
}
class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      show: true,
      redirect: false,
      redirectTo: "",
      remember_me: '',
      formErrors: {
        username: "",
        password: "",
        auth: ''
      }
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleClose2 = this.handleClose2.bind(this);
    this.getCookie = this.getCookie.bind(this);
  }
  redirect() {
    if (this.state.redirect)
      return <Redirect to={this.state.redirectTo} />
  }

  handleClose(event) {
    document.cookie = "myusername=" + this.state.username + ";path=http://localhost:3000";
    document.cookie = "myuserpass=" + this.state.password + ";path=http://localhost:3000";
  }
  handleClose2(event) {
    let usname = this.getCookie("myusername")
    let pass = this.getCookie("myuserpass")
    if (usname && pass) {
      this.state.username = usname
      this.state.password = pass
    }

  }
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  handleSubmit = e => {
    e.preventDefault();
    let formErrors = this.state.formErrors;
    if (formValid(this.state.formErrors)) {
      let user = { email: this.state.username.toLowerCase(), password: this.state.password };
      return fetch(`${API}/user/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
      })
        .then(response => response.json().then(data => {
          if (response.status == 200) {
            // toast.success("Login Successful")
            if (data.user.isAdmin) {
              sessionStorage.setItem("adminData", JSON.stringify(data.user))
              sessionStorage.setItem("adminData", JSON.stringify(data.token))
              this.setState({
                redirect: true
              })
              this.setState({
                redirectTo: {
                  pathname: "/newAdmin",
                  state: { id: data.user }
                }
              })
            }
            else if (!data.user.isUser && data.user.status) {
              sessionStorage.setItem("providerData", JSON.stringify(data.user))
              sessionStorage.setItem("providerDatatoken", JSON.stringify(data.token))
              this.setState({
                redirect: true
              })
              this.setState({
                redirectTo: {
                  pathname: "/serviceprovider/dashboard",
                  state: { id: data.user }
                }
              })
            }
            else if (!data.user.isUser && !data.user.status) {
              sessionStorage.setItem("providerData", JSON.stringify(data.user))
              sessionStorage.setItem("providerDatatoken", JSON.stringify(data.token))
              this.setState({
                redirect: true
              })
              this.setState({
                redirectTo: "/waiting_for_response"
              })
            }
            else {
              localStorage.setItem("userid", data.user._id)
              sessionStorage.setItem("costumer", JSON.stringify(data.user))
              sessionStorage.setItem("token", JSON.stringify(data.token))
              setTimeout(function () { window.location.reload(false); }, 1000);
            }
          }
          else {
            formErrors.auth = "Invalid username or password";
            this.setState({ formErrors });
          }
        })
        )
        .catch(err => toast.error("Ooops!! Something went wrong."));
    } else {
      toast.error("Invalid Credentials");

    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;

    switch (name) {
      case 'username':

        formErrors.username =
          emailRegex.test(value)
            ? ""
            : "invalid username";
        //   value.length < 3 && value.length > 0 
        //     ? 'Minimum 3 characters required' 
        //     : "";
        break;
      case 'password':
        formErrors.password =
          value.length < 6
            ? "Minimum 6 characters required"
            : "";
        break;
      default:
        break;
    }
    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });

    this.setState({ formErrors, [name]: value });
  }


  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  render(props) {
    const { formErrors } = this.state;
    { this.handleClose2() }

    return (

      <div className="wrapper">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
        <div className="form-wrapper">
          <h2>Login</h2>
          <form onSubmit={this.handleSubmit}>
            {formErrors.auth.length > 0 && (
              <span className="errorMessage">{formErrors.auth}</span>
            )}
            <div className="username">
              <input
                type="text"
                name="username"
                className={formErrors.username.length > 0 ? "error" : "input_user"}
                noValidate
                onChange={this.handleChange}
                placeholder="Username"
                required
                defaultValue={this.state.username}
              />
              {formErrors.username.length > 0 && (
                <span className="errorMessage">{formErrors.username}</span>
              )}
            </div>
            <div className="password">
              <input
                type="password"
                name="password"
                className={formErrors.password.length > 0 ? "error" : "input_pass"}
                noValidate
                onChange={this.handleChange}
                placeholder="Password"
                required
                defaultValue={this.state.password}
              />
              {formErrors.password.length > 0 && (
                <span className="errorMessage">{formErrors.password}</span>
              )}
            </div>
            <div className="checkbox ml-4">
              <input
                type="checkbox"
                onClick={this.handleClose}
                name="remember_me"
                id="customControlInline"
              />
              <label for="customControlInline">Remember me</label>
            </div>
            <div className="login_container">
              <button type="submit" name="button" id="formclick" className="btn login_btn">Login</button>
            </div>
          </form>
          {this.props.btnn}
        </div>
        {this.redirect()}
      </div>

    );
  }
}
export default LoginForm;