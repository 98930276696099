import React from 'react'
import Login from '../components/Login'
import Signup from '../components/Signup'
import Joinas from './Joinas'
import Image from 'react-bootstrap/Image'
import headerlogo from '../assets/homepage/header-logo.png'
import usericon from '../assets/homepage/images.png'
import { Link } from 'react-router-dom'
import '../Style.css'
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Redirect } from "react-router-dom";
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';


export default function Header() {
  const [headercheck, setheadercheck] = React.useState(null)
  const [count, setcount] = React.useState(false);
  const providerData = localStorage.getItem("userid")
  const [orderdata, setorderdata] = React.useState([])
  const [tableDatafix, setTableDatafix] = React.useState([]);
  React.useEffect(() => {
    setheadercheck(sessionStorage.getItem('token'))
  }, [])


  React.useEffect(() => {
    setTimeout(function () { topFunction2() }, 100);
    async function providerDetails() {
      if (!count) {

        setcount(true)
        fetch(`${API}/user/getUserOrder/` + providerData,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
          .then(response => response.json().then(data => {
            if (response.status == 200) {
              data.data.map((filtr) => {
                if (filtr.isUser == true) {
                  orderdata.push(filtr)

                }
              })
              setTableDatafix(data.data)
            }
          })
          )
          .catch(err => toast.error("Ooops!! Something went wrong."));
      }
    }
    if (headercheck != null) {
      providerDetails()
    }
  }, [headercheck, orderdata, count, tableDatafix])

  const topFunction2 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const logout = () => {
    sessionStorage.clear("token")
    localStorage.clear()
    window.location.replace("/")
  }
  let check = 0
  const down = () => {
    if (check == 0) {
      document.getElementById("navbarNav").classList.add("show");
      check = 1
    } else {
      check = 0
      document.getElementById("navbarNav").classList.remove("show");
    }
  }
  return (
    <>
      <header className="Homepage-header" id="headr">
        <div className="container">
          <div className="row desktopheader mt-3">
            <div className="col-md-7">
              <Link to="/"	><Image title="THEOM" src={headerlogo} fluid /></Link>
            </div>
            <div className="topBarMenu col-md-5">
              <ul id="header-menu">
                <li>

                </li>
                <li className="nav-item dropdown dropdone">
                  {headercheck ?
                    <>
                      <Link to="#" >
                        <NotificationsIcon className="notif" /><span className="badge badge-warning admin-navbar-notification-icon navbar-badge costmr">{orderdata.length}</span>
                      </Link>
                      {orderdata.length > 0 ?
                        <div className="dropdown2 costumernot">
                          <h2>Notifications</h2>
                          <ul className="">
                            {
                              orderdata.map((noty) => {
                                return (
                                  <li>
                                    <span>
                                      <img src={noty.professionalId.professionalImage ? `${API}/files/${noty.professionalId.professionalImage}` : usericon} class="makeStyles-profile-3 img-fluid" />
                                    </span>
                                    <span>
                                      <Link to="/orders" style={{ paddingRight: 5 }}>
                                        <strong>{noty.professionalId.first_name + ' ' + noty.professionalId.last_name}</strong>
                                      </Link>
                                      {noty.status} your <strong>{noty.orderID}</strong> order.</span>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div> : ''}
                    </>
                    : <Login />}
                </li>
                <li>
                  {headercheck ? <Link className="orders btn btn-primary" to="/orders">Orders</Link> : <Signup />}
                </li>
                <li>
                  <Link to="/serviceprovider"><Joinas /></Link>
                </li>
                {headercheck ? <li>
                  <Link style={{ display: "block", width: "58px" }}>
                    <img src={usericon} class="makeStyles-profile-3 img-fluid" />
                    <i class="fa fa-angle-down" aria-hidden="true" style={{ position: "relative", color: "#666",paddingLeft:"5px" }}></i>
                    <div className="costmre">
                      <ul>
                        <li><Link to="/profile">My Profile</Link></li>
                        <li><Link to="/profile" onClick={e => logout()}>Logout</Link></li>
                      </ul>
                    </div>
                  </Link>
                </li> : ''}

              </ul>
            </div>
          </div>
        </div>
      </header>
      <nav className="navbar-homepage navbar-expand-lg navbar-light">
        <Link to="/"><Image title="THEOM" src={headerlogo} fluid /></Link>
        <button class="navbar-toggler" type="button" onClick={e => down()}>
          <span class="navbar-toggler-icon"></span>
        </button>
        {headercheck ?
          <div class="mobilenoty nav-item dropdown dropdone" style={{ float: "right", position: "relative", padding: "9px 0 20px" }}>
            <Link to="#" >
              <NotificationsIcon className="notif" /><span className="badge badge-warning admin-navbar-notification-icon navbar-badge costmr">{orderdata.length}</span>
            </Link>
            {orderdata.length > 0 ?
              <div className="dropdown2 costumernot">
                <h2>Notifications</h2>
                <ul className="">
                  {
                    orderdata.map((noty) => {
                      return (
                        <li>
                          <span>
                            <img src={noty.professionalId.professionalImage ? `${API}/files/${noty.professionalId.professionalImage}` : usericon} class="makeStyles-profile-3 img-fluid" />
                          </span>
                          <span>
                            <Link to="/orders" style={{ paddingRight: 5 }}>
                              <strong>{noty.professionalId.first_name + ' ' + noty.professionalId.last_name}</strong>
                            </Link>
                            {noty.status} your <strong>{noty.orderID}</strong> order.</span>
                        </li>
                      )
                    })
                  }
                </ul>
              </div> : ''}
          </div>
          : ""}


        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mr-auto" style={{ width: "100%" }}>
            <li className="nav-item active">
              {headercheck ?
                ""
                : <Login />}
            </li>
            <li className="nav-item">
              {headercheck ? <Link className="orders btn btn-primary" style={{ width: "100%" }} to="/orders">Orders</Link> : <Signup />}
            </li>
            <li className="nav-item">
              <Link style={{ width: "100%" }} to="/serviceprovider"><Joinas /></Link>
            </li>
            {headercheck ?
              <li className="nav-item"><Link to="/profile" className="orders btn btn-primary" style={{ width: "100%" }} onClick={e => logout()}>Logout</Link></li> : ""}
            {headercheck ?
              <li className="nav-item"><Link to="/profile" className="orders btn btn-primary" style={{ width: "100%" }} >Costumer Profile</Link></li> : ""}
          </ul>
        </div>
      </nav>
    </>
  );
}
