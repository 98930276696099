import React from 'react'
import Footer from './Footer'
import Header from '../homepage/Header'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import banner from '../assets/PricingPage/help center.png'

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <div className="accordion-wrapper">

      <div
        className={`accordion-title ${isOpen ? "open" : ""}`}
        onClick={() => setOpen(!isOpen)}
      >
        {title}
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  );
};



const NeedHelp = () => {

  const [helpdata, sethelpdata] = React.useState({})

  const help = () => {
    let data = {
      "name": helpdata.name,
      "email": helpdata.email,
      "question": helpdata.comment
    }
    fetch(`${API}/user/needHelp`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(result => {
        toast.success("Mail successful send")
      })
      .catch((err) => toast.error("Ooops!! Something went wrong."));
  }

  const datafill = (e) => {
    let ab = { ...helpdata, [e.target.name]: e.target.value }
    sethelpdata(ab)
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <Header />
      <div className="Banner mb-5">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <img class="pricingBanner" src={banner} alt="Responsive image" />
            <div class="bannerText">
              <h1 class="secheading mt-5 mb-5"><span class="bannerHeading">NEED HELP ?</span></h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row container resp">
          <div className="col-md-7 contentBorder  pt-3 pb-3">
            <div className="row container">
              <div className="col-sm-3 paddCss">
                <label>Name</label>
              </div>
              <div className="col-sm-9 paddCss">
                <input type="text" name="name" className="form-control" placeholder="Your Name" onChange={e => datafill(e)} />
              </div>
              <div className="col-sm-3 paddCss">
                <label>Email</label>
              </div>
              <div className="col-sm-9 paddCss">
                <input type="email" className="form-control" name="email" placeholder="" onChange={e => datafill(e)} />
              </div>
              <div className="col-sm-3 paddCss">
                <label>Question</label>
              </div>
              <div className="col-sm-9 paddCss">
                <textarea className="form-control paddCss" name="comment" rows="6" cols="50" onChange={e => datafill(e)}></textarea>
              </div>
              <div className="col-sm-3 paddCss">
              </div>
              <div className="col-sm-4 paddCss">
                <button className="button btn1 ml-1 send" onClick={e => helpdata.email && helpdata.name && helpdata.comment ? help() : toast.error("All field are required")} >Send</button>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-3">
            <div className="row">
              <div className="col-sm-12 contentBorder" style={{ paddingRight: 0, paddingLeft: 0 }}>
                <div className="col hourStyle">
                  <p className="helpLine">Helpline</p>
                </div>
                <div>
                  <p className="helpNunber"><svg height="20" viewBox="0 0 58 58" width="20" xmlns="http://www.w3.org/2000/svg"><g id="Page-1" fill="none" fill-rule="evenodd"><g id="003---Call" fill="#2b5ea6" fill-rule="nonzero" transform="translate(-1)"><path id="Shape" d="m25.017 33.983c-5.536-5.536-6.786-11.072-7.068-13.29-.0787994-.6132828.1322481-1.2283144.571-1.664l4.48-4.478c.6590136-.6586066.7759629-1.685024.282-2.475l-7.133-11.076c-.5464837-.87475134-1.6685624-1.19045777-2.591-.729l-11.451 5.393c-.74594117.367308-1.18469338 1.15985405-1.1 1.987.6 5.7 3.085 19.712 16.855 33.483s27.78 16.255 33.483 16.855c.827146.0846934 1.619692-.3540588 1.987-1.1l5.393-11.451c.4597307-.9204474.146114-2.0395184-.725-2.587l-11.076-7.131c-.7895259-.4944789-1.8158967-.3783642-2.475.28l-4.478 4.48c-.4356856.4387519-1.0507172.6497994-1.664.571-2.218-.282-7.754-1.532-13.29-7.068z" /><path id="Shape" d="m47 31c-1.1045695 0-2-.8954305-2-2-.0093685-8.2803876-6.7196124-14.9906315-15-15-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2c10.4886126.0115735 18.9884265 8.5113874 19 19 0 1.1045695-.8954305 2-2 2z" /><path id="Shape" d="m57 31c-1.1045695 0-2-.8954305-2-2-.0154309-13.800722-11.199278-24.9845691-25-25-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2c16.008947.01763587 28.9823641 12.991053 29 29 0 .530433-.2107137 1.0391408-.5857864 1.4142136-.3750728.3750727-.8837806.5857864-1.4142136.5857864z" /></g></g></svg> 001122223444</p>
                  <p className="helpNunber"><svg height="20" viewBox="0 0 58 58" width="20" xmlns="http://www.w3.org/2000/svg"><g id="Page-1" fill="none" fill-rule="evenodd"><g id="003---Call" fill="#2b5ea6" fill-rule="nonzero" transform="translate(-1)"><path id="Shape" d="m25.017 33.983c-5.536-5.536-6.786-11.072-7.068-13.29-.0787994-.6132828.1322481-1.2283144.571-1.664l4.48-4.478c.6590136-.6586066.7759629-1.685024.282-2.475l-7.133-11.076c-.5464837-.87475134-1.6685624-1.19045777-2.591-.729l-11.451 5.393c-.74594117.367308-1.18469338 1.15985405-1.1 1.987.6 5.7 3.085 19.712 16.855 33.483s27.78 16.255 33.483 16.855c.827146.0846934 1.619692-.3540588 1.987-1.1l5.393-11.451c.4597307-.9204474.146114-2.0395184-.725-2.587l-11.076-7.131c-.7895259-.4944789-1.8158967-.3783642-2.475.28l-4.478 4.48c-.4356856.4387519-1.0507172.6497994-1.664.571-2.218-.282-7.754-1.532-13.29-7.068z" /><path id="Shape" d="m47 31c-1.1045695 0-2-.8954305-2-2-.0093685-8.2803876-6.7196124-14.9906315-15-15-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2c10.4886126.0115735 18.9884265 8.5113874 19 19 0 1.1045695-.8954305 2-2 2z" /><path id="Shape" d="m57 31c-1.1045695 0-2-.8954305-2-2-.0154309-13.800722-11.199278-24.9845691-25-25-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2c16.008947.01763587 28.9823641 12.991053 29 29 0 .530433-.2107137 1.0391408-.5857864 1.4142136-.3750728.3750727-.8837806.5857864-1.4142136.5857864z" /></g></g></svg>
                    001122223444</p>
                </div>
              </div>
              <div className="col-sm-12 mt-2 contentBorder mt-4" style={{ paddingRight: 0, paddingLeft: 0 }}>
                <div className="col hourStyle">
                  <p className="helpLine">Working hours</p>
                </div>
                <div>
                  <p className="helpNunber">9 am-11 am (CST) </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 mt-5 mb-5 row">
            <h3>Customer FAQ: </h3>
            <div className="Wrapper">
              <Accordion title="Q1 How can you resolve issues: ">
                We understand that there may be unprecedent times where you may encounter disagreements  with the professional you have hired in terms of availability, work and pricing. We would want to let you know that we are always here to assist you with any problems that may occur. You can either reach out to us via live chat, phone call or email us at <a href="support@theom.ca">support@theom.ca</a>
              </Accordion>
              <Accordion title="Q2 How to contact your professional? ">
                We would like to inform you that we take privacy very seriously which is why we do not share contact information until the order is placed. If you have any questions for professional before you are to place order, you can reach out to us freely and we will communicate your questions to the professional. Once the order is placed, you can speak to hired professional via text, call or email.
              </Accordion>
              <Accordion title="Q3 How can you pay your professional? ">
                We would suggest you sign an agreement with the professional before placing the order. Once the order is completed, you can pay the professional decided amount as per the agreement with any payment method you prefer.
              </Accordion>
              <Accordion title="Q4 Need changes to the original request? ">
                You can reach out to us for any changes you require at least 48 hours prior, and we will try our best to make the necessary adjustments for you. If you are to cancel the order, there may be a penalty fee charged if the cancellation request is after 48 hours.
              </Accordion>
            </div>
          </div>
          <div className="col-md-7 mt-5 mb-5 row">
            <h3>Professional FAQ:  </h3>
            <div className="Wrapper">
              <Accordion title="Q1 What are theom points?">
                Theom points are equivalent monetary points that can be used to view a customer’s profile and contact information once they send you an order request. The points are deducted automatically as you choose to view the order details. The number of points deducted varies as per the original order amount.
              </Accordion>
              <Accordion title="Q2 How to buy theom points?  ">
                To view the details of the order, you will have to use the theom points which can be bought using your theom portal. We have different packages of points you can choose from and if you want to buy points individually the cost is CAD $1 for 1 theom point. These points can be bought using Visa, Mastercard and _____
              </Accordion>
              <Accordion title="Q3 How availability works? ">
                The benefit of working with us is that you are your own boss, meaning you can choose the number of hours you would like to work and put your availability according to your free will. The availability can be set in your theom portal. You can put in the hours for each day of the week you would like to work. We suggest putting an availability of at least 3 weeks in advance such that the customers can choose to place the order with you.
              </Accordion>
              <Accordion title="Q4 How refund policy works? ">
                The customer has a freedom of sending the request to three different professionals. If the customer decided to go with another professional and you have used your theom points to view the details of the order, we will process a refund of the theom points used back to your theom profile within 24 hours.
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default NeedHelp