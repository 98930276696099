import React, { useState } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from "react";
import saveIcon from '../assets/Icons/save.png';
import { CasinoOutlined } from '@material-ui/icons';
import { Modal, Container, Row } from "react-bootstrap";

export default function Pending() {
  const [component, setComponent] = useState('dashboard')
  const [color1, setColor1] = useState('#EA4337')
  const [color2, setColor2] = useState('#ccc')
  const [color3, setColor3] = useState('#EA4337')
  const [color4, setColor4] = useState('')
  const [color5, setColor5] = useState('#ccc')
  const [color6, setColor6] = useState('')
  const [color7, setColor7] = useState('#ccc')
  const [showPopup, setPopup] = useState(false);
  const handleCloseSignupForm = () => {
    setPopup(false)
  };

  const [display1, setDisplay1] = useState()
  const [display2, setDisplay2] = useState('none')
  const [display3, setDisplay3] = useState('none')
  const [display4, setDisplay4] = useState('none')
  const [count, setcount] = useState(false);
  const providerData = JSON.parse(sessionStorage.getItem("providerData"))
  const [orderdata, setorderdata] = useState([])
  const [refresh2, setrefresh2] = React.useState([])
  const [redirect, setredirect] = React.useState('')
  const [statussetdata, setstatusset] = React.useState('')
  const [pid, sepid] = React.useState(0)
  const topFunction2 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  useEffect(() => {
    setTimeout(function () { topFunction2() }, 100);
    if (!count) {
      document.getElementById("loder").style.display = "block"
      setcount(true)
      fetch(`${API}/user/getProfessionalOrder/` + providerData._id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
        .then(response => response.json().then(data => {
          document.getElementById("loder").style.display = "none"
          if (response.status == 200) {


            data.data.map((x, index) => {
              x.professionalId.map((y, index) => {
                if (y.professionalId._id == providerData._id) {
                  x.professionalId = [y]
                  setorderdata(data.data)
                }
              })
            })
          }
          setrefresh2(data.data)
        })
        )
        .catch(err => toast.error("Ooops!! Something went wrong."));
    }
  }, [display1, display2, display3, display4, refresh2, statussetdata]);

  const seennotification = (id) => {
    let dataapi = [{
      "bookingId": id,
      "isProfessional": false
    }]
    fetch(`${API}/admin/updateNotification`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(dataapi)
    })
      .then(response => response.json().then(data => {
        if (response.status == 200) {

        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }

  const statusset = (id, pid) => {
    setredirect(id)
    if (statussetdata == "") {
      toast.error("Please select status")
    }
    else if (statussetdata != "Accepted") {
      let dataapi = {
        "_id": id,
        "status": statussetdata,
        "professionalId": pid
      }
      fetch(`${API}/user/UpdateStatus`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataapi)
      })
        .then(response => response.json().then(data => {
          if (response.status == 200) {
            toast.success("Successful")

          }
        })
        )
        .catch(err => toast.error("Ooops!! Something went wrong."));
      setcount(false)
      setrefresh2([])
      setorderdata([])
    } else {
      pointcut("point")
    }
  }

  const acceptbooking = () => {
    let dataapi = {
      "_id": redirect,
      "status": statussetdata,
      "professionalId": pid.id
    }
    fetch(`${API}/user/UpdateStatus`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(dataapi)
    })
      .then(response => response.json().then(data => {
        if (response.status == 200) {
          toast.success("Successful")
          setcount(false)
          setrefresh2([])
          setorderdata([])
          noneqsn("point")
          seennotification(redirect)
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }

  const clicked = () => {
    let dataapi = {
      "professionalId": pid.id,
      "totalPoint": pid.point,
      "paymentStatus": "redeemed"
    }
    fetch(`${API}/professional/transaction`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(dataapi)
    })
      .then(response => response.json().then(data => {
        if (data.result) {
          // toast.success("Successful")
          acceptbooking()
        } else {
          setPopup(true)
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }



  const locals = (data, y) => {
    data.professionalId = [y]
    localStorage.setItem(
      'professioanlclick', JSON.stringify(data)
    )
  }
  const noneqsn = (e) => {
    let questionpop = document.getElementById(e).style.display = "none"
  }

  const pointcut = (e) => {
    let questionpop = document.getElementById(e).style.display = "block"
  }
  return (
    <div className="container" style={{ minHeight: "400px" }}>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div class="loder" id="loder">
        <div class="wrap">
          <div class="loading">
            <div class="bounceball"></div>
            <div class="text">NOW LOADING</div>
          </div>
        </div>
      </div>
      <div className="row-width row mt-5">
        <div className="col-xs-2 col-4 setbtn col-md-2 col-lg-2" style={{ color: color1, boderBottomColor: color1, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid', textAlign: 'center' }}>
          <button className="btn button activeclass pastclass" style={{ color: color3, paddingLeft: 0, paddingRight: 0 }} onClick={() => { setColor1('#EA4337'); setColor2('#ccc'); setDisplay1('block'); setDisplay2('none'); setDisplay3('none'); setColor4(''); setColor5('#ccc'); setColor3('#EA4337'); setColor6(''); setColor7(''); setcount(false) }}>New Request</button>
        </div>
        <div className="col-xs-2 col-4 setbtn col-md-2 col-lg-2" style={{ color: color2, boderBottomColor: color2, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid ', textAlign: 'center' }}>
          <button className="btn button pastclass" style={{ color: color4 }} onClick={() => { setColor1('#ccc'); setColor2('#EA4337'); setDisplay1('none'); setDisplay2('block'); setDisplay3('none'); setColor3(''); setColor5('#ccc'); setColor4('#EA4337'); setColor6(''); setColor7(''); setcount(false) }}>Accepted</button>
        </div>

        <div className="col-xs-2 col-4 setbtn col-md-2 col-lg-2" style={{ color: color5, boderBottomColor: color5, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid ', textAlign: 'center' }}>
          <button className="btn button pastclass" style={{ color: color6 }} onClick={() => { setColor1('#ccc'); setColor2('#ccc'); setDisplay1('none'); setDisplay2('none'); setColor3(''); setColor4(''); setColor5('#EA4337'); setColor6('#EA4337'); setDisplay3('block'); setColor7(''); setcount(false) }}>Rejected</button>
        </div>
      </div>
      <hr className="activepastline"></hr>
      <div className="table-responsive" style={{ display: display1 }}>
        <Modal
          show={showPopup}
          id="questionForm"
          onHide={handleCloseSignupForm}
          size="lg"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><strong>{"Order Summary"}</strong></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <div class="popupprovider questionpopup successtab">
                  <p>You do not have sufficient points. Please buy more points.</p>
                </div>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>


        <div class="shadow" id="point" >
          <div class="popupprovider questionpopup successtab">
            <p>You will be charged {pid.point} points to accept the order request, do you wish to continue?</p>
            <div class="buttons" >
              <button class="buttonn preview" onClick={e => clicked()}> YES</button>
              <button class="buttonn next" onClick={e => noneqsn("point")}> NO</button>
            </div>
          </div>
        </div>
        <table className="table  table-bordered text-center tablefix">
          <thead>
            <tr className="table-heading">
              <th className="table-heading-first" scope="col">Order Number</th>
              <th scope="col" class="hidetable">Service</th>
              <th scope="col">Est. Amount</th>
              <th scope="col" class="hidetable">Time/Date</th>
              <th className="table-heading-last" scope="col">Actions</th>

            </tr>
          </thead>
          <tbody>
            {
              orderdata && orderdata.map((data, index) => {
                return (
                  data.professionalId.map((y, index2) => {
                    if (data.professionalId[0].status == "Pending") {
                      if (data.isProfessional == true) {
                        return (
                          <tr className="highlight">
                            <th scope="row"><Link to="/serviceprovider/order-summary" onClick={e => locals(data, y)}>{data.orderID}</Link></th>
                            <td class="hidetable">{data.subCategory}</td>
                            <td>{
                              "$" + data.totalAmount
                            }</td>
                            <td class="hidetable">{data.startTime + " - " + data.endTime} <br /> {(data.bookingDate.substr(data.bookingDate.lastIndexOf('\\') + 1).split('T')[0])}</td>
                            <td>
                              <form style={{ textAlign: "right" }}>
                                <select className="select-action" onChange={e => {
                                  setstatusset(e.target.value)
                                  sepid({ "id": data.professionalId[0].professionalId._id, "point": data.tokenPrice })
                                }}>
                                  <option value="">select</option>
                                  <option value="Accepted">Accept</option>
                                  <option value="Reject">Reject</option>
                                </select>
                                <Button onClick={e => statusset(data._id, data.professionalId[0].professionalId._id)} style={{ minWidth: "45px", padding: "6px 0" }}><img className="select-image img-fluid" src={saveIcon} />
                                </Button>
                                <Link to="/need-help" className="needhlp"><i class="fas fa-phone-alt"></i></Link>
                              </form>
                            </td>
                          </tr>
                        )
                      }
                      else {
                        return (
                          <tr>
                            <th scope="row"><Link to="/serviceprovider/order-summary"
                              onClick={e => locals(data, y)}> {data.orderID}
                            </Link></th>
                            <td class="hidetable">{data.subCategory}</td>
                            <td>{
                              "$" + data.totalAmount
                            }</td>
                            <td class="hidetable">{data.startTime + " - " + data.endTime} <br /> {(data.bookingDate.substr(data.bookingDate.lastIndexOf('\\') + 1).split('T')[0])}</td>
                            <td>
                              <form style={{ textAlign: "right" }}>
                                <select className="select-action" onChange={e => {
                                  setstatusset(e.target.value)
                                  sepid({ "id": data.professionalId[0].professionalId._id, "point": data.tokenPrice })
                                }}>
                                  <option value="">select</option>
                                  <option value="Accepted">Accept</option>
                                  <option value="Reject">Reject</option>
                                </select>
                                <Button onClick={e => statusset(data._id, data.professionalId[0].professionalId._id)} style={{ minWidth: "45px", padding: "6px 0" }} style={{ minWidth: "45px" }}><img className="select-image img-fluid" src={saveIcon} />
                                </Button>
                                <Link to="/need-help" className="needhlp"><i class="fas fa-phone-alt"></i></Link>
                              </form>
                            </td>
                          </tr>
                        )
                      }
                    }
                  })
                )
              })
            }
          </tbody>
        </table>
      </div>
      <div className="table-responsive" style={{ display: display2 }}>

        <table className="table  table-bordered text-center tablefix">
          <thead>
            <tr className="table-heading">
              <th className="table-heading-first" scope="col">Order Number</th>
              <th scope="col" class="hidetable">Service</th>
              <th scope="col">Est. Amount</th>
              <th scope="col" class="hidetable">Time/Date</th>
              <th className="table-heading-last" scope="col">Status</th>

            </tr>
          </thead>
          <tbody>
            {
              orderdata && orderdata.map((data, index) => {
                return (
                  data.professionalId.map((y, index2) => {
                    if (data.professionalId[0].status.toLowerCase() == "accepted") {
                      return (
                        <tr>
                          <th scope="row"><Link to="/serviceprovider/order-summary" onClick={e => locals(data, y)}>{data.orderID}</Link></th>
                          <td class="hidetable">{data.subCategory}</td>
                          <td>{
                            "$" + data.totalAmount
                          }</td>
                          <td class="hidetable">{data.startTime + " - " + data.endTime} <br /> {(data.bookingDate.substr(data.bookingDate.lastIndexOf('\\') + 1).split('T')[0])}</td>
                          <td>
                            <form style={{ textAlign: "center" }}>
                              {data.professionalId[0].status + " "}
                              <Link to="/need-help" className="needhlp"><i class="fas fa-phone-alt"></i></Link>
                            </form>
                          </td>
                        </tr>
                      )
                    }
                  })
                )
              })
            }
          </tbody>
        </table>
      </div>
      <div className="table-responsive" style={{ display: display3 }}>
        <table className="table  table-bordered text-center tablefix">
          <thead>
            <tr className="table-heading">
              <th className="table-heading-first" scope="col">Order Number</th>
              <th scope="col" class="hidetable">Service</th>
              <th scope="col">Est. Amount</th>
              <th scope="col" class="hidetable">Time/Date</th>
              <th className="table-heading-last" scope="col">Status</th>

            </tr>
          </thead>
          <tbody>
            {
              orderdata && orderdata.map((data, index) => {
                return (
                  data.professionalId.map((y, index2) => {
                    if (data.professionalId[0].status.toLowerCase() == "reject" || data.professionalId[0].status == "Cancelled") {
                      return (
                        <tr>
                          <th scope="row"><Link to="/serviceprovider/order-summary" onClick={e => locals(data, y)}>{data.orderID}</Link></th>
                          <td class="hidetable">{data.subCategory}</td>
                          <td>{
                            "$" + data.totalAmount
                          }</td>
                          <td class="hidetable">{data.startTime + " - " + data.endTime} <br /> {(data.bookingDate.substr(data.bookingDate.lastIndexOf('\\') + 1).split('T')[0])}</td>
                          <td>
                            <form style={{ textAlign: "center" }}>
                              {data.professionalId[0].status.toLowerCase() == "reject" ? data.professionalId[0].status + "ed " : data.professionalId[0].status + " "}

                              <Link to="/need-help" className="needhlp"><i class="fas fa-phone-alt"></i></Link>
                            </form>
                          </td>
                        </tr>
                      )
                    }
                  })
                )
              })
            }
            {/* {
              orderdata && orderdata.map((data, index) => {
                if (data.professionalId[0].status == "Cancelled") {
                  return (
                    <tr>
                      <th scope="row"><Link to="/serviceprovider/order-summary" onClick={e => locals(data)}>{data.orderID}</Link></th>
                      <td>{data.subCategory}</td>
                      <td>{
                        "$" + data.totalAmount
                      }</td>
                      <td>{data.startTime + " - " + data.endTime} <br /> {(data.bookingDate.substr(data.bookingDate.lastIndexOf('\\') + 1).split('T')[0])}</td>
                      <td>
                        <form style={{ textAlign: "right" }}>
                          {data.professionalId[0].status}

                          <Link to="/need-help" className="needhlp"><i class="fas fa-phone-alt"></i></Link>
                        </form>
                      </td>
                    </tr>
                  )
                }
              })
            } */}
          </tbody>
        </table>
      </div>
      <div class="col-12 mt-1 dtview">
        To view full slide right.
      </div>
    </div>
  );
}
