import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import categoryBanner from '../assets/AllServices/beauty-banner.jpg';
import { grey } from '@material-ui/core/colors';
import { Cancel } from '@material-ui/icons';
import { transparent } from 'material-ui/styles/colors';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Image from 'react-bootstrap/Image';
import headerlogo from '../assets/homepage/header-logo.png';
import NotificationsIcon from '@material-ui/icons/Notifications';
import usericon from '../assets/homepage/images.png'
import service5 from '../assets/homepage/service1.png';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        dashboard: {
            backgroundColor: "#353b48",
            marginTop: 85,
            paddingLeft: 20,
            color: 'grey',
            height: 583,

        },
        profile: {
            position: 'relative',
            width: 50,
            height: 50,
            borderRadius: 100
        },
        tooltip: {
            zIndex: 9999,
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        // appBar: {
        //   [theme.breakpoints.up('sm')]: {
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     marginLeft: drawerWidth,
        //   },
        // },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        center: {
        },
        notifications: {
            fontSize: 57,
            color: "#e8b702",
            paddingRight: 10
        },
        sidebarhover: {
            '&:hover': {
                backgroundColor: "rgb(0 0 0 / 18%)",
            },
        },
        profilehover: {
            paddingTop: 0,
            paddingBottom: 0,
            '&:hover': {
                backgroundColor: "#0b88b5",
                color: "#fff",
            },
        }

    }
    ),
);

const ProfessionaOrdersummary = (props) => {
    const [data, setdata] = React.useState(JSON.parse(localStorage.getItem("professioanlclick")))
    const [count, setcount] = React.useState(false);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const [orderdata, setorderdata] = useState([])
    const [component, setComponent] = useState('dashboard')
    const providerData = JSON.parse(sessionStorage.getItem("providerData"))
    const [dubq, setdubq] = React.useState([])



    const dublicate = () => {
        data.selectPrice.map((x) => {
            let answersfit = x.ansawer.substr(x.ansawer.lastIndexOf('\\') + 1).split('#')[0]
            let some = false
            dubq.map((y, index) => {
                console.log(y)
                if (x.question == y.question) {
                    some = true
                    y.ansawer = (y.ansawer + ' <br/> ' + answersfit)
                }
            })
            if (some == false) {
                dubq.push({ "question": x.question, "ansawer": answersfit })
            }

            console.log(dubq)
        })
    }
    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };


    const logout = () => {
        sessionStorage.clear("providerDatatoken")
        props.history.push(`/`)
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    React.useEffect(() => {
        if (!count) {
            dublicate()
            setcount(true)
            seennotification(data._id)
            let dataapi = [{
                "bookingId": data._id,
                "isUser": false
            }]
            fetch(`${API}/user/updateNotification`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(dataapi)
            })
                .then(response => response.json().then(data => {
                    if (response.status == 200) {
                    }
                })
                )
                .catch(err => toast.error("Ooops!! Something went wrong."));

            // 


            fetch(`${API}/user/getProfessionalOrder/` + providerData._id,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json().then(data => {
                    if (response.status == 200) {
                        let newdata = data.data.filter((filt) => filt.isProfessional == true)
                        setorderdata(newdata)
                    }
                })
                )
                .catch(err => toast.error("Ooops!! Something went wrong."));
        }

    }, [data])


    const seennotification = (id) => {
        let dataapi = [{
            "bookingId": id,
            "isProfessional": false
        }]
        fetch(`${API}/admin/updateNotification`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(dataapi)
        })
            .then(response => response.json().then(data => {
                if (response.status == 200) {
                }
            })
            )
            .catch(err => toast.error("Ooops!! Something went wrong."));
    }
    const document = (e) => {
        var formdata = new FormData();
        formdata.append("_id", data._id);
        formdata.append("image", e.target.files[0]);
        formdata.append("professionalId", data.professionalId[0].professionalId._id);
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${API}/professional/workDoneDocument`, requestOptions)
            .then(response => response.json())
            .then(result => {
                toast.success("Completion document has been uploaded")
            })
            .catch(error => toast.error('error', error));
    }



    return (
        <>

            <ToastContainer
                position="top-center"
                autoClose={20000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />

            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Grid container justify="center">
                        <Grid item xs={10} sm={10} md={10}>
                            <Link to="/serviceprovider/dashboard"	><Image title="THEOM" src={headerlogo} fluid /></Link>

                        </Grid>
                        <Grid item container xs={2} sm={2} md={2} alignItems="flex-end" direction="column">

                            <Grid item >
                                <div className="topBarMenu">
                                    <ul id="header-menu">
                                        <li className="nav-item dropdown dropdone">
                                            <Link to="#" >
                                                <NotificationsIcon className="notif" /><span className="badge badge-warning admin-navbar-notification-icon navbar-badge costmr">{orderdata.length}</span>
                                            </Link>
                                            {orderdata.length > 0 ?
                                                <div className="dropdown2">
                                                    <h2>Notifications</h2>
                                                    <ul className="">
                                                        {
                                                            orderdata.map((noty) => {
                                                                return (
                                                                    <li>
                                                                        <span>
                                                                            <img src={usericon} class="makeStyles-profile-3 img-fluid" />
                                                                        </span>
                                                                        <span>
                                                                            <button onClick={() => setComponent('pending')} style={{ paddingRight: 5 }} >
                                                                                <strong>{noty.userId.first_name + ' ' + noty.userId.last_name}</strong>
                                                                            </button>
                                                                            place new order please check.</span>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div> : ''}
                                        </li>
                                        <li><Link to="#" onClick={handleClick('bottom')}><Image className={classes.profile} src={providerData.professionalImage == '' || providerData.professionalImage == null ? service5 : `${API}/files/${providerData.professionalImage}`} fluid /> <i class="fa fa-angle-down" aria-hidden="true"></i></Link></li>
                                    </ul>
                                </div>


                            </Grid>

                        </Grid>
                    </Grid>




                </Toolbar>

                <Popper className={classes.tooltip} open={open} anchorEl={anchorEl} placement={placement} transition>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                <List >
                                    <ListItem className={classes.profilehover} key="name">
                                        <ListItemText className={classes.center} primary={providerData ? providerData.first_name + ' ' + providerData.last_name : ""} style={{ textTransform: 'uppercase' }} />
                                    </ListItem>

                                    {/* <ListItem button className={classes.profilehover} key="profile" onClick={() => setComponent('profile')}>
                                        <ListItemText className={classes.center} primary="Personal Settings" onClick={handleClick('bottom')} />
                                    </ListItem> */}

                                    {/* <ListItem className={classes.profilehover} button key="account_setting"  onClick={() => setComponent('account_setting')}>
            <ListItemText  className={classes.center} primary="Account Settings" />
          </ListItem> */}

                                    <ListItem className={classes.profilehover} button key="logout">
                                        <ListItemText className={classes.center} primary="Logout" onClick={(e) => logout()} />
                                    </ListItem>
                                </List>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </AppBar>


            <div className="container" style={{ marginTop: "100px" }}>
                <div className="row conatiner mt-5 pt-2" >
                    <div className="col-sm-6 col-md-6 col-lg-6 btnset">
                        <h1 className="secheading"><span className="heading-1">ORDER</span>&nbsp;&nbsp;<span className="heading-2">SUMMARY</span></h1>
                    </div>
                    <div className="col-sm-1 col-md-1 col-lg-1 text-right">
                    </div>
                    <div className="col-sm-2 col-md-2 col-lg-2 text-right btnset col-6" style={{ position: "relative" }}>
                        {

                            data.professionalId[0].status == "Accepted" ?
                                <>
                                    <a className="cancel-Post"><button type="button" id="cancelPost" className="btn btn-primary" onClick={e => { props.history.push(`/serviceprovider/dashboard`) }} style={{ width: "130px" }}>Document</button></a>
                                    <input type="file" onChange={e => document(e)} className="fileset" />
                                </>
                                : ""
                        }
                    </div>
                    <div className="col-sm-2 col-md-2 col-lg-2 text-left btnset col-6">
                        <Link to="/serviceprovider/dashboard" className="cancel-Post" onClick={e => localStorage.setItem("redirect", 'pending')}><button type="button" id="cancelPost" className="btn btn-primary" style={{ width: "130px" }}>Back</button></Link>
                    </div>
                </div>
                <div className="row col-sm-10 mt-3 container mb-5 ordersmr" style={{ backgroundColor: "#eeeeee4d", padding: 40, marginLeft: 92, color: "#666" }}>
                    <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-1">
                        <h5 className="col-sm-12 row" style={{ color: "#000", fontWeight: "700" }}>Costumer Details</h5>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="mt-4   col-6">Order Number</span><span className="mt-4  float-right col-6">{data.orderID}</span><br></br>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="mt-4  col-6">Name </span><span className="mt-4  float-right col-6">{data.userId.first_name}  {data.professionalId[0].status == "Accepted" ? data.userId.last_name : " ********"}</span><br></br>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="mt-4  col-6">Email</span><span className="mt-4  float-right col-6">
                            {
                                data.professionalId[0].status == "Accepted" ? data.userId.email :
                                    data.userId.email.replace(/(.{2})(.*)(?=@)/,
                                        function (gp1, gp2, gp3) {
                                            for (let i = 0; i < gp3.length; i++) {
                                                gp2 += "*";
                                            } return gp2;
                                        })
                            }

                        </span><br></br>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="mt-4  col-6">Mobile Number</span><span className="mt-4  float-right col-6">{data.professionalId[0].status == "Accepted" ? data.userId.mobile_number :
                            data.userId.mobile_number.replace(/.(?=.{4})/g, 'x')
                        }</span><br></br>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="mt-4  col-6">Order Status</span><span className="mt-4 float-right col-6">{data.professionalId[0].status}</span><br></br>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="mt-4  col-6">Service Name</span><span className="mt-4  float-right col-6">{data.subCategory ? data.subCategory : ""}</span><br></br>
                    </div>
                    {/* <div className="col-sm-12 col-md-12 col-lg-12">
                        <span className="mt-4 mb-4 mr-5">Order Type</span><span className="mt-4 mb-4 ml-5 float-right">{data.subCategory ? data.subCategory : ""}</span><br></br>
                    </div> */}
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="mt-4  col-6">Order Date & Time</span><span className="mt-4  float-right col-6">{(data.bookingDate.substr(data.bookingDate.lastIndexOf('\\') + 1).split('T')[0]) + " | " + data.startTime + ' - ' + data.endTime}</span><br></br>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="mt-4  col-6">Postal Code</span><span className="mt-4  float-right col-6">{data.userId.postal_code}</span><br></br>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="mt-4  col-6">Paid Point</span><span className="mt-4  float-right col-6">{data.tokenPrice}</span><br></br>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-1 row">
                        <h5 className="col-sm-12 mb-4 mr-5" style={{ color: "#000", fontWeight: "700" }}>Questions</h5>
                    </div>

                    <div className="col-sm-12 row">
                        <div className="header2 questionadmin">
                            <div className="col-sm-12">
                                {
                                    dubq && dubq.map((y, index) => {
                                        return (
                                            <div class="mt-3">
                                                <p> <strong>{(index + 1) + ') ' + y.question}</strong> </p>
                                                <div class="option">
                                                    <label for={"o1"} dangerouslySetInnerHTML={{ __html: y.ansawer }} style={{ fontSize: "16px" }}></label>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 text-left row">
                        <button type="button" id="cancelPost" class="btn btn-primary ml-3" >{data.totalAmount + " $ " + "Estimated Cost"}</button>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-1 row">
                        <h5 className="col-sm-12 mb-4 mr-5" style={{ color: "#000", fontWeight: "700" }}>Note</h5>
                    </div>

                    <div className="col-sm-12 questionadmin row">
                        <div class="first">
                            <textarea id="w3review" name="w3review" rows="4" placeholder="Type Here" value={data.addNote} style={{ border: "none", background: "transparent" }} />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-1">
                        <h5 className="col-sm-12 mb-4 mr-5" style={{ color: "#000", fontWeight: "700" }}>Photo</h5>
                    </div>

                    <div className="col-sm-12 questionadmin row">
                        <div class="first row">
                            {
                                data.uploadImage.length > 0 ? data.uploadImage.map((e) => {
                                    return (<div className="col-md-3">
                                        <a data-fancybox=" gallery" href={`${API}/files/${e}`}><img src={`${API}/files/` + e} style={{ width: "180px", height: "160px" }} /></a>
                                    </div>)
                                }) : ""
                            }
                        </div>
                    </div>



                </div>
            </div>
            <div className='backblack' onClick={handleClick('bottom')} style={open ? { display: "block" } : { display: "none" }} >
            </div>
        </>
    )
}
export default ProfessionaOrdersummary