import React, { useState } from 'react';
import CKEditor from 'ckeditor4-react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import "./Dashboard.css";
import ClearIcon from '@material-ui/icons/Clear';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { check } from 'yargs';
//import { NoEncryption } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    actioncolor: {
      color: "grey",
      width: "1em !important",
    },
    dropdown: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      zIndex: 99999,
      border: '1px solid #fff',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      boxShadow: "0px 4px 8px 2px #888888",
      transform: 'translate(-50%,-50%)',
      width: '100%',
      maxWidth: 700,
      borderRadius: 5,
    },
    edtibutton: {
      border: "none",
      backgroundColor: "#fff",
    },
    option: {
      boxShadow: "0px 4px 8px 2px #888888",
    }

  }
  ),
);
export default function ProfileManagement() {
  const classes = useStyles();
  const [allques, setallques] = useState([1]);
  const [color, setColor] = useState('Earnings')
  const [component1, setComponent1] = useState('Summary')
  const [component, setComponent] = useState('dailyupdate')
  const [questionfill, setquestion] = useState([])
  const [ckeditor, setckeditor] = useState('')
  const [filexit, setfilexit] = useState('')
  const [price, setprice] = useState([])
  const providerData = JSON.parse(sessionStorage.getItem("providerData"))
  const [open, setOpen] = React.useState([]);
  const [updateprofile, setupdateprofile] = React.useState()
  const [count, setcount] = useState(false)
  const [count2, setcount2] = useState(false)
  const [categoryexit, setcategoryexit] = useState([])
  const [update, setupdate] = useState([])
  const [allquestionsend, setallquestionsend] = useState([])
  const [subcategory, setsubcategory] = useState('')
  const [imagesupload, setimagesupload] = useState([])
  const [count3, setcount3] = useState(false);
  const [savequestion, setsavequestion] = useState(false)
  const [savefile, setsavefile] = useState(false)

  const saveNewSetting = () => {
    let get = JSON.parse(sessionStorage.getItem("providerData"))
    if (get) {
      fetch(`${API}/professional/updatePersonalSetting`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(get)
        })
        .then(response => response.json().then(data => {
          if (response.status == 200) {
            toast.success("Details Updated Successfully .");
            sessionStorage.setItem('providerData', JSON.stringify(data))
          }
          else {
            toast.error("Ooops!! Updation Failed .");
          }
        })
        )
      // .catch(err => toast.error("Ooops!! Something went wrong."));
    }

  }


  const saveprice = (e) => {
    setsavequestion(true)
    var raw = JSON.stringify(price);
    if (savequestion == false) {
      fetch(`${API}/professional/addprices`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: raw,
          redirect: 'follow'
        })
        .then(response => response.json().then(data => {
          if (response.status == 200) {
            toast.success("Service Details Updated Successfully.");
            handleClickclose(e)
            localStorage.setItem("redirect", 'profile_management')
            setTimeout(function () { window.location.reload() }, 2000);
          }
          else {
            toast.error("Ooops!! Updation Failed .");
          }
        })
        )
        .catch(err => toast.error("Ooops!! Something went wrong."));
    }
  }


  const select = (cName, e) => {
    let subcategory = []
    providerData.subcategory.map((e) => {
      subcategory.push(e)
    })
    subcategory.push({ "addSubCategory": e.target.value })
    providerData.subcategory = subcategory
    sessionStorage.setItem('providerData', JSON.stringify(providerData))
    setupdateprofile(subcategory)
    setTimeout(function () { saveNewSetting() }, 2000);
  }

  const checkeditor = () => {
    providerData.intro = ckeditor
    sessionStorage.setItem('providerData', JSON.stringify(providerData))
    setTimeout(function () { saveNewSetting() }, 1000);
  }

  const selectdelett = (e) => {
    let subcategory = []
    providerData.subcategory.map((subexit) => {
      if (subexit.addSubCategory != e) {
        subcategory.push(subexit)
      }
    })
    providerData.subcategory = subcategory
    sessionStorage.setItem('providerData', JSON.stringify(providerData))
    setupdateprofile(subcategory)
    setTimeout(function () {
      saveNewSetting()
    }, 1000);
  }
  const getCategory = () => {
    if (!count) {
      getquestion()
      checklocation()
      setcount(true)
      fetch(`${API}/admin/getAllCategory`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) =>
          response.json().then((data) => {
            if (response.status == 200) {
              data.map((sub) => {
                if (providerData.category.replace(/ /g, "") == sub.category.replace(/ /g, "")) {
                  sub.subcategory.map((sublist) => {
                    categoryexit.push(sublist)
                    check()
                  })
                }
              })
              setupdate(categoryexit)
            } else {
              toast.error("Ooops!! Failed to fetch data.");
            }
          })
        )
      // .catch((err) => toast.error("Ooops!! Something went wrong."));

    }
  };
  const checklocation = () => {
    let loct = document.getElementById("vehicle1")
    let loct2 = document.getElementById("vehicle3")
    let loct3 = document.getElementById("vehicle6")
    let loct4 = document.getElementById("vehicle4")
    let loct5 = document.getElementById("vehicle5")

    if (providerData.north == true) {
      loct.checked = true
    }
    if (providerData.west == true) {
      loct2.checked = true
    }
    if (providerData.east == true) {
      loct3.checked = true
    }
    if (providerData.south == true) {
      loct4.checked = true
    }
    if (providerData.any_city == true) {
      loct5.checked = true
    }
  }
  const getquestion = (e) => {
    document.getElementById("loder").style.display = "block"
    fetch(`${API}/admin/getAllQuestion`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            let x = 0
            data.map((sub, index) => {
              x = index + 1
              if (providerData.category.replace(/ /g, "") == sub.category.replace(/ /g, "")) {
                allquestionsend.push(sub)
                // let setdata = { ...providerData, ["pricesQues"]: allquestionsend }
                // sessionStorage.setItem("providerData", JSON.stringify(setdata))
              }
            })
            if (data.length == x) {
              alreadyquestion()
            }
            setupdate(data)
          } else {
            toast.error("Ooops!! Failed to fetch data.");
          }
        })
      )
      .catch((err) => toast.error("Ooops!! Something went wrong."));
  };

  const alreadyquestion = () => {
    fetch(`${API}/professional/getProfessinalPrice?professinalId=` + providerData._id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => response.json().then(data => {
        if (response.status == 200) {
          let fullfill = allquestionsend.map((e, indexm) => {
            data.ProfessinalPrice.map((x) => {
              if (e._id == x.questionId) {
                console.log(e, x)
                x.answer.map((op) => {
                  e.options.map((oop, index) => {
                    if (oop.value == op.option) {
                      let ch = (allquestionsend[indexm].options[index] = op)
                    }
                  })
                })
              }
            })
          })
          document.getElementById("loder").style.display = "none"
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }


  const multyimages = (e) => {
    setsavefile(true)
    var formdata = new FormData();
    formdata.append("_id", providerData._id);
    formdata.append("photo_id", filexit);
    if (filexit == "") {
      toast.error("Please Select Image")
    } else {
      if (savefile == false) {
        console.log()
        if ((filexit.size / (1024 * 1024)) <= 10) {
          var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          };

          fetch(`${API}/professional/addImages`, requestOptions)
            .then(response => response.json())
            .then(result => {
              setimagesupload(result)
              let newdata = { ...providerData, ["profileImages"]: result }
              sessionStorage.setItem("providerData", JSON.stringify(newdata))
              toast.success('Image Updated Successfully')
              setfilexit('')
              setsavefile(false)
            })
            .catch(error => toast.error('error', error));
        } else {
          toast.error('This file size is large.')
          setsavefile(false)
        }
      }
    }
  };

  const topFunction2 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  React.useEffect(() => {
    setTimeout(function () { topFunction2() }, 100);
    getCategory()
    setimagesupload(providerData.profileImages)
  }, [updateprofile, categoryexit, update, questionfill])

  React.useEffect(() => {
    if (!count3) {
      setcount3(true)

    }
  }, [count3])

  const handleClick = (e, sub) => {
    open.push(e)
    for (var x of open) {
      if (x == e) {
        setcount3(false)
        document.getElementById(e).style.display = 'block'
      }
      else {
        document.getElementById(x).style.display = 'none'
      }
    }
    ouestionanswer(sub)
  };
  const handleClickclose = (e) => {
    setcount3(false)

    document.getElementById(e).style.display = 'none'
    getquestion()
  };
  const checkedexit = (e) => {
    let get = JSON.parse(sessionStorage.getItem("providerData"))
    let check = e.target.checked;
    if (check == true) {
      let newdata = { ...get, [e.target.name]: check }
      sessionStorage.setItem("providerData", JSON.stringify(newdata))
    } else {
      let newdata = { ...get, [e.target.name]: check }
      sessionStorage.setItem("providerData", JSON.stringify(newdata))
    }
  }

  const ouestionanswer = (e) => {
    setsubcategory(e)
    setquestion([])
    let newdata = []
    allquestionsend.map((gets) => {
      if (gets.subcategory != undefined) {
        if (gets.subcategory.replace(/ /g, "") == e.replace(/ /g, "")) {

          newdata.push(gets)
          setquestion(newdata)
        }
      }
    })
    setupdate(allquestionsend)
  }

  const questionsave = (value, e, quest, sbid) => {
    let sum = false
    for (var checkprice of price) {
      let sum2 = false
      for (var opti of checkprice.answer) {
        sum2 = true
        if (opti.option.replace(/ /g, "") == value.replace(/ /g, "")) {
          opti.answer = e.target.value
          sum = true
          sum2 = false
        }
      }
      if (sum2 == true) {
        if (checkprice.question.replace(/ /g, "") == quest.replace(/ /g, "")) {
          checkprice.answer.push({ "option": value, "answer": e.target.value })
          sum = true
        }
      }
    }
    if (sum == false) {
      price.push(
        {
          "professinalId": providerData._id,
          "question": quest,
          "subcategory": subcategory,
          "questionId": sbid,
          "answer": [
            { "option": value, "answer": e.target.value }
          ]
        }
      )
    }

  }

  const editquestio = (value, e, quest, sbid) => {
    let sum = false
    for (var checkprice of price) {
      let sum2 = false
      for (var opti of checkprice.answer) {
        sum2 = true
        if (opti.option.replace(/ /g, "") == value.option.replace(/ /g, "")) {
          opti.answer = e.target.value
          sum = true
          sum2 = false
        }
      }
      if (sum2 == true) {
        if (checkprice.question.replace(/ /g, "") == quest.replace(/ /g, "")) {
          checkprice.answer.push({ "_id": value._id, "option": value.option, "answer": e.target.value })
          sum = true
        }
      }
    }
    if (sum == false) {
      price.push(
        {
          "professinalId": providerData._id,
          "question": quest,
          "subcategory": subcategory,
          "questionId": sbid,
          "answer": [
            { "_id": value._id, "option": value.option, "answer": e.target.value }
          ]
        }
      )
    }
  }





  const question = ((x, index) => {
    return (
      <div className=" paddCss1">
        <label><strong>{(index + 1) + ' ' + ")" + ' ' + x.question}</strong></label>
        <div className="row">
          {x.options.length > 0 ? x.options.map((y, index2) => {
            return (
              <div className="mt-2" style={{ display: "block", width: "100%" }}>
                {y.value ?
                  <label>{"(" + (index2 + 1) + ")" + ' ' + y.value}<input type="number" className="editerSmall" id="textEnter" name="textEnter" size="4" onChange={e => questionsave(y.value, e, x.question, x._id)}
                  /></label> :
                  <label>{"(" + (index2 + 1) + ")" + ' ' + y.option}<input type="number" className="editerSmall" id="textEnter" name="textEnter" size="4" onChange={e => editquestio(y, e, x.question, x._id)} defaultValue={y.answer}
                  /></label>
                }
              </div>
            )
          }) : ""}
        </div>
      </div>
    )
  })
  const check = () => {
    let newdata = []
    categoryexit.map((e) => {
      let som = false
      providerData.subcategory.map((x) => {
        if (e.value.toLowerCase().replace(/ /g, "") == x.addSubCategory.toLowerCase().replace(/ /g, "")) {
          som = true
        }
      })
      if (som == false) {
        newdata.push(e)
        setcategoryexit(newdata)
      }
    })
  }


  const deletprofessionimg = (e) => {
    let filtdata = []
    providerData.profileImages.map((deleteimg, index) => {
      let sum = false
      if (index == e) {
        sum = true
      }
      else if (sum == false) {
        filtdata.push(deleteimg)
      }
    })
    setimagesupload(filtdata)
    let newdata = { ...providerData, ["profileImages"]: filtdata }
    sessionStorage.setItem("providerData", JSON.stringify(newdata))
    saveNewSetting()
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div class="loder" id="loder">
        <div class="wrap">
          <div class="loading">
            <div class="bounceball"></div>
            <div class="text">NOW LOADING</div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row row-width2">
          <div className="col-sm-12">
            <h1 className="mt-5 mb-5 margin0"><span className="heading-1">ACCOUNT</span>&nbsp;<span className="heading-2">MANAGEMENT</span></h1>
          </div>
        </div>
        <div className="row row-width2" >
          <div className="col-sm-12">
            <div className="borderP" >
              <div className="header">
                <p>Services</p>
              </div>
              <div className="paddLeft">
                <label>Type of Service</label>
              </div>
              <div className="paddLeft">
                <input type="text" className="form-control" style={{ color: "#212529" }} readonly="" value={providerData.category} />
              </div>
              <div className="paddLeft mt-2">
                <label>Sub category</label>
              </div>
              {
                providerData.subcategory.map((sub, index) => {
                  return (
                    <div className="paddLeft mt-2">
                      <div className="form-control">
                        <div className="row ">
                          <div className="col-sm-8 col-8 pr-0">
                            <div className="">
                              <label>{sub.addSubCategory}</label>
                            </div>
                          </div>
                          <div className="col-sm-4 col-4 pl-0">
                            <div className="float-right">

                              <button type="button"
                                className={classes.edtibutton} onClick={(e) => handleClick("first" + index, sub.addSubCategory)}>
                                <EditIcon className={classes.actioncolor} /></button>
                              &nbsp;&nbsp;
                              <button type="button"
                                className={classes.edtibutton} onClick={(e) => selectdelett(sub.addSubCategory)}>
                                <DeleteIcon className={classes.actioncolor} />
                              </button>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div id={"first" + index} className="displnone">
                              <div class="backnone">
                                <div className={classes.dropdown}>
                                  <div class="overflowqsn">
                                    <button className={classes.edtibutton + ' ' + "cancelb"} onClick={(e) => handleClickclose("first" + index)}><ClearIcon className={classes.actioncolor} /></button>
                                    {count3 ? questionfill.map(question) : ""}
                                    <button className="button btn1" id="saveanswer" onClick={(e) =>
                                      saveprice("first" + index)}>Save</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              <div className="paddLeft mt-3 mb-5">
                <form className="">
                  <div class="">
                    <select className=" form-control text-center selectbtn1 responsiveselect" id="sel1" name="subcategory" onChange={(e) => {
                      select("subcategory", e)
                      check()
                    }}>

                      <option className="text-center" className={classes.option} for="sel2" selected disabled hidden value="">+ Add Another Sub Category</option>
                      {categoryexit ? categoryexit.map((x) => {
                        return (
                          <option className={classes.option} value={x.value}>{x.value}</option>
                        )
                      }) : ''}
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 row-width2" >
          <div className="col-sm-12">
            <div className="borderP" >
              <div className="header">
                <p>Introduction</p>
              </div>
              <div className="header2" >
                <div className="col-sm-12" style={{ padding: "0" }}>
                  <div className="">
                    <CKEditor
                      data={providerData.intro}
                      onChange={evt => setckeditor(evt.editor.getData())}
                    />
                  </div>
                  <button className="button btn1" onClick={e => checkeditor()} style={{ marginLeft: "0" }}>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 row-width2" >
          <div className="col-sm-12">
            <div className="borderP" >
              <div className="header">
                <p>Upload Photo & Video</p>
              </div>
              <div className="header2">
                <div className="col-sm-12" style={{ padding: "0" }}>
                  <div class="zoomprofessional">
                    <ul>
                      {
                        imagesupload.map((images, index) => {
                          var check = images.substr(images.lastIndexOf('\\') + 1).split('.')[1]
                          if (check == "mp4") {
                            return (
                              <li>
                                <button onClick={e => deletprofessionimg(index)}><svg class="" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button>
                                <a data-fancybox=" gallery" href={`${API}/files/${images}`}>
                                  <video class="image_Preview slider-3 img-fluid radius-image-full" width="240px" height="172px" controls>
                                    <source src={`${API}/files/${images}`} type="video/mp4"></source>
                                  </video>
                                </a>
                              </li>
                            )
                          } else {
                            return (
                              <li>
                                <button onClick={e => deletprofessionimg(index)}><svg class="" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button>
                                <a data-fancybox=" gallery" href={`${API}/files/${images}`}>
                                  <img className="slider-3 image_Preview img-fluid radius-image-full" src={`${API}/files/${images}`} alt="" />
                                </a>
                              </li>
                            )
                          }
                        })
                      }
                    </ul>
                  </div>
                </div>
                <div className="col-sm-12" style={{ padding: "0" }}>
                  <div className="header2 position">
                    <input type="file" name="" onChange={(e) => setfilexit(e.target.files[0])} accept="image/x-png,image/gif,image/jpeg,video/mp4" />
                    <div class="upside" >
                      <i class="fa fa-upload" aria-hidden="true"></i>
                      {
                        filexit == "" ? <p>Choose a file.</p> : <p><strong>{filexit.name}</strong></p>
                      }

                    </div>
                  </div>
                  <div className="header2 position savebutton" style={{ paddingLeft: "0" }}>
                    <button className="button btn1 " onClick={e => setfilexit('')} style={{ marginLeft: "0" }}>Cancel</button>
                    {filexit == "" ? <button className="button btn1 "><input type="file" name="" onChange={(e) => setfilexit(e.target.files[0])} />Upload</button> : <button className="button btn1 " onClick={e => multyimages(e)}>Save</button>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 row-width2" >
          <div className="col-sm-12">
            <div className="borderP">
              <div className="header ">
                <p>Location Preferences</p>
              </div>
              <div className="header2 paddCss1">
                <label className="ml-2 ">Select preferred zones</label>
                <div className="row mb-3 mt-3">
                  <div className="col-sm-1 col-1">
                    <input type="checkbox" id="vehicle1" name="north" value="north" onChange={(e) => checkedexit(e)} />
                  </div>
                  <div className="4">
                    <label for="vehicle1"> North</label>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-1 col-1">
                    <input type="checkbox" id="vehicle6" name="east" value="east" onChange={(e) => checkedexit(e)} />
                  </div>
                  <div className="4">
                    <label for="vehicle6">East</label>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-1 col-1">
                    <input type="checkbox" id="vehicle3" name="west" value="west" onChange={(e) => checkedexit(e)} />
                  </div>
                  <div className="4">
                    <label for="vehicle3">West</label>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-1 col-1">
                    <input type="checkbox" id="vehicle4" name="south" value="south" onChange={(e) => checkedexit(e)} />
                  </div>
                  <div className="4">
                    <label for="vehicle4">South</label>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-1 col-1">
                    <input type="checkbox" id="vehicle5" name="any_city" value="any_city" onChange={(e) => checkedexit(e)} />
                  </div>
                  <div className="4">
                    <label for="vehicle5">Downtown</label>
                  </div>
                </div>
                <button className="button btn1" onClick={(e) => saveNewSetting()} style={{ marginLeft: "0" }}>Save</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
