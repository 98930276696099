import React, { useState } from 'react';
import './Admin.css';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import "../dashboard/Dashboard.css";
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    actioncolor: {
      color: "grey",

    },
    dropdown: {
      position: 'absolute',
      top: 28,
      right: 0,
      left: 0,
      zIndex: 1,
      border: '1px solid #fff',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      boxShadow: "0px 4px 8px 2px #888888",
      borderRadius: 5,
    },
    edtibutton: {
      border: "none",
      backgroundColor: "#fff",
    },
    option: {
      boxShadow: "0px 4px 8px 2px #888888",
    }

  }
  ),
);
export default function SearchService() {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [allques, setallques] = useState({});
  const [showPopup, setPopup] = useState(false);
  const [phonePopup, setPhonePopup] = useState(false);
  const [current, setCurrent] = useState();
  const [count, setcount] = useState(false);
  const [categorys, setcategorys] = useState();
  const [categorysel, setcategorysel] = useState();
  const [currentsub, setCurrentsub] = useState();
  const [count1, setcount1] = useState(false);
  const [count2, setcount2] = useState(false);
  const currentlyClicked = JSON.parse(localStorage.getItem("currentlyClicked"))
  const [updateprofile, setupdateprofile] = React.useState()
  const [categoryexit, setcategoryexit] = useState([])
  const [update, setupdate] = useState([])
  const [questionfill, setquestion] = useState([])
  const [open, setOpen] = React.useState([]);
  const [allquestionsend, setallquestionsend] = useState([])
  const [price, setprice] = useState([])
  const [subcategory, setsubcategory] = useState('')
  const [count3, setcount3] = useState(false);
  const [savequestion, setsavequestion] = useState(false)


  React.useEffect(() => {
    if (!count3) {
      getCategory2()
      setcount3(true)
    }
  }, [count3])

  const handleClick = (e, sub) => {
    open.push(e)
    for (var x of open) {
      if (x == e) {
        setcount3(false)
        questionfill.map(question)
        document.getElementById(e).style.display = 'block'
      }
      else {
        questionfill.map(question)
        document.getElementById(x).style.display = 'none'
      }
    }
    ouestionanswer(sub)
  };
  const selectdelett = (e) => {
    let subcategory = []
    currentlyClicked.subcategory.map((subexit) => {
      if (subexit.addSubCategory != e) {
        subcategory.push(subexit)
      }
    })
    currentlyClicked.subcategory = subcategory
    localStorage.setItem('currentlyClicked', JSON.stringify(currentlyClicked))
    setupdateprofile(subcategory)
    setTimeout(function () { saveNewSetting() }, 2000);
  }

  const saveNewSetting = () => {
    let get = JSON.parse(localStorage.getItem("currentlyClicked"))
    if (get) {
      fetch(`${API}/professional/updatePersonalSetting`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(get)
        })
        .then(response => response.json().then(data => {
          if (response.status == 200) {
            toast.success("Details Updated Successfully .");
            localStorage.setItem('currentlyClicked', JSON.stringify(data))
          }
          else {
            toast.error("Ooops!! Updation Failed .");
          }
        })
        )
      // .catch(err => toast.error("Ooops!! Something went wrong."));
    }

  }

  const handleClickclose = (e) => {
    // setOpen((prev) => !prev);
    setcount3(false)
    questionfill.map(question)
    document.getElementById(e).style.display = 'none'
  };

  const select = (cName, e) => {
    let subcategory = []
    currentlyClicked.subcategory.map((e) => {
      subcategory.push(e)
    })
    subcategory.push({ "addSubCategory": e.target.value })
    currentlyClicked.subcategory = subcategory
    localStorage.setItem('currentlyClicked', JSON.stringify(currentlyClicked))
    setupdateprofile(subcategory)
    setTimeout(function () { saveNewSetting() }, 2000);
  }
  const getCategory = () => {
    if (!count) {
      setcount(true)
      fetch(`${API}/admin/getAllCategory`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) =>
          response.json().then((data) => {
            if (response.status == 200) {
              data.map((sub) => {
                if (currentlyClicked.category.replace(/ /g, "") == sub.category.replace(/ /g, "")) {
                  sub.subcategory.map((sublist) => {
                    categoryexit.push(sublist)
                    check()
                  })
                }
              })
              setupdate(categoryexit)
            } else {
              // toast.error("Ooops!! Failed to fetch data.");
            }
          })
        )
      // .catch((err) => toast.error("Ooops!! Something went wrong."));
    }
  };

  React.useEffect(() => {
    getCategory()
  }, [categoryexit, questionfill])



  const getcurrentlyClicked = function () {
    if (!count1) {
      setcount1(true)
    }
  }

  const getCategory2 = function () {
    if (!count2) {
      getquestion()
      setcount2(true)
    }
  };
  const questionsave = (value, e, quest, sbid) => {
    console.log(currentlyClicked._id, value, e, quest, sbid)
    let sum = false
    for (var checkprice of price) {
      let sum2 = false
      for (var opti of checkprice.answer) {
        sum2 = true
        if (opti.option.replace(/ /g, "") == value.replace(/ /g, "")) {
          opti.answer = e.target.value
          sum = true
          sum2 = false
        }
      }
      if (sum2 == true) {
        if (checkprice.question.replace(/ /g, "") == quest.replace(/ /g, "")) {
          checkprice.answer.push({ "option": value, "answer": e.target.value })
          sum = true
        }
      }
    }
    if (sum == false) {
      price.push(
        {
          "professinalId": currentlyClicked._id,
          "question": quest,
          "subcategory": subcategory,
          "questionId": sbid,
          "answer": [
            { "option": value, "answer": e.target.value }
          ]
        }
      )
    }
  }
  const editquestio = (value, e, quest, sbid) => {
    let sum = false
    for (var checkprice of price) {
      let sum2 = false
      for (var opti of checkprice.answer) {
        sum2 = true
        if (opti.option.replace(/ /g, "") == value.option.replace(/ /g, "")) {
          opti.answer = e.target.value
          sum = true
          sum2 = false
        }
      }
      if (sum2 == true) {
        if (checkprice.question.replace(/ /g, "") == quest.replace(/ /g, "")) {
          checkprice.answer.push({ "_id": value._id, "option": value.option, "answer": e.target.value })
          sum = true
        }
      }
    }
    if (sum == false) {
      price.push(
        {
          "professinalId": currentlyClicked._id,
          "question": quest,
          "subcategory": subcategory,
          "questionId": sbid,
          "answer": [
            { "_id": value._id, "option": value.option, "answer": e.target.value }
          ]
        }
      )
    }
  }


  const question = ((x, index) => {
    return (
      <div className=" paddCss1 pdeid">
        <label><strong>{(index + 1) + ' ' + ")" + ' ' + x.question}</strong></label>
        <div className="row">
          {x.options.length > 0 ? x.options.map((y, index2) => {
            return (
              <div className="mt-2" style={{ display: "block", width: "100%" }}>
                {y.value ?
                  <label>{"(" + (index2 + 1) + ' ' + ")" + ' ' + y.value}<input type="text" className="editerSmall" id="textEnter" name="textEnter" size="4" onChange={e => questionsave(y.value, e, x.question, x._id)}
                  /></label> :
                  <label>{"(" + (index2 + 1) + ' ' + ")" + ' ' + y.option}<input type="text" className="editerSmall" id="textEnter" name="textEnter" size="4" onChange={e => editquestio(y, e, x.question, x._id)} defaultValue={y.answer}
                  /></label>
                }
              </div>
            )
          }) : ""}
        </div>
      </div>
    )
  })

  const ouestionanswer = (e) => {
    setsubcategory(e)
    setquestion([])
    let newdata = []
    allquestionsend.map((gets) => {
      if (gets.subcategory != undefined) {
        if (gets.subcategory.replace(/ /g, "") == e.replace(/ /g, "")) {

          newdata.push(gets)
          setquestion(newdata)
        }
      }
    })
    setupdate(allquestionsend)
  }

  const getquestion = (e) => {
    fetch(`${API}/admin/getAllQuestion`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            document.getElementById("loder").style.display = "block"
            let x = 0
            data.map((sub, index) => {
              x = index + 1
              if (currentlyClicked.category.replace(/ /g, "") == sub.category.replace(/ /g, "")) {
                allquestionsend.push(sub)
                // let setdata = { ...providerData, ["pricesQues"]: allquestionsend }
                // sessionStorage.setItem("providerData", JSON.stringify(setdata))
              }
            })
            if (data.length == x) {
              alreadyquestion()
            }
            setupdate(data)
          } else {
            toast.error("Ooops!! Failed to fetch data.");
          }
        })
      )
      .catch((err) => toast.error("Ooops!! Something went wrong."));
  };


  const alreadyquestion = () => {
    fetch(`${API}/professional/getProfessinalPrice?professinalId=` + currentlyClicked._id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => response.json().then(data => {
        if (response.status == 200) {
          let fullfill = allquestionsend.map((e, indexm) => {
            data.ProfessinalPrice.map((x) => {
              if (e._id == x.questionId) {
                x.answer.map((op) => {
                  e.options.map((oop, index) => {
                    if (oop.value == op.option) {
                      let ch = (allquestionsend[indexm].options[index] = op)
                    }
                  })
                })
              }
            })
          })
          document.getElementById("loder").style.display = "none"
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong. already"));
  }

  const check = () => {
    let newdata = []
    categoryexit.map((e) => {
      let som = false
      currentlyClicked.subcategory.map((x) => {
        if (e.value.toLowerCase().replace(/ /g, "") == x.addSubCategory.toLowerCase().replace(/ /g, "")) {
          som = true
        }
      })
      if (som == false) {
        newdata.push(e)
        setcategoryexit(newdata)
      }
    })
  }
  const saveprice = (e) => {
    setsavequestion(true)
    var raw = JSON.stringify(price);
    if (savequestion == false) {
      fetch(`${API}/professional/addprices`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: raw,
          redirect: 'follow'
        })
        .then(response => response.json().then(data => {
          if (response.status == 200) {
            toast.success("Details Updated Successfully .");
            setTimeout(function () { window.location.reload() }, 2000);
          }
          else {
            toast.error("Ooops!! Updation Failed .");
          }
        })
        )
      // .catch(err => toast.error("Ooops!! Something went wrong."));
    }
  }





  return (
    <>
      {getcurrentlyClicked()}

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div class="loder" id="loder">
        <div class="wrap">
          <div class="loading">
            <div class="bounceball"></div>
            <div class="text">NOW LOADING</div>
          </div>
        </div>
      </div>
      <div className="container status-content">
        <div className="row" >
          <div className="col-sm-12">
            <div className="borderP" >
              <div className="header">
                <p>Services</p>
              </div>
              <div className="paddLeft">
                <label>Type of Service</label>
              </div>
              <div className="paddLeft">
                <input type="text" className="form-control" style={{ color: "#212529" }} readonly="" value={currentlyClicked.category} />
              </div>
              <div className="paddLeft mt-2">
                <label>Sub Category</label>
              </div>
              {
                currentlyClicked.subcategory.map((sub, index) => {
                  return (
                    <div className="paddLeft mt-2 categ">
                      <div className="form-control">
                        <div className="row ">
                          <div className="col-sm-8 col-8">
                            <div className="">
                              <label>{sub.addSubCategory}</label>
                            </div>
                          </div>
                          <div className="col-sm-4 col-4">
                            <div className="float-right">

                              <button type="button"
                                className={classes.edtibutton} onClick={(e) => handleClick("first" + index, sub.addSubCategory)}>
                                <EditIcon className={classes.actioncolor} /></button>
                              &nbsp;&nbsp;
                              <button type="button"
                                className={classes.edtibutton} onClick={(e) => selectdelett(sub.addSubCategory)}>
                                <DeleteIcon className={classes.actioncolor} />
                              </button>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div id={"first" + index} className="displnone">
                              <div className={classes.dropdown}>
                                <div class="overflowqsn">
                                  <button className={classes.edtibutton + ' ' + "cancelb"} onClick={(e) => handleClickclose("first" + index)}><ClearIcon className={classes.actioncolor} /></button>
                                  {count3 ? questionfill.map(question) : ""}
                                  <button className="button btn1" id="saveanswer" onClick={(e) => saveprice("first" + index)}>Save</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              <div className="paddLeft mt-3 mb-5">
                <form className="">
                  <div class="">
                    <select className=" form-control text-center selectbtn1" id="sel1" name="subcategory" onChange={(e) => {
                      select("subcategory", e)
                      check()
                    }}>

                      <option className="text-center" className={classes.option} for="sel2" selected disabled hidden value="">+ Add Another Sub Category</option>
                      {categoryexit ? categoryexit.map((x) => {
                        return (
                          <option className={classes.option} value={x.value}>{x.value}</option>
                        )
                      }) : ''}
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
