import React from 'react';
import { Link } from "react-router-dom";
import './Admin.css';
import NotificationsIcon from '@material-ui/icons/Notifications';
import service5 from '../assets/homepage/service1.png';
import Image from 'react-bootstrap/Image';
import usericon from '../assets/homepage/images.png'
import { useState, useEffect } from "react";
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import theomo from '../assets/admin/Theom2.png'

export default function Header() {

    const [tableData, setTableData] = useState([]);
    const [tableDatafix, setTableDatafix] = useState([]);
    const [count, setcount] = useState(false);
    useEffect(() => {
        async function providerDetails() {
            if (!count) {

                setcount(true)
                fetch(`${API}/user/getAllOrder`,
                    {
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        }
                    })
                    .then(response => response.json().then(data => {
                        if (response.status == 200) {
                            data.map((e) => {
                                if (e.isAdmin == true) {
                                    tableData.push(e)
                                }
                            })
                            setTableDatafix(data)
                        } else {
                            toast.error("Ooops!! Failed to fetch data.");
                        }
                    })
                    )
                    .catch(err => toast.error("Ooops!! Something went wrong."));
            }
        }
        providerDetails();
    }, [tableData, count, tableDatafix]);


    const logout = () => {
        localStorage.clear()
        sessionStorage.clear()
        window.location.reload()
    }
    const clicked = (data) => {
        localStorage.setItem(
            'ordercurrentlyClicked', JSON.stringify(data)
        )
        setTableData([])
        let dataapi = [{
            "bookingId": data._id,
            "isAdmin": false
        }]
        fetch(`${API}/admin/updateNotification`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(dataapi)
        })
            .then(response => response.json().then(data => {
                if (response.status == 200) {
                    setcount(false)
                }
            })
            )
            .catch(err => toast.error("Ooops!! Something went wrong."));
    }


    return (
        <>

            <nav className="main-header navbar navbar-expand navbar-white navbar-light admin-header">
                <ul className="navbar-nav menuopen">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><img src={theomo} style={{ width: "19px" }} /></a>
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown dropdone adset">
                        <Link to="#" >
                            <NotificationsIcon className="notif" /><span className="badge badge-warning admin-navbar-notification-icon navbar-badge">{tableData.length}</span>
                        </Link>
                        {tableData.length > 0 ?
                            <div className="dropdown2">
                                <h2>Notifications</h2>
                                <ul className="">
                                    {
                                        tableData.map((noty) => {
                                            return (
                                                <li>
                                                    <span>
                                                        <img src={usericon} class="makeStyles-profile-3 img-fluid" />
                                                    </span>
                                                    <span>
                                                        <Link to="/newAdmin/live-operation" onClick={e => clicked(noty)} style={{ paddingRight: 5 }}>
                                                            <strong>{noty.userId.first_name + ' ' + noty.userId.last_name}</strong>
                                                        </Link>
                                                        place new order please check.</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div> : ''}
                    </li>
                    <li>
                        <Link to="#" >
                            <img src={window.location.origin + "/dist/img/user2-160x160.jpg"} className="img-circle elevation-2 header-adminLogo" alt="User Image" />
                            {/* <Image className="header-adminLogo" src={service5} fluid  /> */}
                        </Link>
                    </li>
                    <li className="adminname">
                        <h4>Raghav</h4>
                        <ul>
                            <li><button onClick={logout}>Log Out</button></li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </>
    )
}
