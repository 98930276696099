import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { Modal, Button, Container, Row } from 'react-bootstrap';
import Signupform from './Signupform'
import './SignUp.css'
import '../Style.css'
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';

export default function Google() {
    const [show, setShow] = useState(false);
    const [showPopup, setPopup] = useState(false);
    const [googledata, setgoogledata] = useState({})

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseSignupForm = () => setPopup(false);
    const showSignForm = () => setPopup(true);

    const responseGoogle = (response) => {
        if (response.profileObj) {
            setgoogledata(response.profileObj)
            googlelog(response.profileObj.email)
        }
        // setPopup(true);
    }

    const googlelog = (e) => {
        let dataapi = {
            "email": e.toLowerCase()
        }
        fetch(`${API}/user/googleSignin`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(dataapi)
        })
            .then(response => response.json().then(data => {
                if (response.status == 200) {
                    toast.success(data.message)
                    localStorage.setItem("userid", data.data._id)
                    sessionStorage.setItem("costumer", JSON.stringify(data.data))
                    sessionStorage.setItem("token", JSON.stringify("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjBmMDA5ZTIzYWZiN2U3OTBlMjBjNDlkIn0sImlhdCI6MTYzMTE2NzM0NywiZXhwIjoxNjMxMTcwOTQ3fQ.kSlSUfPFLpNXWUuNh5J9jy_d9FK3ZOdYCxMYZPac86k"))
                    setTimeout(function () { window.location.reload(false); }, 2000);
                } else {
                    toast.error(data.message)
                }
            })
            )
            .catch(err => toast.error("Ooops!! Something went wrong."))
    }
    return (
        <>
            <GoogleLogin
                clientId="445670612418-800u96b6hb7d0mdjdupoqk0d8gerk3em.apps.googleusercontent.com"
                render={renderProps => (
                    <button className="loginwithgoogle mt-3 btn-lg btn btn-primar" id="loginTabs" onClick={renderProps.onClick} disabled={renderProps.disabled}>Login with Google </button>
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
            />
        </>
    )
}