import React from 'react';
import { Link } from "react-router-dom";
import './Admin.css';
import { useLocation } from 'react-router-dom';

export default function Header() {
    let location = useLocation();
    var currentlyClicked;
    if (location.state) {
        currentlyClicked = location.state
        localStorage.setItem(
            'currentlyClicked', JSON.stringify(currentlyClicked));
    }
    else {
        currentlyClicked = JSON.parse(localStorage.getItem(
            'currentlyClicked'));
    }
    const hidemade2 = () => {
        document.getElementById("hidethis").style.marginLeft = "0"
    }
    return (
        <nav className="main-header navbar search-header navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav stw">
                <li className="nav-item admin-logo-icon">
                    <h4>{currentlyClicked.first_name + ' ' + currentlyClicked.middle_name + ' ' + currentlyClicked.last_name} </h4>
                </li>
                <li onClick={e => hidemade2()} className='mobmn'><i class="fa fa-bars" aria-hidden="true"></i></li>
            </ul>
        </nav>
    )
}
