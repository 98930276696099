import React from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import Footer from './Footer'
import Header from '../homepage/Header'
import usericon from '../assets/homepage/images.png'
import ReactStars from "react-rating-stars-component";
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

export default function Reiewplace() {
    const slugdata = useParams()
    const professionalid = slugdata.professionalid
    const userid = slugdata.userid
    const orderid = slugdata.orderid
    const [rv, setrv] = React.useState('')
    const [rating, setrating] = React.useState(0)
    const [dis, setdis] = React.useState(false)
    const [count, setcount] = React.useState(false)
    const [forget, setforget] = React.useState(false);
    const handleCloseLoginForm = () => {
        setforget(false)
        window.location.replace("/")
    }
    const handleShow = () => {
        setforget(true);
    }
    React.useEffect(() => {
        if (!count) {
            setcount(true)
            fetch(`${API}/user/getOrderBaseReview?professionalId=` + professionalid + `&userId=` + userid + `&orderId=` + orderid, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json().then(result => {
                    if (result.status == 400) {

                        handleShow()
                    }
                }))
                .catch(err => toast.error("Ooops!! Something went wrong."));
        }
    }, [count])

    const reviewadd = () => {
        setdis(true)
        if (rv && rating > 0) {
            let bookingdata = {
                "professionalId": professionalid,
                "userId": userid,
                "addUserReview": rv,
                "rating": rating,
                "orderId": orderid,
            }
            fetch(`${API}/user/addReview`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(bookingdata)
            })
                .then(res => res.json().then(result => {
                    toast.success("Review successfully submitted")
                    setcount(false)
                    setdis(false)
                }))
                .catch(err => toast.error("Ooops!! Something went wrong."));
        }
        else {
            toast.error("select rating  and fill review")
            setdis(false)
        }

    }




    const ratingChanged = (newRating) => {
        setrating(newRating)
    };
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
            <Header />
            <Modal show={forget} id="loginUpModel" onHide={handleCloseLoginForm} size="lg" keyboard={false} aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="successfulbook">
                        Thank you for sharing your valuable feedback!
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <div className="container">
                <div className="review_place mt-5 mb-5">
                    <div className="profileporfess row">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-6">
                            <div className="revrat">
                                <h5 class="mt-2">Rating</h5>
                                <div className=" rating">
                                    <ReactStars
                                        count={5}
                                        onChange={ratingChanged}
                                        size={30}
                                        isHalf={false}
                                        emptyIcon={<i className="far fa-star"></i>}
                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                        fullIcon={<i className="fa fa-star"></i>}
                                        activeColor="#ffd700"
                                    />
                                </div>
                                <h5 class="mt-2">Tall us about your experience</h5>
                                <div class="first">
                                    <textarea id="w3review" name="w3review" rows="4" placeholder="Type Here" onChange={e => setrv(e.target.value)} />
                                </div>
                                <button class="button btn btn-info btn-lg mt-4 admin-status-btn" disabled={dis} onClick={e => reviewadd()}>POST REVIEW</button>
                            </div>
                        </div>
                        <div class="col-sm-3"></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}