import React, { useState } from 'react'
import LoginForm from './LoginForm'
import Google from './google_login'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import '../Style.css'
import './Login.css'
import Signup from "./Signup";
import { Link } from 'react-router-dom';
import Signupform from "./Signupform";
import Phonelogin from "../homepage/Phonelogin"
import Googlesign from './signup_google'
import { API } from "../backend";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const emailRegex = RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);
const validPassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
function Login() {

  const [show, setShow] = useState(false);
  const [showLogin, setLogin] = useState(false);
  const [showsignup, setShowsignup] = useState(false);
  const [showPopup, setPopup] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showgoogle, setshowgoogle] = useState(false);
  const [forget, setforget] = useState(false);
  const [professionalf, setprofessionalf] = useState(false);
  const [costumerf, setcostumerf] = useState(false);
  const [formErrors, setformErrors] = useState('');
  const [login, setlogin] = useState({})
  const [otp, setopt] = useState('')

  const logindata = (e) => {
    let abc = { ...login, [e.target.name]: e.target.value }
    if (e.target.name == "email") {
      abc = { ...login, [e.target.name]: e.target.value.toLowerCase() };
      let x = emailRegex.test(e.target.value) ? ""
        : "Invalid Email";
      setformErrors(x)
    } else if (e.target.value == "newpassword") {
      abc = validPassword.text(e.target.value) ? { ...login, [e.target.name]: e.target.value } : { ...login, [e.target.name]: '' }
    }
    setlogin(abc)
  }

  const professionalapiotp = () => {
    if (login.email) {
      console.log(login.email)
      let dataapi = {
        "email": login.email
      }
      fetch(`${API}/professional/resetpassword`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataapi)
      })
        .then(response => response.json().then(data => {
          console.log(data)
          if (data.status == 200) {
            toast.success("Email sent to registered email address")
            setopt("ugiu")
          } else {
            toast.error(data.text)
          }
        })
        )
    }
    else {
      toast.error("Please email fill")
    }
  }
  const professionalapiotpsub = () => {
    if (login.otp && login.confirmpassword && login.newpassword) {
      let dataapi = {
        "email": login.email,
        "otp": login.otp,
        "confirmPassword": login.confirmpassword,
        "newPassword": login.newpassword
      }
      fetch(`${API}/professional/updatePassword`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataapi)
      })
        .then(response => response.json().then(data => {
          if (data.status) {
            toast.success(data.text)
            setlogin({})
            handleClose()
          } else {
            toast.error(data.text)
          }
        })
        )
    } else {
      toast.error("All field are requred")
    }
  }

  const costumerotp = () => {
    if (login.email) {
      console.log(login.email)
      let dataapi = {
        "email": login.email.toLowerCase()
      }
      fetch(`${API}/user/resetpassword`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataapi)
      })
        .then(response => response.json().then(data => {
          console.log(data)
          if (data.status == 200) {
            toast.success(data.message)
            setopt("ugiu")
          } else {
            toast.error(data.message)
          }
        })
        )
    }
    else {
      toast.error("Please email fill")
    }
  }
  const costumerotpsub = () => {
    if (login.otp && login.confirmpassword && login.newpassword) {
      let dataapi = {
        "email": login.email,
        "otp": login.otp,
        "confirmPassword": login.confirmpassword,
        "newPassword": login.newpassword
      }
      fetch(`${API}/user/updatePassword`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataapi)
      })
        .then(response => response.json())
        .then(data => {
          if (data.status == 400) {
            toast.error("please enter vaild Otp!")
          } else if (data.status == 200) {
            toast.success(data.text)
            setlogin({})
            handleClose()
          }
        }
        )
    } else {
      toast.error("All field are requred")
    }
  }

  const handleClose = () => {
    setShow(false);
    setLogin(false);
    setShowsignup(false);
    setPopup(false);
    setShowPhone(false);
    setshowgoogle(false)
    setforget(false)
    setprofessionalf(false)
    setcostumerf(false)
  }
  const handleShow = () => {
    setShow(true); setLogin(false); setShowsignup(false); setPopup(false); setShowPhone(false);
    setshowgoogle(false); setforget(false); setprofessionalf(false); setcostumerf(false)
  }
  const handleCloseLoginForm = () => {
    setLogin(false);
    setShowsignup(false); setPopup(false); setShowPhone(false);
    setShow(false)
    setshowgoogle(false)
    setforget(false)
    setprofessionalf(false)
    setcostumerf(false)
    setopt('')
  }
  const handleCloseSignupForm = () => {
    setPopup(false);
  }
  const showSignForm = () => {
    setPopup(true);
    setShow(false);
    setLogin(false);
    setShowsignup(false);
    setShowPhone(false);
    setshowgoogle(false)
  }
  const showSignUpOptions = () => {

    return <Modal show={showsignup} id="signUpModel" onHide={handleClose} size="lg" keyboard={false} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="content-signUp justify-content-center">
            <Button id="signUpTabs" className="signUpTabs btn-lg" onClick={() => { setPopup(true); setShowsignup(false); }} variant="secondary" block>Sign up using Email</Button>{''}
            {/* <Button className="signUpTabs mt-3 btn-lg"  block>Sign up using Phone</Button>{''} */}
            <Googlesign onClick={() => { handleClose() }} />{''}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>

  }


  const showSignUpForm = () => {

    return <Modal show={showPopup} id="signUpForm" onHide={handleCloseSignupForm} size="lg" keyboard={false} aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Signupform />
          </Row>
        </Container>
      </Modal.Body>
    </Modal>


  }

  const professional = () => {

    return <Modal show={professionalf} id="signUpForm" onHide={handleCloseLoginForm} size="md" keyboard={false} aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="mb-3">
            <div className="forgetdesig">
              {otp ? '' :
                <div className="email">
                  <h2>Enter Your Registered Email Id.</h2>
                  <input className="eml" placeholder="Email" name="email" className={formErrors.length > 0 ? "error" : "input_user"} onBlur={e => logindata(e)} />
                  {formErrors.length > 0 && (
                    <span className="errorMessage">{formErrors}</span>
                  )}
                  <a className="cancel-Post"><button type="button" id="cancelPost" className="btn btn-primary" style={{ width: "130px" }} onClick={e => {
                    professionalapiotp()

                  }}>Get Code</button></a>
                </div>}
              {
                otp ?
                  <div className="otp">
                    <h1>{login.email}</h1>
                    <h2>Enter Your Code</h2>
                    <input className="eml" placeholder="OTP" name="otp" onChange={e => logindata(e)} />
                    <h2>New Password</h2>
                    <div class="rlative passwordsug" style={{ position: "relative" }}>
                      <input className={login.newpassword ? login.newpassword.length < 8 ? "error eml" : "eml" : ""} type="password" placeholder="New Password" onChange={e => logindata(e)} name="newpassword" />
                      {login.newpassword ? login.newpassword.length < 8 && (
                        <div id="messagepassford">
                          <h3>Password must contain the following:</h3>
                          <p id="capital" class="invalid">A <b>Capital (Uppercase)</b> letter</p>
                          <p id="letter" class="invalid">A <b>Lowercase</b> letter</p>
                          <p id="number" class="invalid">A <b>Special Characters</b></p>
                          <p id="number" class="invalid">A <b>Number</b></p>
                          <p id="length" class="invalid">Minimum <b>8 Characters</b></p>
                        </div>
                      ) : ""}
                    </div>
                    <h2>Confirm Password</h2>
                    <input className="eml" type="password" placeholder="Confirm Password" onChange={e => logindata(e)} name="confirmpassword" />
                    {login.confirmpassword ? login.newpassword != login.confirmpassword && (
                      <span className="errorMessage">{"The password does not match"}</span>
                    ) : ""}
                    <a className="cancel-Post"><button type="button" id="cancelPost" className="btn btn-primary" onClick={e => professionalapiotpsub()}>Reset password</button></a>
                  </div> : ''
              }
            </div>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>


  }



  const costumer = () => {

    return <Modal show={costumerf} id="signUpForm" onHide={handleCloseLoginForm} size="md" keyboard={false} aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="mb-3">
            <div className="forgetdesig">
              {otp ? '' :
                <div className="email">
                  <h2>Enter Your Registered Email Id.</h2>
                  <input className="eml" placeholder="Email" className={formErrors.length > 0 ? "error" : "input_user"} name="email" onBlur={e => logindata(e)} />
                  {formErrors.length > 0 && (
                    <span className="errorMessage">{formErrors}</span>
                  )}
                  <a className="cancel-Post"><button type="button" id="cancelPost" className="btn btn-primary" style={{ width: "130px" }} onClick={e => {
                    costumerotp()

                  }}>Get Code</button></a>
                </div>}
              {
                otp ?
                  <div className="otp">
                    <h1>{login.email}</h1>
                    <h2>Enter Your Code</h2>
                    <input className="eml" placeholder="OTP" name="otp" onChange={e => logindata(e)} />
                    <h2>New Password</h2>
                    <div class="rlative passwordsug" style={{ position: "relative" }}>
                      <input className={login.newpassword ? login.newpassword.length < 8 ? "error eml" : "eml" : ""} placeholder="New Password" onChange={e => logindata(e)} name="newpassword" type="password" />
                      {login.newpassword ? login.newpassword.length < 8 && (
                        <div id="messagepassford">
                          <h3>Password must contain the following:</h3>
                          <p id="capital" class="invalid">A <b>Capital (Uppercase)</b> letter</p>
                          <p id="letter" class="invalid">A <b>Lowercase</b> letter</p>
                          <p id="number" class="invalid">A <b>Special Characters</b></p>
                          <p id="number" class="invalid">A <b>Number</b></p>
                          <p id="length" class="invalid">Minimum <b>8 Characters</b></p>
                        </div>
                      ) : ""}
                    </div>
                    <h2>Confirm Password</h2>
                    <input className="eml" type="password" placeholder="Confirm Password" onChange={e => logindata(e)} name="confirmpassword" />
                    {login.confirmpassword ? login.newpassword != login.confirmpassword && (
                      <span className="errorMessage">{"The password does not match"}</span>
                    ) : ""}
                    <a className="cancel-Post"><button type="button" id="cancelPost" className="btn btn-primary" onClick={e => costumerotpsub()}>Reset password</button></a>
                  </div> : ''
              }
            </div>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>


  }



  const forgetpassword = () => {

    return <Modal show={forget} id="loginUpModel" className="mb-3" onHide={handleCloseLoginForm} size="lg" keyboard={false} aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="justify-content-center mb-3">
            <h2 style={{ fontSize: "24px", paddingTop: "0" }}><strong>Which account do you have ? </strong></h2>
            <Button className="loginwithemail btn-lg" id="loginTabs" onClick={() => { setLogin(false); setshowgoogle(false); setShow(false); setShowsignup(false); setPopup(false); setShowPhone(false); setcostumerf(true); setforget(false) }}>Customer</Button>{''}
            <Button className="loginwithphone mt-3 btn-lg" id="loginTabs" onClick={() => { setShowPhone(false); setshowgoogle(false); setShow(false); setShow(false); setShowsignup(false); setPopup(false); setprofessionalf(true); setforget(false) }}>Professional</Button>{''}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>


  }
  const phoneLogin = () => {
    return <Modal show={showPhone} id="Phonelogin" style={{ height: "400px" }} onHide={() => { setShowPhone(false); }} size="md" keyboard={false} aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Phonelogin />
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  }


  const loginPopUp = () => {
    return <Modal show={show} id="loginUpModel" onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="justify-content-center">
            <Button className="loginwithemail btn-lg" id="loginTabs" onClick={() => { setLogin(true); setshowgoogle(false); setShow(false); setShowsignup(false); setPopup(false); setShowPhone(false); }}>Login with Email</Button>{''}
            <Button className="loginwithphone mt-3 btn-lg" id="loginTabs" onClick={() => { setShowPhone(true); setshowgoogle(false); setShow(false); setShow(false); setShowsignup(false); setPopup(false); }}>Login with Phone</Button>{''}
            <Google onClick={() => { handleClose(); setshowgoogle(false); setShowPhone(false); setShow(false);setShowsignup(false); setPopup(false); }} />{''}
          </Row>
        </Container>
        <Container className="mt-3">
          <div className="d-flex justify-content-center links ml-5 mt-4 lognaddd" onClick={() => { setshowgoogle(false); setLogin(false); setShow(false); setShowsignup(true); setPopup(false); setShowPhone(false); }}>
            <span>Don't have an account?</span><Signup />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  }
  const loginForm = () => {
    return <Modal id="login-form" show={showLogin} onHide={handleCloseLoginForm} size="lg" keyboard={false} aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <LoginForm btnn={
              <div className="forgetPassword" onClick={() => { setshowgoogle(false); setLogin(false); setShow(false); setShowsignup(false); setPopup(false); setShowPhone(false); setforget(true) }}>
                <a href="#">Forgot your password?</a>
              </div>
            }
            />
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  }

  const googleform = () => {
    return <Modal id="login-form" show={showgoogle} onHide={handleCloseLoginForm} size="lg" keyboard={false} aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Google />
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  }
  return (
    <>
      <Button id="login-btn" onClick={handleShow}>
        Log in
      </Button>
      {show ? loginPopUp() : ""}
      {showsignup ? showSignUpOptions() : ""}
      {showPopup ? showSignUpForm() : ""}
      {showPhone ? phoneLogin() : ""}
      {showLogin ? loginForm() : ""}
      {showgoogle ? googleform() : ""}
      {forget ? forgetpassword() : ""}
      {costumerf ? costumer() : ""}
      {professionalf ? professional() : ""}
    </>
  );
}

export default Login