import React from 'react'
import AdminDashboard from './AdminDashboard.css';
import photography from '../assets/homepage/photography.jpg';
import '../AdminComponents/Admin.css';
import { API } from "../backend";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
export default function ShowDocumnets(props) {
    const [currIndex, setcurrIndex] = useState(0);
    const [setref, setsetref] = useState({});
    const [app, setapp] = useState();
    const [rej, setrej] = useState([]);
    const currentlyClicked = JSON.parse(localStorage.getItem('currentlyClicked'));
    const arr = [];
    arr[0] = { file: `${API}/files/${currentlyClicked.background}` }
    arr[0].type = currentlyClicked.background.substring(currentlyClicked.background.indexOf('.') + 1)
    arr[0].name = 'Background'
    arr[0].approved = currentlyClicked.valid_background ? currentlyClicked.valid_background == true ? <span className="title-of-image approvedtext"> Documnets Approved</span> : <span className="title-of-image notapprovedtext"> Document Rejected</span> : (currentlyClicked.valid_background == false ? <span className="title-of-image notapprovedtext"> Document Rejected</span> : <span className="title-of-image approvedtext cyellow"> Documnets Not Approved</span>)

    arr[1] = { file: `${API}/files/${currentlyClicked.child_abuse}` }
    arr[1].type = currentlyClicked.child_abuse.substring(currentlyClicked.child_abuse.indexOf('.') + 1)
    arr[1].name = 'Child Abuse'
    arr[1].approved = currentlyClicked.valid_child_abuse ? (currentlyClicked.valid_child_abuse == true ? <span className="title-of-image approvedtext"> Documnets Approved</span> : <span className="title-of-image notapprovedtext"> Document Rejected</span>) : (currentlyClicked.valid_child_abuse == false ? <span className="title-of-image notapprovedtext"> Document Rejected</span> : <span className="title-of-image approvedtext cyellow"> Documnets Not Approved</span>)

    // arr[2] = { file: `${API}/files/${currentlyClicked.vulnebrity_check}` }
    // arr[2].type = currentlyClicked.vulnebrity_check.substring(currentlyClicked.vulnebrity_check.indexOf('.') + 1)
    // arr[2].name = 'Vulnebrity Check'
    // arr[2].approved = currentlyClicked.valid_vulnebrity_check == true ? <span className="title-of-image approvedtext"> Documnets Approved</span> : <span className="title-of-image notapprovedtext"> Document Rejected</span>

    arr[2] = { file: `${API}/files/${currentlyClicked.visa_document}` }
    arr[2].type = currentlyClicked.visa_document.substring(currentlyClicked.visa_document.indexOf('.') + 1)
    arr[2].name = 'Visa Document'
    arr[2].approved = currentlyClicked.valid_visa_document ? currentlyClicked.valid_visa_document == true ? <span className="title-of-image approvedtext"> Documnets Approved</span> : <span className="title-of-image notapprovedtext"> Document Rejected</span> : (currentlyClicked.valid_visa_document == false ? <span className="title-of-image notapprovedtext"> Document Rejected</span> : <span className="title-of-image approvedtext cyellow"> Documnets Not Approved</span>)


    // arr[4] = { file: `${API}/files/${currentlyClicked.work_reference}` }
    // arr[4].type = currentlyClicked.work_reference.substring(currentlyClicked.work_reference.indexOf('.') + 1)
    // arr[4].name = 'Work Reference'
    // arr[4].approved = currentlyClicked.valid_work_reference == true ? <span className="title-of-image approvedtext"> Documnets Approved</span> : <span className="title-of-image notapprovedtext"> Document Rejected</span>

    arr[3] = { file: `${API}/files/${currentlyClicked.photo_id}` }
    arr[3].type = currentlyClicked.photo_id.substring(currentlyClicked.photo_id.indexOf('.') + 1)
    arr[3].name = 'Photo Id'
    arr[3].approved = currentlyClicked.valid_photo_id ? currentlyClicked.valid_photo_id == true ? <span className="title-of-image approvedtext"> Documnets Approved</span> : <span className="title-of-image notapprovedtext"> Document Rejected</span> : (currentlyClicked.valid_photo_id == false ? <span className="title-of-image notapprovedtext"> Document Rejected</span> : <span className="title-of-image approvedtext cyellow"> Documnets Not Approved</span>)
    React.useEffect(() => {
        console.log(currentlyClicked.valid_photo_id, currentlyClicked)

    }, [arr, currIndex])

    const saveNewSetting = () => {
        let get = JSON.parse(localStorage.getItem("currentlyClicked"))
        if (get) {
            fetch(`${API}/professional/updatePersonalSetting`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(get)
                })
                .then(response => response.json().then(data => {
                    if (response.status == 200) {
                        // toast.success("Details Updated Successfully .");
                        localStorage.setItem('currentlyClicked', JSON.stringify(data))
                        setsetref(data)
                    }
                    else {
                        toast.error("Ooops!! Updation Failed .");
                    }
                })
                )
            // .catch(err => toast.error("Ooops!! Something went wrong."));
        }
    }


    const leftclicked = () => {
        if (currIndex > 0) {
            setcurrIndex(currIndex - 1)
        }

    }

    const rightclicked = () => {
        if (currIndex < 3) {
            setcurrIndex(currIndex + 1)
        }
        else {
            setcurrIndex(0)
        }
    }
    const approved = (e, id, name) => {
        let get = JSON.parse(localStorage.getItem("currentlyClicked"))
        if (currIndex <= 4) {
            let newdata = { ...get, [name]: true }
            localStorage.setItem('currentlyClicked', JSON.stringify(newdata))
            saveNewSetting()
            toast.success("Document approved.");
            // setTimeout(function(){ window.location.reload(false) }, 1500);
        }
    }
    const Rejected = (e, id, name) => {
        let get = JSON.parse(localStorage.getItem("currentlyClicked"))
        if (currIndex <= 3) {
            let newdata = { ...get, [name]: false }
            localStorage.setItem('currentlyClicked', JSON.stringify(newdata))
            saveNewSetting()
            toast.success("Document Rejected.");
        }
    }
    const imgchange = (e, id, name) => {
        var formdata = new FormData();
        formdata.append("_id", id);
        formdata.append("type", name);
        formdata.append("background", e.target.files[0]);
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${API}/professional/uploadDocumentImage`, requestOptions)
            .then(response => response.json()
                .then(result => {
                    toast.success('Image Updated Successfully')
                    const currentlyClicked = JSON.parse(localStorage.getItem('currentlyClicked'));
                    let newdata = { ...currentlyClicked, [name]: result.image_path }
                    localStorage.setItem('currentlyClicked', JSON.stringify(newdata))
                    setTimeout(function () { window.location.reload(false) }, 1000);

                }))
            .catch(error => toast.error('error', error));
    }
    return (
        <div className="container image-page-size">
            <ToastContainer
                position="top-center"
                autoClose={2000}
            />
            <div className="col-md-12">
                <div className="titleOne">
                    <h3 className="docsTitle">Documents</h3>
                </div>
            </div>

            <div className="row">
                <div className='col-md-3 leftArrow'>
                    <button className="round" onClick={e => leftclicked()}><i class="arrow left"></i></button>
                </div>
                <div className=' gallery col-md-6'>
                    <div className=''>
                        {
                            arr[currIndex].type == "JPG" || arr[currIndex].type == "PNG" || arr[currIndex].type == "jpg" || arr[currIndex].type == "png" || arr[currIndex].type == "jpeg" ? <img src={arr[currIndex].file} width="600" height="400" style={{ width: "100%" }} className='documentshowtag' /> :
                                <iframe src={arr[currIndex].file} width="600" height="400" style={{ width: "100%" }} allowfullscreen="allowfullscreen" frameborder='0' className='documentshowtag'></iframe>
                        }
                    </div>
                </div>
                <div className='col-md-3 rightArrow'>
                    <button className="round" onClick={e => rightclicked()}><i class="arrow right"></i></button>
                </div>
            </div>
            <div className="row mid-section">
                <div className="col-md-12" style={{ textAlign: "center" }}>
                    {arr[currIndex].approved}
                </div>
            </div>
            <div className="row mid-section">
                <div className="col-md-12" style={{ textAlign: "center" }}>
                    <span className="title-of-image">{arr[currIndex].name}</span>
                </div>
            </div>

            <div className="row bottom-row">
                <div className='col-md-3 approved'>
                </div>
                <div className='col-md-6 approved row'>
                    <div class="col-md-4"><button className="round documents-approve-btn " style={{ float: "right" }}>
                        <input type="file" name="document" id="profile_pic_upload" onChange={(e) => imgchange(e, currentlyClicked._id, arr[currIndex].name.replace(/ /g, "_").toLowerCase())} style={{
                            width: "100px",
                            height: "38px"
                        }} />
                        Upload</button></div>
                    <div class="col-md-4" style={{ textAlign: "center" }}><button className="round documents-approve-btn " onClick={e => approved(e, currentlyClicked._id, "valid_" + arr[currIndex].name.replace(/ /g, "_").toLowerCase())}>Approve</button></div>
                    <div class="col-md-4"><button className="round documents-reject-btn " onClick={e => Rejected(e, currentlyClicked._id, "valid_" + arr[currIndex].name.replace(/ /g, "_").toLowerCase())}>Reject</button></div>
                </div>
                <div className='col-md-3 approved'>
                </div>
            </div>
        </div>
    );

}