import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { Player, ControlBar } from 'video-react';
import sectionthird2 from '../assets/homepage/how.mp4'

class Sectionthird extends React.Component {

	handleVideoMounted = element => {
		if (element !== null) {
			element.currentTime = 2;
		}
	};

	render() {
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
		};
		return (
			<section className="sec-3 mt-5 mb-5">
				<Container>
					<Row>
						<Col md={6} className="" >
							<h1 className="secheading mb-2"><span className="heading-1">How it</span>&nbsp;<span className="heading-2">Works ?</span></h1>
							<video width="100%" loop="true" controls id="video" ref={this.handleVideoMounted}>
								<source src={sectionthird2} type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						</Col>

						<Col md={6} className="">
							<h1 className="secheading  mb-5"><span className="heading-1">CONSUMER</span>&nbsp;<span className="heading-2">STORIES</span></h1>

							<div className="container mt-5" style={{ textAlign: "center" }}>
								<Slider {...settings}>
									<div>
										<p>Coming Soon</p>

									</div>
									<div>
										<p>Coming Soon</p>
									</div>
									<div>
										<p>Coming Soon </p>
									</div>
									<div>
										<p>Coming Soon</p>
									</div>
								</Slider>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}


}
export default Sectionthird
