import React from 'react';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import SaveIcon from '@material-ui/icons/Save';
import service5 from '../assets/homepage/images.png';
import { Star as AddIcon } from '@material-ui/icons';
import Showreview from "../review/Showreview";
import ORDERCOMPLETED from '../assets/Icons/ORDER-COMPLETED.png';
import EARNING from '../assets/Icons/EARNING.png';
import POINTS from '../assets/Icons/POINT.png';
import saveIcon from '../assets/Icons/save.png';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Container, Row } from "react-bootstrap";

export default function Dashboard(props) {
  const providerData = JSON.parse(sessionStorage.getItem("providerData"))
  const [count, setcount] = React.useState(false);
  const [orderdata, setorderdata] = React.useState([])
  const [upcomming, setupcomming] = React.useState([])
  const [total, settotal] = React.useState([])
  const [refresh, setrefresh] = React.useState([])
  const [refresh2, setrefresh2] = React.useState([])
  const [redirect, setredirect] = React.useState('')
  const [point, setpoint] = React.useState(0)
  const [pid, sepid] = React.useState(0)
  const [statussetdata, setstatusset] = React.useState('')
  const [reviewa, setreview] = React.useState([])
  const [vl, setvl] = React.useState(0)
  const [showPopup, setPopup] = React.useState(false);
  const handleCloseSignupForm = () => {
    setPopup(false)
  };
  const topFunction2 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  React.useEffect(() => {
    setTimeout(function () { topFunction2() }, 100);
    if (!count) {
      review()
      document.getElementById("loder").style.display = "block"
      fetch(`${API}/user/getProfessionalOrder/` + providerData._id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
        .then(response => response.json().then(data => {
          document.getElementById("loder").style.display = "none"
          if (response.status == 200) {
            settotal(data.data)
            data.data.map((x, index) => {
              x.professionalId.map((y, index) => {
                if (y.professionalId._id == providerData._id && y.status == "Pending") {
                  if (new Date(x.bookingDate).getDate() >= new Date().getDate() || new Date(x.bookingDate).getMonth() >= new Date().getMonth() && new Date(x.bookingDate).getFullYear() >= new Date().getFullYear()) {
                    x.professionalId = [y]
                    orderdata.push(x)

                    console.log(new Date(x.bookingDate).getDate(), new Date().getDate())
                  }
                }
              })
            })
          }
          setrefresh2(data.data)
          console.log(orderdata)
        })
        )
        .catch(err => toast.error("Ooops!! Something went wrong."));

      // upcomming api 
      fetch(`${API}/admin/currentDateWise?id=` + providerData._id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
        .then(response => response.json().then(data => {
          data.data.map((x, index) => {
            x.professionalId.map((y, index) => {
              if (y.professionalId._id == providerData._id && y.status == "Accepted") {
                x.professionalId = [y]
                upcomming.push(x)
              }
            })
          })
        })
        )
        .catch(err => toast.error("Ooops!! Something went wrong. upcomming"));
      //points

      fetch(`${API}/professional/getProfessinalToken?professionalId=` + providerData._id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
        .then(response => response.json().then(data => {
          setpoint(data.data.totalPoint)
        })
        )
        .catch(err => toast.error("Ooops!! Something went wrong."));
      setcount(true)
    }
  }, [count, refresh, refresh2]);


  const statusset = (id) => {
    setredirect(id)
    if (statussetdata == "") {
      toast.error("Please select status")
    }
    else if (statussetdata != "Accepted") {
      let dataapi = {
        "_id": id,
        "status": statussetdata,
        "professionalId": pid.id
      }
      fetch(`${API}/user/UpdateStatus`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataapi)
      })
        .then(response => response.json().then(data => {
          if (response.status == 200) {
            toast.success("Successful")
          }
        })
        )
        .catch(err => toast.error("Ooops!! Something went wrong."));
      setcount(false)
      setrefresh("gctycyugvufvuy")
    } else {
      pointcut("point")
    }
  }

  const acceptbooking = (id) => {
    let dataapi = {
      "_id": redirect,
      "status": "Accepted",
      "professionalId": pid.id
    }
    fetch(`${API}/user/UpdateStatus`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(dataapi)
    })
      .then(response => response.json().then(data => {
        if (response.status == 200) {
          toast.success("Successful")
          setcount(false)
          noneqsn("point")
          setrefresh2([])
          setorderdata([])
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }

  const clicked = () => {
    let dataapi = {
      "professionalId": pid.id,
      "totalPoint": pid.point,
      "paymentStatus": "redeemed"
    }
    fetch(`${API}/professional/transaction`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(dataapi)
    })
      .then(response => response.json().then(data => {
        if (data.result) {
          // toast.success("Successful")
          acceptbooking()
        } else {
          setPopup(true)
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }

  const locals = (data) => {
    localStorage.setItem(
      'professioanlclick', JSON.stringify(data)
    )
  }


  const noneqsn = (e) => {
    let questionpop = document.getElementById(e).style.display = "none"
  }

  const pointcut = (e) => {
    let questionpop = document.getElementById(e).style.display = "block"
  }

  const review = () => {
    fetch(`${API}/professional/getProfessinalWiseReview?professionalId=` + providerData._id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    })
      .then(response => response.json().then(data => {
        if (data.status == 200) {
          setreview(data.data)
        } else {
          toast.error(data.message)
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }
  return (
    <div className="container">
      <ToastContainer
        position="top-center"
        autoClose={2000}
      />
      <div class="loder" id="loder">
        <div class="wrap">
          <div class="loading">
            <div class="bounceball"></div>
            <div class="text">NOW LOADING</div>
          </div>
        </div>
      </div>
      <h3 className="welcome mt-3"> Welcome, {providerData ? providerData.first_name + ' ' + providerData.last_name : ""}</h3>
      <p>Theom Service Professional</p>
      <div className="row-width row mt-5 ml-0 mr-0">
        <div className="dashboard-colunm-left  col-xs-6  col-sm-6 col-md-6">
          <div className="earnig_image">
            <img className="ordercomplete-image img-fluid" src={ORDERCOMPLETED} />
            <h4 className="order-complete">Total Order
              <span className="number-style">{total.length < 9 ? "0" + total.length : total.length}</span></h4>
          </div>
        </div>
        {/* <div className="dashboard-colunm-center col-xs-6 col-sm-6 col-md-6">
          <div className="earnig_image">
            <img className="review-image img-fluid" src={EARNING} />
            <h5 className="order-complete">Last Week Earning
              <span className="number-style"> $ 2200</span>
            </h5>
          </div>
        </div> */}
        <div className="dashboard-colunm-right col-xs-6 col-sm-6 col-md-6">
          <div className="earnig_image">
            <img className="review-image img-fluid" src={POINTS} />
            <h5 className="order-complete">Points
              <span className="number-style">{point}</span>
            </h5>
          </div>
        </div>
      </div>
      <h3 className="mt-5 mb-4"><span className="heading-1">PENDING</span>&nbsp;<span className="heading-2">ORDERS</span></h3>
      <div className="table-responsive">
        <Modal
          show={showPopup}
          id="questionForm"
          onHide={handleCloseSignupForm}
          size="lg"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><strong>{"Order Summary"}</strong></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <div class="popupprovider questionpopup successtab">
                  <p>You have no points so please by points and check order</p>
                </div>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
        <div class="shadow" id="point" >
          <div class="popupprovider questionpopup successtab">
            <p>You will be charged {pid.point} points to accept the order request, do you wish to continue?</p>
            <div class="buttons" >
              <button class="buttonn preview" onClick={e => clicked()}> YES</button>
              <button class="buttonn next" onClick={e => noneqsn("point")}> NO</button>
            </div>
          </div>
        </div>
        <table className="table  table-bordered text-center">
          <thead>
            <tr className="table-heading">
              <th className="table-heading-first" scope="col">Order Number</th>
              <th scope="col" class="hidetable">Service Name</th>
              <th scope="col">$ Amount</th>
              <th scope="col" class="hidetable">Order Time & Date</th>
              <th className="table-heading-last" scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              orderdata.map((data, index) => {
                if (index < 3) {
                  return (
                    <tr>
                      <td scope="row"><Link to="/serviceprovider/order-summary" onClick={e => locals(data)}>{data.orderID}</Link></td>
                      <td class="hidetable">{data.subCategory}</td>
                      <td>{"$" + data.totalAmount}</td>
                      <td class="hidetable">{data.startTime + " - " + data.endTime} <br /> {(data.bookingDate.substr(data.bookingDate.lastIndexOf('\\') + 1).split('T')[0])}</td>
                      <td>
                        <form style={{ textAlign: "right" }}>
                          <select className="select-action" onChange={e => {
                            setstatusset(e.target.value)
                            sepid({ "id": data.professionalId[0].professionalId._id, "point": data.tokenPrice })
                          }}>
                            <option value="Pending">select</option>
                            <option value="Accepted">Accept</option>
                            <option value="Reject">Reject</option>
                          </select>
                          <Button onClick={e => statusset(data._id)}><img className="select-image img-fluid" src={saveIcon} />
                          </Button>
                          <Link to="/need-help" className="needhlp"><i class="fas fa-phone-alt"></i></Link>
                        </form>
                      </td>
                    </tr>
                  )
                }
              })
            }
          </tbody>
        </table>

      </div>
      <div class="col-12 mt-1 dtview">
        To view full slide right.
      </div>
      <h3 className="mt-5 mb-4"><span className="heading-1">UPCOMING</span>&nbsp;<span className="heading-2">ORDERS</span></h3>
      <div className="table-responsive">
        <table className="table  table-bordered text-center">
          <thead>
            <tr className="table-heading" >
              <th className="table-heading-first" scope="col">Order Number</th>
              <th scope="col" class="hidetable">Service Name</th>
              <th scope="col">$ Amount</th>
              <th className="table-heading-last hidetable" scope="col">Order Time & Date</th>
              <th className="table-heading-last" scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>

            {
              upcomming.map((data) => {
                return (
                  <tr>
                    <td scope="row"><Link to="/serviceprovider/order-summary" onClick={e => locals(data)}>{data.orderID}</Link></td>
                    <td class="hidetable"  >{data.subCategory}</td>
                    <td>{"$" + data.totalAmount}</td>
                    <td class="hidetable">{data.startTime + " - " + data.endTime} <br /> {(data.bookingDate.substr(data.bookingDate.lastIndexOf('\\') + 1).split('T')[0])}</td>
                    <td>
                      <form style={{ textAlign: "center" }}>
                        {data.status}
                        <Link to="/need-help" className="needhlp"><i class="fas fa-phone-alt"></i></Link>
                      </form>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <div class="col-12 view">
          {/* <Link to=""	>View All</Link> */}
        </div>
      </div>
      <div class="col-12 mt-1 dtview">
        To view full slide right.
      </div>
      <div>
        <h3 className="mt-5 mb-4"><span className="heading-1">REVIEWS</span></h3>
        <div className=" row-width  reviews row">
          <div className=" col-xs-12  col-sm-12 col-md-12">
            {
              reviewa.map((e, index) => {
                if (index <= vl) {
                  return (
                    <>
                      <div className="user_image  pb-2">
                        <img className="review-image img-fluid" src={service5} />
                        <h4 className="reviewer-name">{e.userId.first_name + " " + e.userId.last_name}
                          <p style={{ marginBottom: "0px", float: "right" }}><span class="ml-5">{e.createdAt.substr(e.createdAt.lastIndexOf('\\') + 1).split('T')[0]}</span></p>
                          <p style={{ marginBottom: "0px", float: "left", width: "100%" }}><span class="star"><Showreview star={e.rating} /></span> <span class="ml-2">({e.rating}/5)</span> </p>
                          <span className="review">{e.addUserReview} </span>
                        </h4>
                      </div>
                    </>
                  )
                }
              })
            }
          </div>
        </div>
      </div>
      <div class="col-12 view mt-3">
        {
          vl < 100 ? <Link onClick={e => setvl(100)}	>View All</Link> : <Link onClick={e => setvl(0)}	>View Less</Link>
        }
      </div>
    </div>
  );
}
