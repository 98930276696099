import React from 'react';
import Admin from './Admin.css';
import { Redirect } from "react-router-dom";

export default function AdminService() {

    return (
        <div className="AdminLiveOperation">
          AdminService
        </div>
    )
}
