import React from 'react';
import Mainrouting from './routing/routing'



function App() {
  return (
    <>
      <Mainrouting />
    </>
  );
}

export default App;
