import React from 'react';
import Footer from './Footer'
import Header from '../homepage/Header'
import "../Style.css";
import careerBanner from '../assets/Career Page/career-banner.jpg';
function PrivacyPolicies() {

    return (
        <>
            <div ref={window.scrollTo(0, 0)} className="privacyPolicies">
                <Header />
                <div className="">
                    <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
                        <img className="Banner" src={careerBanner} alt="Responsive image" />
                        <div className="bannerText">
                            <h1 className="secheading mt-5 mb-5"><span className="bannerHeading" style={{ textTransform: "uppercase" }}>Terms of Service</span></h1>
                        </div>
                    </div>
                </div>
                <div className="privacyBody">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1 style={{ textAlign: "center" }}>Terms and Conditions</h1>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }} >
                                    Last Updated: December 6, 2021
                                </h2>
                                <p className="home-content">
                                    PLEASE REFER TO THESE TERMS AND CONDITIONS BEFORE USING OUR
                                    PLATFORM
                                </p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }} >
                                    INTERPRETATION AND DEFINITIONS
                                </h2>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }} >
                                    INTERPRETATION
                                </h2>
                                <p className="home-content">
                                    The words of which the initial letter is capitalized have meanings defined under the given
                                    conditions.
                                </p>
                                <p className="home-content">
                                    The following definitions shall have the following meaning regardless of if they are singular or
                                    plural.
                                </p>
                            </div>
                        </div>
                        <div className="row mt-5 mb-5">
                            <div className="col-lg-12">
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>DEFINITIONS
                                </h2>
                                <ul>
                                    <li>
                                        <p className="home-content"><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common
                                            control with a party, where "control" means ownership of 50% or more of the
                                            shares, equity interest or other securities entitled to vote for election of directors
                                            or other managing authority.
                                        </p>
                                        <p className="home-content"><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this
                                            Agreement) refers to THEOM Technologies Inc.,
                                            1750 Pembina Highway, Winnipeg, Manitoba R3T 4J5. </p>
                                        <p className="home-content"><strong>Device</strong> means any device that can access the Service such as a computer, a
                                            cell phone, or a digital tablet.   </p>
                                        <p className="home-content"><strong>Terms and Conditions</strong> (also referred as "Terms") means these Terms and
                                            Conditions that form the entire agreement between You and the Company
                                            regarding the use of the Service.  </p>
                                        <p className="home-content"><strong>Third-party Social Media Service</strong> means any services or content (including
                                            data, information, products, or services) provided by a third-party that may be
                                            displayed, included, or made available on the online platform
                                            of THEOM Technologies Inc.   </p>
                                        <p className="home-content"><strong>Website</strong> refers to THEOM, accessible from <a href='www.THEOM.ca'>www.THEOM.ca</a>  </p>
                                        <p className="home-content"><strong>You</strong> mean the individual accessing or using the service, or the company, or other
                                            legal entity on behalf of which such individual is accessing or using the Service,
                                            as applicable.  </p>

                                        <p className="home-content"><strong>Service Provider</strong> means the professional which is independently
                                            contracted with the THEOM to deploy their services upon request. They are third
                                            party contractors who are not employees, partners, representatives, agents, and
                                            joint ventures of THEOM. </p>
                                        <p className="home-content"><strong>Account</strong> refers to the password protected account created on the THEOM
                                            platform. </p>
                                    </li>
                                </ul>

                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>GENERAL RESPONSIBILITIES
                                </h2>
                                <p className="home-content">Please do not copy, upload, download, or share files unless you have the right to do so.
                                    You, not THEOM will be fully responsible and liable for what you copy, share, upload,
                                    download or otherwise use while using the Services on the platform. In case of any update to the
                                    information you provided to THEOM, please reach out to the company and the necessary
                                    changes can be made. Under any circumstances, you are not allowed to upload any spyware or
                                    malicious software to the platform that belongs to THEOM. </p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>LIMITATIONS OF LIABILITY AND SECURITY </h2>
                                <p className="home-content">You, not THEOM are solely responsible for the actions of your own account
                                    on THEOM public platform. You are not allowed to pass on any type of details of your own
                                    account which include but not limited to Username, passwords, payment details on the third-
                                    party gateway etc. to any third party or any individual/group. THEOM will keep all your
                                    information which include but not limited to your first name, last name, address, contact number,
                                    credit card details, types of services used via THEOM platform confidential. </p>
                                <p className="home-content">To the maximum extent permitted by applicable law, in no event shall the Company or its
                                    suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever
                                    (including, but not limited to, damages for loss of profits, loss of data or other information, for
                                    business interruption, for personal injury, loss of privacy arising out of or in any way related to
                                    the use of or inability to use the Service, third-party software and/or third-party hardware used
                                    with the Service, or otherwise in connection with any provision of this Terms), even if the
                                    Company or any supplier has been advised of the possibility of such damages and even if the
                                    remedy fails of its essential purpose. </p>


                                <p className="home-content"><strong>THEOM IS NOT RESPONSIBLE FOR ANY DAMAGE, LOSS OR CORRUPTION OF
                                    DATA OR ANY OTHER HARM WHICH HAPPENS FROM YOUR ACCESS OF OUR
                                    PLATFORM TO YOUR DEVICES WHICH INCLUDE BUT NOT LIMITED TO
                                    COMPUTER, CELL PHONE, TELEVISION, IPAD, ANDROID TABLET ETC.</strong></p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>ACCEPTANCE OF USE </h2>
                                <p className="home-content"><strong>IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT AND ABIDE BY ITS
                                    TERMS, </strong></p>
                                <p className="home-content"><strong>YOU MAY NOT USE OR ACCESS THE THEOM PLATFORM.</strong></p>
                                <p className="home-content">These are the Terms and Conditions governing the use of all the services offered by THEOM and
                                    the agreement that operates between You and the Company. These Terms and Conditions set out
                                    the rights and obligations of all users regarding the use of the Service.
                                    Your access to and use of the Service is conditioned on your acceptance of and compliance with
                                    these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others
                                    who access or use the Service.</p>
                                <p className="home-content">By accessing or using the Service You agree to be bound by these Terms and Conditions. If You
                                    disagree with any part of these Terms and Conditions, then You may not access the Service.
                                    Your access to and use of the Service is also conditioned on Your acceptance of and compliance
                                    with the Privacy Policy of the Company. Our Privacy Policy describes our policies and
                                    procedures on the collection, use and disclosure of Your personal information when You use the
                                    Application or the Website and tells You about Your privacy rights and how the law protects
                                    You. Please read Our Privacy Policy carefully before using Our Service. </p>


                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>
                                    ACCOUNT REGISTRATIONS</h2>
                                <p className="home-content">The platform can be used without creating an account. Guest users will be able to  view the
                                    profiles of service providers and book orders on the platform. However, some information will
                                    be required to book an order on the platform. It includes but not limited to your legal name, your
                                    address, your phone number, and your email address.
                                    You can also create an account using certain third-party account and log-in credentials (your
                                    "Third-Party Site Password")</p>
                                <p className="home-content">Service providers are required to create an account on the platform. It can be made by going over
                                    to Theom.ca and clicking “Become a pro”. Following the process, service providers are expected
                                    to provide the necessary documents which include but are not limited to necessary personal
                                    information, documentations (if applicable), licenses (if applicable) and any other more
                                    information. It will be at company’s sole discretion to change the process of registration. </p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>ELIGIBILITY, SERVICE PROVIDER REPRESENTATIONS, WARRANTIES, AND
                                    USE OF THE PLATFORM</h2>

                                <p className="home-content">Access to and use of the Platform is available only to individuals who are at least 18 years old
                                    and can form legally binding contracts under applicable law. By accessing or using the Platform,
                                    you represent and warrant that you are eligible. </p>
                                <p className="home-content">By registering or using the Platform to offer, post, or provide services, Service Providers
                                    represent and warrant that they, and the employees, agents, contractors, and subcontractors who
                                    may perform work for them, are properly and fully qualified and experienced, and licensed,
                                    certified, bonded, and insured, as required by applicable laws or regulations to which they may
                                    be subject in the jurisdiction(s) in which they offer their services and in relation to the specific
                                    job they are performing.</p>
                                <p className="home-content">Service Providers understand and agree that by creating and maintaining an account on the
                                    Platform, they receive only the ability to use the platform to access persons interested in
                                    receiving services and related tools, including but not limited to the ability to message those
                                    persons or schedule appointments, that facilitate the provision of services.</p>
                                <p className="home-content">Service providers understand that they are not the employees, joint venture, partner, or agents of
                                    THEOM Technologies Inc. Service providers also acknowledge that they set their own prices for
                                    the services they offer, they set their own schedule for work. THEOM does not control and has
                                    no right to control what service(s) are being offered by the service providers and how they
                                    provide it.</p>
                                <p className="home-content">As permitted by applicable laws, THEOM may obtain reports regarding Service Providers,
                                    which may include history of criminal convictions or sex offender registration, and we may limit,
                                    block, suspend, deactivate, or cancel a Service Provider’s account based on the results of such a
                                    check. As a Service Provider, you agree and authorize us to use your personal information, such
                                    as your full name and date of birth, to obtain such reports from THEOM’s vendors.</p>

                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>USER CONTENT</h2>



                                <p className="home-content">We may, in our sole discretion, permit you to post, upload, publish, submit, or transmit User
                                    Content. By making available any User Content on or through the Platform, you hereby grant to
                                    THEOM a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license,
                                    with the right to sublicense, to use, copy, adapt, modify, distribute, license, sell, transfer, publicly
                                    display, publicly perform, transmit, stream, broadcast, access, view, and otherwise exploit such
                                    User Content on, though, by means of or to promote, market or advertise the Platform or
                                    Services provided, or for any other purpose in our sole discretion, except that the contents of
                                    private messaging through the Platform will not be used by THEOM in public advertising. In the
                                    interest of clarity, the license granted to THEOM shall survive termination of the Platform or
                                    your Account. THEOM does not claim ownership rights in your User Content and nothing in
                                    these Terms will be deemed to restrict rights that you may have to use and exploit any such User
                                    Content submitted, posted, uploaded, published, or transmitted on or through the Platform by
                                    you. </p>
                                <p className="home-content">You acknowledge and agree that you are solely responsible for all User Content that you make
                                    available on or through the Platform. Accordingly, you represent and warrant that: (a) you either
                                    are the sole and exclusive owner of all User Content that you make available on or through the
                                    Platform or you have all rights, licenses, consents and releases that are necessary to grant to
                                    THEOM the rights in such User Content, as contemplated under these Terms; and (b) neither the
                                    User Content nor your posting, uploading, publication, submission or transmittal of the User
                                    Content or THEOM's use of your User Content (or any portion thereof) on, through or by means
                                    of the Platform will infringe, misappropriate or violate a third party's patent, copyright,
                                    trademark, trade secret, moral rights or other proprietary or intellectual property rights, or rights
                                    of publicity or privacy, or result in the violation of any applicable law or regulation. While it has
                                    no obligation to do so, you agree that THEOM may proofread, summarize or otherwise edit
                                    and/or withdraw your User Content, and you understand it remains your sole responsibility to
                                    monitor your User Content and ensure that such edited Content is accurate and consistent with
                                    your representations and warranties in these Terms.</p>
                                <p className="home-content">THEOM reserves the right, at any time and without prior notice, to remove or disable access to
                                    User Content that we, in our sole discretion, consider to be objectionable for any reason, in
                                    violation of these Terms or otherwise harmful to the Platform or users, or for any other reason.</p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>
                                    LICENSE TO USE THE PLATFORM</h2>
                                <p className="home-content">Subject to your compliance with these Terms, THEOM grants you a limited, non-exclusive,
                                    revocable, non-transferable, and non-sublicensable license to reproduce and display Collective
                                    Content (excluding any software source code) solely for your personal and non-commercial use
                                    and only in connection with your access to and participation in the Platform. You will not use,
                                    copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer,
                                    publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Platform
                                    or Collective Content, except as expressly permitted in these Terms. The Platform and Collective
                                    Content are provided to you AS IS. If you download or print a copy of Collective Content for
                                    personal use, you must retain all copyright and other proprietary notices contained thereon. No
                                    licenses or rights are granted to you by implication or otherwise under any intellectual property
                                    rights owned or controlled by THEOM or its licensors, except for the licenses and rights
                                    expressly granted in these Terms.</p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>SPONSORED INFORMATION</h2>

                                <p className="home-content">THEOM may publicly display advertisements, paid content, and other sponsored information.
                                    The manner, mode, and extent of such advertising are subject to change without specific notice
                                    to you. Service Members may compensate THEOM to “Promote” themselves in search results
                                    and therefore rank higher. Any such Service Members will be clearly designated in applicable
                                    search results as “Promoted” or with a similar designation. THEOM may impose certain quality-
                                    related standards for Service Members to qualify as “Promoted”, which standards may be
                                    enforced and/or changed at any time.</p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>TERMINATION</h2>

                                <p className="home-content">THEOM holds the complete right to terminate or suspend your access immediately, without prior
                                    notice or liability, for any reason whatsoever, including but not limited if You breach these
                                    Terms and Conditions. If we terminate your services for the breach of these terms and
                                    conditions, no refund will be paid to you by THEOM Technologies Inc.
                                    Upon termination, your right to use the Service will cease immediately.   </p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>MODIFICATIONS</h2>
                                <p className="home-content">THEOM holds the complete right to terminate or suspend your access immediately, without prior
                                    notice or liability, for any reason whatsoever, including but not limited if You breach these
                                    Terms and Conditions. If we terminate your services for the breach of these terms and
                                    conditions, no refund will be paid to you by THEOM Technologies Inc.
                                    Upon termination, your right to use the Service will cease immediately. </p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>MODIFICATIONS</h2>
                                <p className="home-content">THEOM reserves the right, at Our sole discretion, to modify or replace these Terms and
                                    Conditions at any time. If a revision is made to these terms and conditions, we will make
                                    reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What
                                    constitutes a material change will be determined at Our sole discretion. You can expect to be
                                    notified by us via email if the there are any updates to these terms of conditions.
                                    By continuing to access or use our service after those revisions become effective, you agree to be
                                    bound by the revised terms. If You do not agree to the new terms, in whole or in part, you may
                                    not be able to continue using the website and the services.  </p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>GOVERNING LAW</h2>
                                <p className="home-content">The laws of the Country (i.e., Canada), excluding its conflicts of law rules, shall govern this
                                    Terms and Your use of the Service. Your use of the Application may also be subject to other
                                    local, state, national, or international laws.  </p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>INTELLECTUAL PROPERTY</h2>
                                <p className="home-content">The innovation we use for the working of our organization is ensured by
                                    copyright, trademark, and other overseeing laws in the nation of Canada. The agreements
                                    recorded down here in any capacity don't concede remittance to utilize property
                                    of THEOM which incorporates yet not restricted to Logo, name of the organization, name of
                                    area or some other sorts of brand highlights.   </p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>LIMITATIONS OF ACCEPTANCE OF USE</h2>
                                <p className="home-content">Agreeing to these terms and conditions, you agree that you will not exploit the online platform
                                    of THEOM in any manner which is listed below but not limited to - </p>
                                <ul>
                                    <li>
                                        <p className="home-content">Probe, scan, or test the vulnerability of any system or network which belongs to THEOM
                                            Technologies Inc.</p>
                                        <p className="home-content">Breach or otherwise circumvent any security or authentication measures.</p>
                                        <p className="home-content">Access, tamper with, or use non-public areas of the Service, shared areas of the Service
                                            which you have not been invited to, THEOM Technologies Inc. (or our service
                                            providers’) computer systems.</p>
                                        <p className="home-content">nterfere with or disrupt any user, host, or network, for example by sending a virus,
                                            overloading, flooding, spamming, or mail-bombing any part of the Services. </p>
                                        <p className="home-content">Plant malware or otherwise use the Services to distribute malware.</p>
                                        <p className="home-content">Access or search the Services by any means other than our publicly supported interfaces
                                            (for example, "scraping"). </p>
                                        <p className="home-content">Send unsolicited communications, promotions or advertisements, or spam.</p>
                                        <p className="home-content">send altered, deceptive, or false source-identifying information, including "spoofing"
                                            or   "phishing” </p>
                                        <p className="home-content">Publish anything that is fraudulent, misleading, or infringes another's rights. </p>
                                        <p className="home-content">promote or advertise products or services other than your own without</p>
                                        <p className="home-content">appropriate authorization</p>
                                        <p className="home-content">Impersonate or misrepresent your affiliation with any person or entity.</p>
                                        <p className="home-content">Publish or share materials that are unlawfully pornographic or indecent, or
                                            that advocate bigotry, religious, racial, or ethnic hatred.</p>
                                        <p className="home-content">Violate the law in any way, or violate the privacy of others, or defame others.</p>
                                    </li>
                                </ul>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>PROMOTIONS</h2>
                                <p className="home-content">You may receive certain offers from THEOM (“Promotions”), including but not limited to
                                    discounted or free use of the Platform for a limited period. Any Promotions are offered at
                                    THEOM’s discretion and could be nullified by the company at any given point of time.
                                    Any benefits from participating in the Promotion, requirements of accepting the offer, and any
                                    other conditions to receive any benefit described in the Promotion will be disclosed to you.
                                    At any given point of time, THEOM reserves the full right to revoke a benefit if we believe that
                                    you have not satisfied the Promotion requirements, including but not limited to maintaining your
                                    account in good overall standing. </p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>TRACKING COMMUNICATIONS</h2>
                                <p className="home-content">To ensure appropriate charging of Fees and to enforce these Terms, THEOM reserves the full
                                    right to track the occurrence of communications between customers and service providers. These
                                    include but not limited to emails, text messages, phone calls and any other communications
                                    which occur on platforms not owned by THEOM. Except as explicitly stated herein, we will not
                                    record or review the content of any communications that do not meet the THEOM
                                    panel unless your consent is obtained. </p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>DISPUTE BETWEEN OR AMONG USERS</h2>
                                <p className="home-content">We value our service providers and Customers, and we aim to help reach the users to resolve the
                                    dispute independently. You acknowledge and agree that THEOM is under no obligation to
                                    become involved in or impose resolution in any dispute between or among users or any third
                                    party. </p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>FEES</h2>
                                <p className="home-content">All charges payable by you for the Services presented by THEOM will be as per the size of
                                    charges and rates distributed occasionally, by us on our site, blunders and exclusions excepted,
                                    and will be expected and payable ahead of arrangement of the administrations presented on
                                    THEOM's foundation. You concur that, apart from where in any case determined in these terms,
                                    these charges are not refundable on any premise. </p>
                                <p className="home-content">For extra items and administrations, THEOM will show you the charges related with the
                                    utilization of these extra items or administrations before the acquisition of these extra items and
                                    administrations.</p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>THEOM POINTS</h2>
                                <p className="home-content">For a shifted number of administrations presented on the stage, Service suppliers will be charged
                                    focuses known as THEOM points. In this, clients will send solicitation to organization around at
                                    least one specialist co-ops they wish to find out about. THEOM will advance the solicitation to
                                    the specialist co-ops chose by clients. On the off chance that the specialist organizations
                                    acknowledge the solicitation, they will be charged focuses on the event that they wish to contact
                                    the client. </p>
                                <p className="home-content">Focuses will be deducted from the specialist co-ops accounts. They can purchase THEOM points
                                    utilizing their specialist co-op accounts. The evaluating of THEOM points is liable to change at
                                    organization's sole watchfulness.</p>

                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>FEEDBACK</h2>
                                <p className="home-content">In case of sending feedback/suggestions/concerns to THEOM, including the Platform you
                                    represent and warrant (a) that you have the right to disclose the Feedback, (b) that the Feedback
                                    does not violate the rights of any other person or entity, and (c) that your Feedback does not
                                    contain the confidential or proprietary information of any third party or parties.
                                    By sending us feedback, you agree that THEOM is under no obligation to keep the feedback
                                    confidential under any circumstances. </p>
                                <p className="home-content">The feedback you provided to THEOM shall survive in case of your termination or suspension
                                    from the platform of THEOM. </p>

                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>INDEMNIFICATION</h2>
                                <p className="home-content">You agree to release, defend, indemnify, and hold THEOM and its affiliates and subsidiaries,
                                    and their respective officers, directors, employees and agents, harmless from and against any
                                    claims, liabilities, damages, losses, and expenses, including without limitation reasonable legal
                                    and accounting fees, arising out of or in any way connected with (a) your violation of these
                                    Terms; (b) your User Content; (c) your interaction with any Member or user; and (d) the request
                                    or receipt or offer or provision of services by you, including but not limited to any injuries,
                                    losses, or damages (compensatory, direct, incidental, consequential or otherwise) of any kind
                                    arising in connection with such services.</p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>DATA SHARING AND DELETION</h2>
                                <p className="home-content">THEOM Technologies Inc. Reserve the right to share the information from the user profile to the
                                    law enforcements if asked which includes but not limited to personal information, order history,
                                    monetary account balance, order details and etc. Also, THEOM Technolgies Inc also retains the
                                    right to delete the data after the termination of account for any number of reasons. </p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>REFUNDS </h2>
                                <p className="home-content">All the payments towards the order completion should occur between the customer and the
                                    service provider. THEOM Technologies Inc does not intervene and keep record of the payments
                                    and will not participate in any of the disputes which may occur due to the reason stated above.
                                    For service providers purchasing THEOM points should contact support at <a href="support@theom.ca">support@theom.ca</a>
                                    with their concerns.  </p>
                                <h2 className="heading mb-4" style={{ fontWeight: "500" }}>NOTICE OF COPYRIGHT INFRINGEMENT</h2>
                                <p className="home-content">Deliver the notice of infringement with all the details and the list of items to the following
                                    address </p>
                                <p className="home-content">Apartment 1217 1750 Pembina Hwy, Winnipeg, Manitoba, R3T 4J5 </p>



                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default PrivacyPolicies;