import React from 'react'
import Footer from './Footer'
import Header from '../homepage/Header'
import AfterLoginHeader from '../homepage/Header'
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import categoryBanner from '../assets/AllServices/beauty-banner.jpg';
import { grey } from '@material-ui/core/colors';
import { Cancel } from '@material-ui/icons';
const Ordersummary = () => {
    const [data, setdata] = React.useState(JSON.parse(localStorage.getItem("custordclick")))
    const [count, setcount] = React.useState(false);
    const [dubq, setdubq] = React.useState([])



    const dublicate = () => {
        data.selectPrice.map((x) => {
            let answersfit = x.ansawer.substr(x.ansawer.lastIndexOf('\\') + 1).split('#')[0]
            let some = false
            dubq.map((y, index) => {
                if (x.question == y.question) {
                    some = true
                    y.ansawer = (y.ansawer + ' <br/> ' + answersfit)
                }
            })
            if (some == false) {
                dubq.push({ "question": x.question, "ansawer": answersfit })
            }

        })
    }
    React.useEffect(() => {
        if (!count) {
            dublicate()
            setcount(true)
            let dataapi = [{
                "bookingId": data._id,
                "isUser": false
            }]
            fetch(`${API}/user/updateNotification`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(dataapi)
            })
                .then(response => response.json().then(data => {
                    if (response.status == 200) {
                    }
                })
                )
                .catch(err => toast.error("Ooops!! Something went wrong."));
        }

    }, [data])

    const statusset = (e, id, pid) => {
        let dataapi = {
            "_id": id,
            "status": e,
            "professionalId": pid
        }
        fetch(`${API}/user/UpdateStatus`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(dataapi)
        })
            .then(response => response.json().then(data => {
                if (response.status == 200) {
                    toast.success("Cancelled order Successful")
                }
            })
            )
            .catch(err => toast.error("Ooops!! Something went wrong."));
        localStorage.setItem("custordclick", JSON.stringify({ ...data, ["status"]: e }))
        setTimeout(function () { window.location.reload(false) }, 2000);


    }

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
            <AfterLoginHeader />
            <hr className="headerLine"></hr>
            <div className="container">
                <div className="row conatiner mt-5 pt-2">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <h1 className="secheading"><span className="heading-1">ORDER</span>&nbsp;&nbsp;<span className="heading-2">SUMMARY</span></h1>
                    </div>
                    <div className="col-sm-5 col-md-3 col-lg-3 mb-2 col-6 text-right btnset">
                        {data.professionalId[0].status == "Completed" ? '' : <a className="cancel-Post" ><button type="button" id="cancelPost" className="btn btn-primary" onClick={e => statusset("Cancelled", data._id, data.professionalId[0].professionalId._id)}>Cancel Order</button></a>}
                    </div>
                    <div className="col-sm-2 col-md-2 col-lg-2 text-left btnset col-6">
                        <Link className="cancel-Post" to="/orders"><button type="button" id="cancelPost" className="btn btn-primary" style={{ width: "130px" }}>Back</button></Link>
                    </div>
                </div>
                <div className="row col-sm-10 mt-3 ordersmr conatiner mb-5" style={{ backgroundColor: "#eeeeee4d", padding: 40, marginLeft: 92, color: "#666" }}>
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="col-6  mb-4">Order Number</span><span className="col-6 mb-4 float-right">{data.orderID}</span><br></br>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="col-6  ">Order Status</span><span className="col-6 mb-4  float-right">{data.status ? data.status : data.professionalId[0].status}</span><br></br>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="col-6 mb-4 ">Service Name</span><span className="col-6 mb-4  float-right">{data.subCategory ? data.subCategory : ""}</span><br></br>
                    </div>
                    {/* <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="col-6 mb-4 ">Order Type</span><span className="col-6 mb-4  float-right">{data.subCategory ? data.subCategory : ""}</span><br></br>
                    </div> */}
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="col-6 mb-4 ">Order Date & Time</span><span className="col-6 mb-4  float-right">{(data.bookingDate.substr(data.bookingDate.lastIndexOf('\\') + 1).split('T')[0]) + " | " + data.startTime + ' - ' + data.endTime}</span><br></br>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row mt-4 mb-1">
                        <h5 className="col--6 mb-4 " style={{ color: "#000", fontWeight: "700" }}>Professional Details</h5>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="col-6 mb-4 ">Name </span><span className="col-6 mb-4  float-right">{data.professionalId[0].professionalId.first_name + ' ' + data.professionalId[0].professionalId.middle_name + ' ' + data.professionalId[0].professionalId.last_name}</span><br></br>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="col-6 mb-4 ">Service Name</span><span className="col-6 mb-4  float-right">{data.subCategory ? data.subCategory : ""}</span><br></br>
                    </div>
                    {/* <div className="col-sm-12 col-md-12 col-lg-12 row">
                        <span className="col-6 mb-4 ">Estimated Cost</span><span className="col-6 mb-4  float-right">{data.totalAmount ? "$" + data.totalAmount : ""}</span><br></br>
                    </div> */}
                    <div className="col-sm-12 col-md-12 col-lg-12 row mt-4 mb-1">
                        <h5 className="col--6 mb-4 " style={{ color: "#000", fontWeight: "700" }}>Questions</h5>
                    </div>

                    <div className="col-sm-12 row">
                        <div className="header2 questionadmin">
                            <div className="col-sm-12">
                                {
                                    dubq && dubq.map((y, index) => {
                                        return (
                                            <div class="mt-3">
                                                <p> <strong>{(index + 1) + ') ' + y.question} </strong></p>
                                                <div class="option">
                                                    <label for={"o1"} dangerouslySetInnerHTML={{ __html: y.ansawer }}></label>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 row text-left">
                        <button type="button" id="cancelPost" class="btn btn-primary ml-3" >{data.totalAmount + " $ " + "Estimated Cost"}</button>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row mt-4 mb-1">
                        <h5 className="col--6 mb-4 " style={{ color: "#000", fontWeight: "700" }}>Note</h5>
                    </div>

                    <div className="col-sm-12 questionadmin">
                        <div class="first">
                            <textarea id="w3review" name="w3review" rows="4" placeholder="Type Here" value={data.addNote} style={{ border: "none", background: "transparent" }} />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 row mt-4 mb-1">
                        <h5 className="col--6 mb-4 " style={{ color: "#000", fontWeight: "700" }}>Photo</h5>
                    </div>

                    <div className="col-sm-12 questionadmin">
                        <div class="first row">
                            {
                                data.uploadImage.length > 0 ? data.uploadImage.map((e) => {
                                    return (<div className="col-md-3">
                                        <a data-fancybox=" gallery" href={`${API}/files/${e}`}><img src={`${API}/files/` + e} style={{ width: "180px", height: "160px" }} /></a>
                                    </div>)
                                }) : ""
                            }
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}
export default Ordersummary