import React, { useState } from 'react';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery';
import Header from './Header'
import Footer from '../common/Footer'
import usericon from '../assets/homepage/images.png'

export default function Costumersetting() {

    const [userdetail, setuserdetail] = React.useState(JSON.parse(sessionStorage.getItem("costumer")))
    const userPass = {}
    React.useEffect(() => {
    }, [userdetail])



    const handleChange = (cName, e) => {
        if (userdetail == '') {
            // setupdateprofile(providerData)
        } else {
            let newdata = { ...userdetail, [e.target.name]: e.target.value }
            sessionStorage.setItem('costumer', JSON.stringify(newdata))
            // setupdateprofile(newdata)
            userdetail[cName] = e.target.value
        }
    }


    const saveNewSetting = () => {
        let get = JSON.parse(sessionStorage.getItem("costumer"))
        if (get) {
            fetch(`${API}/user/profileUpdate`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(get)
                })
                .then(response => response.json().then(data => {
                    if (response.status == 200) {
                        toast.success("Details Updated Successfully .");
                        // setTimeout(function () {
                        //     window.location.reload(false)
                        // }, 2000);
                    }
                    else {
                        toast.error("Ooops!! Updation Failed .");
                    }
                })
                )
            // .catch(err => toast.error("Ooops!! Something went wrong."));
        }
    }

    const changePassword = (data) => {
        if (data) {
            data._id = userdetail._id
            fetch(`${API}/user/changePassword`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                })
                .then(response => response.json().then(data => {
                    if (response.status == 200) {
                        toast.success(data.msg);
                    }
                    else {
                        toast.error(data.msg);
                    }
                })
                )
            // .catch(err => toast.error("Ooops!! Something went wrong."));
        }
    }

    const handlePassword = (cName, e) => {
        userPass[cName] = e.target.value
    }
    const logout = () => {
        sessionStorage.clear("token")
        localStorage.clear()
        window.location.replace("/")
    }
    return (
        <>
            <Header />
            <div className="container">
                <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                />
                <div className="row mt-5">
                    <div className="col-sm-3">
                        <div className="profile">
                            <div className="profile-img">
                                <img className="profileimage" src={usericon} />
                                <div className="upload-button" style={{ marginTop: "0" }}>
                                    <div className="file btn tab-btns" >
                                        <strong style={{ textTransform: "uppercase" }}>{userdetail.first_name + ' ' + userdetail.last_name}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-9">
                        <div className="borderP pb-2" >
                            <div className="header">
                                <p>Personal Settings</p>
                            </div>
                            <div className="row header2">
                                <div className="col-sm-12">
                                    <div className="row col-12 mb-3">
                                        <div className="form-group col-sm-6">
                                            <input type="text" className="form-control" name="first_name" id="fName" placeholder="First Name" defaultValue={userdetail.first_name} onChange={(e) => handleChange("first_name", e)} />
                                        </div>
                                       
                                        <div className="form-group col-sm-6">
                                            <input type="text" className="form-control" name="last_name" id="lName" placeholder="Last Name" defaultValue={userdetail.last_name} onChange={(e) => handleChange("last_name", e)} />
                                        </div>
                                    </div>
                                    <div className="row  col-12 mb-3">
                                        <div className="form-group col-sm-6">
                                            <input type="email" className="form-control" id="email" name="email" placeholder="Email Address" defaultValue={userdetail.email} onChange={(e) => handleChange("email", e)} />
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <input type="text" className="form-control" id="phone" name="mobile_number" placeholder="Phone Number" defaultValue={userdetail.mobile_number} onChange={(e) => handleChange("mobile_number", e)} />
                                        </div>
                                    </div>
                                    <div className="row col-12 mb-3">

                                        <div className="form-group col-sm-12">
                                            <input id="" className="form-control" name="address" placeholder="Address" defaultValue={userdetail.address} onChange={(e) => handleChange("address", e)} />
                                        </div>
                                    </div>
                                    <div className="row col-12 mb-3">
                                        <div className="form-group col-sm-4">
                                            <input id="city" className="form-control" name="city" placeholder="City" defaultValue={userdetail.postal_code} onChange={(e) => handleChange("postal_code", e)} />
                                        </div>
                                    </div>
                                    <button className="button btn1 ml" onClick={e => saveNewSetting()}>Save</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5 mb-5">
                    <div className="col-sm-3">

                    </div>
                    <div className="col-sm-9">
                        <div className="borderP">
                            <div className="header">
                                <p>Change Password</p>
                            </div>

                            <div className="header2">
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-4 paddCss">
                                            <label>Current Password</label>
                                        </div>
                                        <div className="col-sm-8 paddCss">
                                            <input type="text" name="" className="form-control" placeholder="Type here" onChange={(e) => handlePassword("current", e)} />
                                        </div>
                                        <div className="col-sm-4 paddCss">
                                            <label>Enter New Password</label>
                                        </div>
                                        <div className="col-sm-8 paddCss">
                                            <input type="text" className="form-control" name="" placeholder="Type here" onChange={(e) => handlePassword("new", e)} />
                                        </div>
                                        <div className="col-sm-4 paddCss">
                                            <label>Re-enter New Password</label>
                                        </div>
                                        <div className="col-sm-8 paddCss">
                                            <input type="text" className="form-control" name="" placeholder="Type here" onChange={(e) => handlePassword("confirm", e)} />
                                        </div>
                                    </div>
                                    <button onClick={() => changePassword(userPass)} className="button btn1 m2l" style={{margin:"0"}}>Change</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}