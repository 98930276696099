import React, { useState } from 'react'
import Footer from './Footer'
import Header from '../homepage/Header'
import ReactStars from "react-rating-stars-component";
import service5 from '../assets/blog/pp.png';
import { Link, useParams, useLocation } from 'react-router-dom'
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from "react";
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Showreview from '../review/Showreview';
import { useHistory } from "react-router-dom";

const SearchProfessional = () => {
  const slugdata = useParams()
  const slugdatae = slugdata.service_professional
  const [reflectpage, setreflectpage] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [postel, setpostel] = React.useState(slugdatae.substr(slugdatae.lastIndexOf('\\') + 1).split('='))
  const [multy, setmulty] = React.useState(JSON.parse(sessionStorage.getItem("multy")))
  const [count, setcount] = useState(false);
  const [post, setpost] = useState(postel[1] == undefined ? '' : postel[1])
  const [value, setValue] = React.useState([0, 500]);
  const [tableDatafix, setTableDatafix] = useState([]);
  const [pagination, setpagination] = useState({
    min: 0,
    max: 5
  });
  const [reting, setreting] = useState()
  const [questionall, setquestionall] = useState([])
  const [postelsearch, setpostelsearch] = useState()
  const history = useHistory();

  useEffect(() => {
    if (!count) {
      localStorage.setItem("searchsubcategory_question", postel[0])
      document.getElementById("loder").style.display = "block"
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      fetch(`${API}/user/searchprofessional?subcategory=` + postel[0] + "&postalCode=" + post, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            result.result.map((data) => {
              tableData.push(data)
              alreadyqsn(data._id)
              tableDatafix.push(data)
            })
          }
          setreflectpage(result.data)
          document.getElementById("loder").style.display = "none"
        })
        .catch(error => toast.error("Ooops!! Something went wrong."));

      // 



      setcount(true)
    }


  }, [tableData, tableDatafix, count, reflectpage, questionall]);

  const alreadyqsn = (e) => {
    fetch(`${API}/professional/getProfessinalPrice?professinalId=` + e,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => response.json().then(data => {
        if (response.status == 200) {
          // questionall.map(price)
          // setquestionall(data.ProfessinalPrice)
          // data.ProfessinalPrice.map((e) => {
          //   questionall.push(e)
          // })
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }

  const todayabl = () => {
    let ch = []
    fetch(`${API}/professional/currentyDateWiseProfessinalAvailibility`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    })
      .then(res => res.json().then(result => {
        if (result.data[0]) {
          setTableData([])

          for (var x of result.data) {
            let find = tableDatafix.filter(e => {
              if (e._id == x.userId._id) {
                ch.push(e)
              }
            })
          }
          setTableData(ch)
        } else {
          setTableData([])
          toast.error("Professional not available today ")
        }
      }))
      .catch(err => toast.error("Ooops!! Something went wrong."));
  }

  const phone = (data) => {
    if (!postelsearch) {
      toast.error("fill please")
    }
    else {
      history.push("/search-professional/" + (postel[0] + "=" + postelsearch));
      setTimeout(() => {
        window.location.reload()
      }, 500);
    }
  }

  const rangeSelector = (event, newValue) => {
    let x = []
    let find = tableDatafix.filter(data => {
      if (newValue[0] <= data.getAvgPrice && newValue[1] >= data.getAvgPrice) {
        x = [...x, data]
      }
    })
    setTableData(x)
    setValue(newValue);
  }

  // const linkName = '';

  const ratingChanged = (newRating) => {
    setTableData([])
    let find = tableDatafix.filter(x => {
      let y = String(x.getAvgRating).substr(String(x.getAvgRating).lastIndexOf('\\') + 1).split('.') ?
        '.' + parseInt(String(x.getAvgRating).substr(String(x.getAvgRating).lastIndexOf('\\') + 1).split('.')[1]) >= .5 ? parseInt(String(x.getAvgRating).substr(String(x.getAvgRating).lastIndexOf('\\') + 1).split('.')[0]) + 1 : String(x.getAvgRating).substr(String(x.getAvgRating).lastIndexOf('\\') + 1).split('.')[0] : x.getAvgRating
      if (parseInt(y) == (newRating)) {
        return (x)
      }
    })
    setTimeout(function () { setTableData(find) }, 1000);

  };

  const reviews = (e) => {
    localStorage.setItem('review', e)
  }

  const providerTable = ((x, index) => {
    if (x.status) {
      if (index < pagination.max && index >= pagination.min) {
        return (
          <>
            <Link className="professionalLink" to="/review" onClick={(e) => reviews(x._id)}>
              <div className="row  mb-3">
                <div className="col-sm-8 col-12">
                  <div className="professionalImage">
                    <img className="review-image img-fluid" src={x.professionalImage == undefined ? service5 : `${API}/files/${x.professionalImage}`} />
                    <h4 className="professionalName">{x.first_name + ' ' + x.last_name}
                      <span className="professionalReview">
                        {/* <p>Tags given of any</p> */}

                        <div className="ratng" style={{ marginTop: "-6px" }}>
                          <Showreview star={x.getAvgRating} />
                        </div>
                        <h3 className="mov">{x.getAvgPrice >= 0 ? x.getAvgPrice + "$" : ''} Estimated Cost</h3>
                        {/* <p>15 similar jobs done near you</p>{ } */}

                      </span>
                      <p dangerouslySetInnerHTML={{ __html: x.intro }} className="intro" style={{ float: "left", width: "100%" }}></p>
                    </h4>
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div className="professionalEstimate hidetable">
                    <h3>{x.getAvgPrice >= 0 ? x.getAvgPrice + "$" : ''}</h3>
                    <h5>Estimated Cost</h5>
                  </div>
                </div>
              </div>
            </Link>
            <hr></hr>
          </>
        )
      }
    }
  })

  const clear = () => {
    window.location.reload(false)
  }

  const togal = (e) => {
    var element = document.getElementById(e);
    element.classList.toggle("mystyle");
  }
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div class="loder" id="loder">
        <div class="wrap">
          <div class="loading">
            <div class="bounceball"></div>
            <div class="text">NOW LOADING</div>
          </div>
        </div>
      </div>
      <Header />
      <hr className="headerBorder mt-2"></hr>
      <div className="professionalSearch">
        <div className="row">
          <div className="filterBox col-lg-3">
            <div className="filterByBox row">
              <div className="col-sm-6 col-6">
                <h5 onClick={e => togal("fil")}>Filter by <i class="fa fa-angle-down dn" aria-hidden="true"></i></h5>
              </div>
              <div className="col-sm-6 col-6">
                <button id="login-btn" type="button" class="btn btn-primary clearb" onClick={() => clear()}>Clear all</button>
              </div>
            </div>
            <div className="hidepart" id="fil">
              <div className="filterByBox row" >
                <div className="col-sm-12">
                  <h5>Price</h5>
                  <div style={{
                    margin: 'auto',
                    display: 'block',
                    width: 'fit-content'
                  }}>
                    <strong className="col-sm-4 col-2">$</strong>   <input type="text" value={value[0]} className="col-sm-3 col-4" style={{ textAlign: "center", borderRadius: "0" }} /> <span className="col-sm-2 col-2">--</span>  <input type="text" value={value[1]} className="col-sm-3 col-4" style={{ textAlign: "center", borderRadius: "0" }} />
                    <Typography id="range-slider" gutterBottom>
                    </Typography>
                    <Slider
                      value={value}
                      onChange={rangeSelector}
                      valueLabelDisplay="auto"
                      min={0}
                      max={500}
                    />

                  </div>
                </div>
              </div>
              <div className="filterByBox row">
                <div className="col-sm-12">
                  <h5 className="mb-3">Availability</h5>
                  <button type="button" class="btn btn-primary availability" onClick={e => todayabl()}>Today</button>
                </div>
              </div>
              <div className="filterByBox row">
                <div className="col-sm-12">
                  <h5 class="mt-2">Rating</h5>
                  <div className=" rating">
                    <ReactStars
                      count={5}
                      onChange={ratingChanged}
                      size={30}
                      isHalf={false}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      activeColor="#ffd700"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="providerBox col-lg-9 col-12">
            <h2 className="text-left professionals">Top {postel[0]} Professionals Near You</h2>
            <form className="mb-5">
              <ul className="list-unstyled list-inline text-center search-box row" style={{ margin: "auto", width: "70%" }}>
                <li className="col-8" id="map-icon">
                  <input className="col-sm-12 professionalsearch"
                    id="search"
                    type="text"
                    placeholder="Postal Code"
                    aria-label="Search"
                    maxlength="6"
                    onChange={e => setpostelsearch(e.target.value.toUpperCase())}
                  />
                </li>
                <li className="col-4" style={{ textAlign: "right" }}>
                  <button id="search"
                    type="button"
                    className="btn btn-primary mr-5 "
                    id="search-button" onClick={e => phone()}>Search</button>
                </li>
              </ul>
            </form>



            {tableData.length > 0 ? tableData.map(providerTable) : ""}
            <div className="col-md-12 row">
              <div className="col-sm-6">
                {pagination.max > 5 ? <a className="read-more-link text-left" onClick={() => setpagination({
                  "min": pagination.min,
                  "max": pagination.max - 5
                })}><h6 className="viewMore">View less...</h6></a> : ''}
              </div>
              <div className="col-sm-6">
                {pagination.max > tableData.length ? '' : <a className="read-more-link text-right" onClick={() => setpagination({
                  "min": pagination.min,
                  "max": pagination.max + 5
                })}><h6 className="viewMore">View more...</h6></a>}
              </div>
            </div>


          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default SearchProfessional