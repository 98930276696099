import React from 'react';
import { Switch, Route } from "react-router-dom";
import Dashboard from './Dashboard';
import AdminService from './AdminService';
import Search from './Search';
import Review from './Review'
import Task from './Task'
import Liveoperation from './Liveoperation'
import Customers from './Customers'
import Liveoperationsdetails from './Liveoperationsdetails';
import Addservice from './Addservice';
import Servicetype from './Servicetype'
import Questionnair from "./Questionnair"
import SearchProfessional from "./SearchProfessional"
import CreateTask from './CreateTask'
import NewTask from './NewTask'
import ManageService from './ManageService'
import ManageServiceType from './ManageServiceType'


export default function Content() {

    return (
        <Switch>
            <Route exact path="/newAdmin" component={Dashboard} />
            <Route exact path="/newAdmin/adminDasboard" component={Dashboard} />
            <Route exact path="/newAdmin/service" component={AdminService} />
            <Route exact path="/newAdmin/search" component={Search} />
            <Route exact path="/newAdmin/review" component={Review} />
            <Route exact path="/newAdmin/tasks" component={Task} />
            <Route exact path="/newAdmin/live-operation" component={Liveoperation} />
            <Route exact path="/newAdmin/customers" component={Customers} />
            <Route exact path="/newAdmin/live-operations-details" component={Liveoperationsdetails} />
            <Route exact path="/newAdmin/add-service" component={Addservice} />
            <Route exact path="/newAdmin/service-type" component={Servicetype} />
            <Route exact path="/newAdmin/questionnair" component={Questionnair} />
            <Route exact path="/newAdmin/search-professional" component={SearchProfessional} />
            <Route exact path="/newAdmin/createtask" component={CreateTask} />
            <Route exact path="/newAdmin/newtask" component={NewTask} />
            <Route exact path="/newAdmin/mangeService" component={ManageService} />
            <Route exact path="/newAdmin/mangeServiceType" component={ManageServiceType} />
            <Route path="/newAdmin/" component={Search} />
            <Route component={SearchProfessional} />
        </Switch>
    )
}
