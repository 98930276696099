import React, { useState } from 'react';
import Availability, { addsedule } from './StatusAvailability';
import './Admin.css';
import { ToastContainer, toast } from 'react-toastify';
import { API } from "../backend";

export default function Booking() {
  let user, currentlyClicked;

  const [component, setComponent] = useState('#EA4337')
  const [color1, setColor1] = useState('#EA4337')
  const [color2, setColor2] = useState('#ccc')
  const [color3, setColor3] = useState('#EA4337')
  const [color4, setColor4] = useState('')
  const [color5, setColor5] = useState('#ccc')
  const [color6, setColor6] = useState('')

  const [display1, setDisplay1] = useState()
  const [display2, setDisplay2] = useState('none')
  const [display3, setDisplay3] = useState('none')
  const getcurrentlyClicked = (() => {
    currentlyClicked = JSON.parse(localStorage.getItem('currentlyClicked'));
  });
  React.useEffect(() => {
  }, [currentlyClicked, user, getcurrentlyClicked])
  const change = (val, id) => {
    if (val == "Onboarding") {
      user = {
        _id: id,
        status: false,
        onboarding: true
      }
    }
    else {
      user = {
        _id: id,
        status: val == 'true' ? true : false
      }
    }
    console.log(user)
  }
  const clicked = function (currentlyClicked) {
    fetch(`${API}/admin/validateProfessional`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
      })
      .then(response => response.json().then(data => {
        if (response.status == 200) {
          console.log(data)
          // let temp = tableData;
          //  let ind = temp.findIndex(x=>x._id ==id);
          //  temp.splice(ind, 1);
          //  setTableData(temp)
          toast.success("Successful")
          setdata()
        }
        else {
          toast.error("Ooops!! Something went wrong.");
        }
      })
      )
      .catch(err => toast.error("Ooops!! Something went wrong."));

  }

  const setdata = () => {
    localStorage.setItem("currentlyClicked", JSON.stringify({ ...currentlyClicked, ["status"]: user.status, ["onboarding"]: user.onboarding ? user.onboarding : false }))
    getcurrentlyClicked()
    setTimeout(function () { window.location.reload(false) }, 2000);
  }
  return (
    <>
      {getcurrentlyClicked()}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div className="container status-content">
        <div className="contentBorder">
          <div className="row-width row">
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={{ color: color1, boderBottomColor: color1, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid', textAlign: "center" }}>
              <button className="btn button activeclass pastclass" id="button" style={{ color: color3 }} onClick={() => { setColor1('#EA4337'); setColor2('#ccc'); setDisplay1('block'); setDisplay2('none'); setDisplay3('none'); setColor4(''); setColor5('#ccc'); setColor3('#EA4337'); setColor6('') }}>Account Status</button>
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={{ color: color2, boderBottomColor: color2, borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: '4px solid ', textAlign: "center" }}>
              <button className="btn button pastclass" style={{ color: color4 }} onClick={() => { setColor1('#ccc'); setColor2('#EA4337'); setDisplay1('none'); setDisplay2('block'); setDisplay3('none'); setColor3(''); setColor5('#ccc'); setColor4('#EA4337'); setColor6('') }}>Availability</button>
            </div>
          </div>
          <hr className="activepastline"></hr>
          <div className="responsive row" style={{ display: display1 }}>
            <div class="form-group col-sm-6 col-md-6 widthset">
              <select class="form-control status-active-select" onChange={e => {
                change(e.target.value, currentlyClicked._id)
                // setstatus(e.target.value)
              }}>
                <option >select</option>
                <option value="true">Active</option>
                <option value="Onboarding">Onboarding</option>
                <option value="false">Inactive</option>
              </select>
            </div>
            <div className="col-sm-6 col-md-6 widthset">
              <p>{currentlyClicked.status == true ? "Active" : currentlyClicked.onboarding == true ? "Onboarding" : "InActive"}</p>
            </div>
            <div className="bottom-status col-sm-6 col-md-6" >
              <div className="mt-3">
                <div className="">
                  <button className="button admin-status-btn" onClick={e => {
                    clicked()
                  }}>Save</button>
                </div>
              </div>
            </div>
          </div>
          <div className="responsive" style={{ display: display2 }}>
            <Availability />
          </div>
        </div>


      </div>
    </>
  );

}
