import React from 'react';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import service5 from '../assets/homepage/service1.png';
import { Star as AddIcon } from '@material-ui/icons';
import Showreview from "../review/Showreview";
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import saveIcon from '../assets/Icons/save.png';
import './Admin.css';
import { API } from "../backend";
import { ToastContainer, toast } from 'react-toastify';
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom'
import $ from 'jquery';
import { Modal, Container, Row } from 'react-bootstrap';

export default function Review() {

  let redirectId = []
  const adminData = localStorage.getItem("user")
  const [tableData, setTableData] = useState([]);
  const [tableDatafix, setTableDatafix] = useState([]);
  const [value, setValue] = useState({});
  const [count, setcount] = useState(false);
  const [redirect, setredirect] = useState(false);
  const [showPopup, setPopup] = useState(false);
  const [comment, setcomment] = useState('')
  const handleCloseSignupForm = () => {
    setPopup(false)
  };


  useEffect(() => {
    async function providerDetails() {
      if (!count) {
        fetch(`${API}/admin/getAllReview`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
          .then(response => response.json().then(data => {
            if (response.status == 200) {
              data.data.map((e) => e.professionalId ? tableData.push(e) : '')
              data.data.map((e) => e.professionalId ? tableDatafix.push(e) : '')
              setcount(true)
            } else {
              toast.error("Ooops!! Failed to fetch data.");
            }
          })
          )
          .catch(err => toast.error("Ooops!! Something went wrong."));
        // 


      }
    }
    providerDetails();
  }, []);

  const name = (data) => {
    if (tableDatafix.length) {
      let find = tableDatafix.filter(x => (x.professionalId.first_name + x.professionalId.last_name).toLowerCase().search(data.target.value.replace(/ /g, "").toLowerCase()) >= 0 || x.professionalId.last_name.toLowerCase().search(data.target.value.toLowerCase()) >= 0)
      setTableData(find)
    }
    else {
      setTableData(tableDatafix)
    }
  }

  const status = (data) => {
    if (data.target.value.length > 0) {
      let find = tableDatafix.filter(x => x.reviewStatus.toLowerCase().search(data.target.value.toLowerCase()) >= 0)
      setTableData(find)
    } else {
      setTableData(tableDatafix)
    }
  }
  const status2 = (data) => {
    if (data.target.value.length > 0) {
      let find = tableDatafix.filter(x => x.rating == parseInt(data.target.value))
      setTableData(find)
    } else {
      setTableData(tableDatafix)
    }
  }
  const phone = (data) => {
    if (data.target.value.length > 0) {
      if (tableData.length) {
        let find = tableData.filter((x, index) => (index + 1) == data.target.value)
        setTableData(find)
      }

    }
    else {
      setTableData(tableDatafix)
    }
  }

  const change = (val, id) => {
    let user = {
      _id: id,
      reviewStatus: val
    }
    // 
    fetch(`${API}/admin/updateStatus`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
      })
      .then(response => response.json().then(data => {
        if (response.status == 200) {
          toast.success("Saved successfully");
          window.location.reload(false)
        } else {
          toast.error("Ooops!! Updation Failed .");
        }
      })
      )
    // .catch(err => toast.error("Ooops!! Something went wrong."));
  }
  const providerTable = ((x, index) => {
    return (
      <tr key={index}>
        <th scope="row">{x.countReview}</th>
        <td> <Link to={{ state: x }} className="nav-link">{x.professionalId.first_name + " " + x.professionalId.last_name}</Link></td>
        <td style={{ textTransform: 'uppercase' }}>{x.reviewStatus}</td>
        <td>{x.rating}</td>
        <td className="revwbox" ><span className="reviewso">{x.addUserReview}</span> {x.addUserReview.length > 12 ? <a onClick={(e) => {
          setPopup(true)
          setcomment(x.addUserReview)
        }}>Read More</a> : ""}</td>
        <td>
          <form>
            <select onChange={e => change(e.target.value, x._id)} className="select-action" style={{
              minWidth: "120px",
              textAlign: "center"
            }}>
              <option >Select</option>
              <option value="Pending">Pending</option>
              <option value="Approved">Approved</option>
              <option value="Reject">Reject</option>
            </select>
          </form>
        </td>
      </tr>
    )
  })

  return (
    <div className="AdminContainer">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div className="row headerHeading">
        <div className="menuHeading">
          <h3 className="mt-4 mb-4"><span className="heading-1">Reviews</span></h3>
        </div>
      </div>

      <form>
        <div className="row mt-2">
          <div className="col-sm-2">
            <div class="form-group ">
              <input type="text" className="form-control" name="order_no" placeholder="Review no." onChange={e => phone(e)} />
            </div>
          </div>
          <div className="col-sm-3">
            <div class="form-group ">
              <input type="text" className="form-control" name="professional_name" placeholder="Professional Name" onChange={e => name(e)} />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="responsive">
              <div class="form-group ">
                <select class="form-control" onChange={e => status(e)}>
                  <option value="">Status</option>
                  <option value="Pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="reject">Reject</option>
                </select>
              </div>
            </div>
          </div>

          <div className="col-sm-2">
            <div className="responsive">
              <div class="form-group ">
                <select class="form-control" onChange={e => status2(e)}>
                  <option value="">Rating</option>
                  <option value="1">One Star</option>
                  <option value="2">Two Star</option>
                  <option value="3">Three Star</option>
                  <option value="4">Four Star</option>
                  <option value="5">Five Star</option>
                </select>
              </div>
            </div>
          </div>
          {/* <div className="col-sm-2">
                <button type="button" class="btn btn-block admin-status-btn">Search</button>
              </div> */}
        </div>
      </form>
      <div className="tableRadius">
      <div class="table-responsive">
        <table className="table  text-center tableLayout">
          <thead>
            <tr className="tableHeading">
              <th className="admiTable-first tableHeadingColumn" scope="col">Review no.</th>
              <th className="tableHeadingColumn" scope="col">Professional Name</th>
              <th className="tableHeadingColumn" scope="col">Status</th>
              <th className="tableHeadingColumn" scope="col">Rating</th>
              <th className="tableHeadingColumn" scope="col">Comment</th>
              <th className="admiTable-last tableHeadingColumn" scope="col">Action</th>
            </tr>
          </thead>
          {/* <tr>
                <td>
                  <Link to="/" className="nav-link">1</Link>
                </td>
                <td>
                  <Link to="/adminDasboard" className="nav-link">Yash</Link>
                  </td>
                <td>Approved</td>
                <td>Possitive</td>
                <td>Lorem Ipsum is simply dummy text</td>
                <td>
                  <form>
                    <select className="select-action">
                      <option value="accept">Approve</option>
                      <option value="reschedule">Reject</option>
                    </select>
                    <Button><img className="select-image img-fluid" src={saveIcon} /> </Button>
                  </form>
                </td>
              </tr> */}
          {tableData.length > 0 ? tableData.map(providerTable) : ""}
        </table>
        </div>
      </div>
      <Modal show={showPopup} id="questionForm"
        onHide={handleCloseSignupForm} size="md" aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="successfulbookrv">
              {comment}
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  )
}
